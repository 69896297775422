import {Modal, Button, Row, Col} from 'antd';
import React, {useState, useEffect} from 'react';

import {Client, User} from '@growth-x/types';
import {CONSTANTS, CorePlan} from '@growth-x/ui';

import background from '../../../../assets/images/invites_limit_reached_bg.png';
import './FreeInvitesLimitReached.scss';

interface FreeInvitesLimitReachedProps {
  client: Client;
  users: User[];
  activateRegularPlanGx: (updateIsLoading: any, planId: string) => void;
}

export function FreeInvitesLimitReached({
  client,
  users,
  activateRegularPlanGx,
}: Readonly<FreeInvitesLimitReachedProps>) {
  const [visible, setVisible] = useState(false);
  const hideModal = () => setVisible(false);
  const activateCore = () => {
    activateRegularPlanGx(undefined, CONSTANTS.BILLING.PLANS.CORE);
    setVisible(false);
  };
  useEffect(() => {
    if (client.type === CONSTANTS.BILLING.PLANS.FREE) {
      const user = users.find(u => !u.archived);
      const limitReached = user?.messages_sent_monthly_cur_billing_term >= user?.monthly_max_messages_limit;
      const limitReachedShown = sessionStorage.getItem('free_invites_limit_reached_shown');
      if (limitReached && !limitReachedShown) {
        setVisible(true);
        sessionStorage.setItem('free_invites_limit_reached_shown', 'true');
      }
    }
  }, [client, users]);
  return (
    <Modal visible={visible} onCancel={hideModal} width={1000} footer={null}>
      <div
        className="free-invites-limit-reached"
        style={{background: `url(${background}) no-repeat center center`, backgroundSize: 'cover'}}
      >
        <div className="title">
          <h2>Oops...Your limit reached!</h2>
          <p>
            Looks like you've used up your 100 monthly invites on the Free plan! Upgrade to Core for just $99/month and
            get unlimited connection invites & more.
          </p>
        </div>
        <Row type="flex" justify="center" className="content">
          <Col span={12}>
            <CorePlan period="monthly" />
          </Col>
        </Row>
        <Row type="flex" justify="center" className="bottom">
          <Col span={12}>
            <div className="buttons-wrapper">
              <Button type="ghost" onClick={hideModal}>
                Not Now
              </Button>
              <Button type="primary" onClick={activateCore}>
                Get Core Subscription!
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
