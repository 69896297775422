export enum UserErrorCodes {
  LoginIdentityRequired = 'error_login_identity_required',
  AgreeNotScrape = 'error_agree_not_scrape',
  TemporarilyRestricted = 'error_temporarily_restricted',
  ProxyInvalid = 'error_proxy_invalid',
  NameViolation = 'error_name_violation',
  VerificationTaskFailed = 'error_verification_task',
  SnSubscriptionOver = 'error_sn_subscription_over',
  WrongCredentials = 'error_login_wrong',
  EmailRestricted = 'error_email_restricted',
  UserLanguage = 'error_user_language',
  NewSnUserIntro = 'error_new_sn_user_intro',
  LoginCaptcha = 'error_login_captcha',
  LoginEmailVerification = 'error_login_email_verification',
  LoginAppVerification = 'error_login_app_verification',
  LoginFailed = 'error_login_failed',
  LoginTwoSteps = 'error_login_two_steps',
  SnNotActive = 'error_sn_not_active',
  SnSeatManagement = 'error_sn_seat_management',
  MsgFocusedInboxTabs = 'error_msg_focused_inbox_tabs',
  Default = 'error_default',
  MultipleSn = 'error_multiple_sn',
  ConnectionsCountInsufficient = 'error_connections_count_insufficient',
  SnHasOktaSso = 'error_sn_has_okta_sso',
  OccurredPage = 'error_occurred_page',
  ConnectionError = 'login_connection_error',
  ServerError = 'login_server_error',
}
