import {Button} from 'antd';
import classNames from 'classnames';
import React, {useState} from 'react';

import {ReactComponent as ArrowDownIcon} from '../../assets/svg/nav_arrow_down.svg';
import {ReactComponent as Sparks} from '../../assets/svg/sparks.svg';

import './Plan.scss';

export default function Plan({plan, isPopular, period, button, isCollapsable, highlighted}) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={classNames('plan_container', {popular: isPopular, highlighted})}>
      {isPopular && (
        <div className="popular_badge">
          Most Popular
          <Sparks />
        </div>
      )}
      <div className="plan_header">
        {plan.icon}
        <h3>{plan.title}</h3>
        {plan.headline && <p className="subtitle">{plan.headline}</p>}
      </div>
      <div className="plan_price">
        <h4>
          <span className="price">{plan.prices[period].price}</span>
          <span>{plan.prices[period].description}</span>
        </h4>
      </div>
      <div className={classNames('plan_content', {plan_collapse: isCollapsable, show: !collapsed})}>
        <div className="plan_collapse_content">{plan.features}</div>
        {isCollapsable && (
          <Button type="link" className={classNames({show: !collapsed})} onClick={() => setCollapsed(v => !v)}>
            {collapsed ? 'Show more' : 'Show less'} <ArrowDownIcon />
          </Button>
        )}
      </div>
      {button}
    </div>
  );
}
