import {UserErrorCodes} from '@growth-x/types';

import {brandConfig} from '../brandConfig';

export default {
  campaign: {
    not_support_general_search_filters: 'We only support Sales Navigator and LinkedIn Search URLs.',
    error_update_search_url: `{campaign.name} ran out of invites. Please disable invites and keep it going with just follow-ups. Duplicate or create a new campaign and add your new search URL${
      brandConfig.isAffiliate
        ? '.'
        : ', here is how: https://help.growth-x.com/beginner/create-or-duplicate-a-campaign.'
    }`,
    error_big_url_potential:
      'Campaign skipped because the Search URL has a potential of more than 50,000 results. Please split the search in multiple campaigns with smaller potential. For best results, potential should be between 300 and 2,500.',
    error_zero_url_potential: 'Campaign skipped because the Search URL has 0 results. Please, update the URL.',
    error_outdated_search_url: `The Search URL of this campaign needs to be updated${
      brandConfig.intercomFeature ? `, please follow this guide ${brandConfig.articles.errorMessageUi}` : `.`
    }`,
    error_csv_feature: `{campaign.name} is using a CSV file but this premium feature is disabled, please contact {brandConfig.contact}.`,
    error_update_multiple_search_url:
      'Most invites have already been sent to search results of all generated search URLs',
    error_email_restricted_warning:
      'User with email {user.email} is temporarily restricted. Pending invites are being deleted automatically and invites will not send for 48 hours.',
    error_email_invites_all_messages_sent: 'Email invites is completed and all messages were sent',
    error_user_not_selected: 'The user is undefined, please make sure to set it up before running the campaign.',
    error_second_followup_undefined:
      'The second followup message is undefined, please make sure to fill it up before running the campaign.',
    error_third_followup_undefined:
      'The third followup message is undefined, please make sure to fill it up before running the campaign.',
    error_message_request: '{campaign.name} is completed and all messages were sent.',
    error_all_messages_sent: 'First messages have gone out, please deactivate them and keep the follow-ups running',
    error_update_csv:
      '{campaign.name} ran out of invites. Please disable invites. Duplicate or create a new campaign and add new CSV files.',
    error_profile_view_restricted:
      'User with email {user.email} is temporarily restricted. All messages will be send from next week.',
    error_email_provider_no_credentials: `User with email {user.email} is not ready to run acquisition+ campaigns, please contact {brandConfig.contact} to set it up.`,
    warning_sn_search_no_connection_filter:
      'Please select a connection filter in your saved search in order to reach a more relevant audience and a higher connection rate.',
    error_sn_search_view:
      'User {user.email} has reached the maximum number of search page views, it will restart sending invites automatically within 24 hours',
    error_weekly_limit_warning:
      'User with email {user.email} reached the invite weekly limit restriction. Invites will start to send again next Monday. You do not need to take any action.',
    error_monthly_limit_warning:
      'User reached the inmail monthly limit warning. Inmail will start to send again next Month. You do not need to take any action.',
    error_wrong_search_url: 'This campaign cannot run because the Search URL is invalid. Please update the search url.',
    error_no_group_filter:
      'In order to use a message request campaign, you have to use the group filter. Please open the Saved Search URL or Regular URL with the relevant user, add group filter and save',
    error_too_many_requests: "There are some issues with LinkedIn Sales Navigator Search, we'll retry in 24 hours.",
    error_request_timed_out:
      'Campaign was skipped because LinkedIn is having problems loading the page. This usually happens when the Search URL is too long or complex.  We suggest to try making the Search URL shorter (below 7k characters) or trying to convert it into a Saved Search (e.g. "https://www.linkedin.com/sales/search/people?savedSearchId=…"). If you tried both options, please contact us.',
    error_update_retention: '{campaign.name} is completed and all messages were sent.',
    error_email_provider_import_error:
      'There is a Linkedin bug that causes issues with the invites of Acquitision+ campaigns. Once it gets resolved, we will update you.',
    bad_csv_columns: 'Uploaded file has wrong columns, please follow guide to create the CSV file',
    error_update_retention_csv: '{campaign.name} is completed and all messages were sent.',
    error_profile_view_limit_reached: `This user ({user.email}) has reached the profile views limit for today, this campaign will restart automatically tomorrow. ${
      brandConfig.isAffiliate
        ? ''
        : `Please read this article for a detailed explanation: ${brandConfig.articles.profileViewLimit}`
    }`,
    error_default: `Some error happened in user of this email {user.email}, please contact {brandConfig.contact}.`,
    error_invite_message_limit: 'Invite message is too long, please rewrite the message to make it shorter',
    error_campaign_sources_limited:
      'Your current subscription plan does not support SN Navigator URLs. Upgrade your plan now to access advanced features.',
    error_invite_li_basic_personalized_invite:
      'Users without Sales Navigator subscription cannot send personalized invites. Please fix this in campaign settings.',
  },
  user: {
    [UserErrorCodes.NameViolation]:
      '{user.email} not using the real name which may lead to LinkedIn account restriction',
    [UserErrorCodes.VerificationTaskFailed]: `The attempt of verification step has failed for some reason, please try to validate the user again. If the error persists contact {brandConfig.contact}.`,
    [UserErrorCodes.SnSubscriptionOver]:
      'User with email {user.email} has Sales Navigator subscription expired, please renew https://www.linkedin.com/sales',
    [UserErrorCodes.WrongCredentials]: '{user.email} credentials are wrong, please fix them.',
    [UserErrorCodes.AgreeNotScrape]: `There is a problem with {user.email}. If it doesn't resolve within 24 hours, please contact us via in-app chat. (Error code: a_d)`,
    [UserErrorCodes.EmailRestricted]: `User restricted contact {brandConfig.contact}`,
    [UserErrorCodes.UserLanguage]: `User with email {user.email} doesn't have english as default language. Please change it.`,
    [UserErrorCodes.NewSnUserIntro]:
      'The user with email {user.email} has not completed the Sales Navigator setup. Please visit https://www.linkedin.com/sales to finish the setup.',
    [UserErrorCodes.LoginCaptcha]: `{user.email} requires to solve the captcha for login. Please open your browser, manually login, and resolve the captcha. Then clear the error and restart the campaigns. If it happens often with the same user please reach out to {brandConfig.contact}.`,
    [UserErrorCodes.LoginEmailVerification]: `The email {user.email} required email verification, please, do the login with webapp. If it doesn't help contact {brandConfig.contact}.`,
    [UserErrorCodes.LoginAppVerification]: `The email {user.email} required LinkedIn app verification, please, do the login with webapp. If it doesn't help contact {brandConfig.contact}.`,
    [UserErrorCodes.TemporarilyRestricted]: '{user.email} has been temporarily restricted.',
    [UserErrorCodes.LoginFailed]: `{user.email} is having some problems logging in. Try to open your browser, login manually, and run the campaigns again. if this doesn't help contact {brandConfig.contact}.`,
    [UserErrorCodes.LoginTwoSteps]: `The email {user.email} is configured to have two-steps verification, please, do the login manually and disable it on linkedIn settings session. If it doesn't help contact {brandConfig.contact}.`,
    [UserErrorCodes.LoginIdentityRequired]: '{user.email} has been restricted and requires identity verification.',
    [UserErrorCodes.SnNotActive]:
      "User with email {user.email} doesn' t have a premium account with Sales Navigator enabled, please upgrade to keep using this user",
    [UserErrorCodes.SnSeatManagement]:
      'User with email {user.email} should manage the seats in the Sales Navigator, please visit https://www.linkedin.com/sales',
    [UserErrorCodes.MsgFocusedInboxTabs]:
      'This user is not properly saving conversation because it has LinkedIn Focused Inbox feature ON. Please go to https://www.linkedin.com/mypreferences/d/categories/privacy and turn it OFF.',
    [UserErrorCodes.Default]: `Some error happened in user of this email {user.email}, please contact {brandConfig.contact}.`,
    [UserErrorCodes.MultipleSn]: `This user has more than one Sales Navigator Subscription, please choose the right one ${
      brandConfig.isAffiliate ? '.' : `following this guide ${brandConfig.articles.multipleSalesNavigator}.`
    }`,
    [UserErrorCodes.ConnectionsCountInsufficient]: `User with email {user.email} has less than 500 connections and it is at risk of getting restricted by LinkedIn. We strongly recommend to remove this user from any campaign and only start using ${brandConfig.name} when you manually reached out to 500 connections.`,
    [UserErrorCodes.SnHasOktaSso]:
      'User with email {user.email} has an Okta SSO integration. Please contact {brandConfig.contact}.',
    [UserErrorCodes.ProxyInvalid]:
      'User with email {user.email} has bad proxy settings. Please contact {brandConfig.contact}.',
  },
};
