import {Form, Input, Button, Icon, Alert, Select} from 'antd';
import {Formik} from 'formik';
import React, {useState} from 'react';
import * as Yup from 'yup';

import {ClientData, SignupRequestParams} from '@growth-x/types';

import {brandConfig, STRINGS, getCookie} from '../../utils';
import './registerForm.css';

interface RegisterFormProps {
  client: ClientData;
  signup: (params: SignupRequestParams) => Promise<boolean>;
  analyticsService: any;
}

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
  heard_from: Yup.string().max(300, 'Allowed 300 characters max'),
});

const HEARD_FROM = [
  {id: 1, value: 'Facebook'},
  {id: 2, value: 'LinkedIn'},
  {id: 3, value: 'Email'},
  {id: 4, value: 'Referral'},
  {id: 5, value: 'Search Engine'},
  {id: 6, value: 'Other'},
];

const OUTBOUND_SALES_REPS = [
  {id: 1, value: '1'},
  {id: 2, value: '2 to 5'},
  {id: 3, value: '5 to 10'},
  {id: 4, value: '10+'},
];

export function RegisterForm({client: {status, loading}, signup, analyticsService}: RegisterFormProps) {
  const [otherChosen, setOtherChosen] = useState(false);
  const onSubmit = async (form: any, config: any) => {
    const utm_campaign = getCookie('utm_campaign');
    const isCreated = await signup({
      name: form.name,
      email: form.email,
      password: form.password,
      heard_from: form.hearFrom !== 'Other' ? form.hearFrom : form.heard_from,
      attributed: !!utm_campaign,
      outbound_sales_reps: form.outbound_sales_reps,
    });
    if (isCreated) {
      if (config.isLeadoku) {
        try {
          (window as any).gr('track', 'conversion', {email: form.email});
        } catch (error) {
          console.error(error);
        }
      }
      if (config.isLeadoku || config.isGx) {
        try {
          (window as any).dataLayer.push({event: 'form_submitted'});
        } catch (error) {
          console.error(error);
        }
        try {
          if (utm_campaign) {
            analyticsService.sendEvent({event_type: 'signup', code: utm_campaign});
          }
        } catch (error) {
          console.error(error);
        }
        try {
          (window as any)._hsq = (window as any)._hsq || [];
          (window as any)._hsq.push([
            'identify',
            {
              email: form.email,
              firstname: form.name,
            },
          ]);
          (window as any)._hsq.push(['trackPageView']);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  return (
    <Formik
      initialValues={{name: '', email: '', password: '', heard_from: '', outbound_sales_reps: undefined}}
      validationSchema={RegisterSchema}
      onSubmit={form => onSubmit(form, brandConfig)}
    >
      {({values, handleSubmit, setFieldValue, errors, touched}: any) => (
        <Form className="register-form" onSubmit={handleSubmit}>
          <Form.Item
            validateStatus={errors.name && touched.name ? 'error' : ''}
            hasFeedback
            help={errors.name && touched.name ? errors.name : ''}
          >
            <Input
              prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />}
              value={values.name}
              onChange={(ev: any) => setFieldValue('name', ev.target.value)}
              placeholder="First Name*"
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors.email && touched.email ? 'error' : ''}
            hasFeedback
            help={errors.email && touched.email ? errors.email : ''}
          >
            <Input
              prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}} />}
              type="text"
              placeholder="Email*"
              autoComplete="off"
              value={values.email}
              onChange={(ev: any) => setFieldValue('email', ev.target.value)}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors.password && touched.password ? 'error' : ''}
            hasFeedback
            help={errors.password && touched.password ? errors.password : ''}
          >
            <Input
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              type="password"
              autoComplete="off"
              placeholder="Password*"
              value={values.password}
              onChange={(ev: any) => setFieldValue('password', ev.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Select
              placeholder="How did you hear about us?"
              value={values.hearFrom}
              onChange={(val: any) => {
                setFieldValue('hearFrom', val);
                setOtherChosen(val === 'Other');
              }}
            >
              {HEARD_FROM.map(hearItem => {
                return (
                  <Select.Option key={hearItem.id} value={hearItem.value}>
                    {`${hearItem.value}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {otherChosen && (
            <Form.Item validateStatus={errors.heard_from && touched.heard_from ? 'error' : ''}>
              <Input.TextArea
                placeholder={'Input your option here'}
                autoSize={{minRows: 1, maxRows: 10}}
                value={values.heard_from}
                onChange={(ev: any) => setFieldValue('heard_from', ev.target.value)}
              />
              {
                <div style={{justifyContent: 'space-between', display: 'flex'}}>
                  <span style={errors.heard_from ? {color: 'red'} : {}}>
                    {errors.heard_from ? 'Allowed 300 characters max ' : ''}
                  </span>
                  <span style={errors.heard_from ? {color: 'red'} : {}}>{values.heard_from.length}/300</span>
                </div>
              }
            </Form.Item>
          )}
          <Form.Item>
            <Select
              placeholder="How many outbound sales reps are in your company?"
              value={values.outbound_sales_reps}
              onChange={(val: any) => setFieldValue('outbound_sales_reps', val)}
            >
              {OUTBOUND_SALES_REPS.map(amountItem => (
                <Select.Option key={amountItem.id} value={amountItem.value}>
                  {`${amountItem.value}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!!status.length && (
            <Alert message="Sign up failed" description={status} type="error" style={{marginBottom: '10px'}} />
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Sign up
            </Button>
            {!brandConfig.isAffiliate && (
              <span className="register-form_licence">
                By signing up you agree to our{' '}
                <a href={STRINGS.url.terms} target="_blank" rel="noopener noreferrer">
                  Terms of Use
                </a>{' '}
                and{' '}
                <a href={STRINGS.url.privacy} target="_blank">
                  Privacy Policy
                </a>
              </span>
            )}
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
