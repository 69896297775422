import React from 'react';

import {CONSTANTS} from '../../utils';
import {GX_PLANS} from './ChoosePlanGxModal';
import Plan from './Plan';

export function CorePlan({period}) {
  const plan = GX_PLANS.find(p => p.key === CONSTANTS.BILLING.PLANS.CORE);
  return <Plan isPopular={false} plan={plan} period={period} button={null} isCollapsable={true} highlighted={true} />;
}
