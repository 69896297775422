import {message} from 'antd';

import {WorldService} from '../../services';

export const GET_COUNTRY_LIST_REQUEST = 'GET_COUNTRY_LIST_REQUEST';
export const GET_COUNTRY_LIST_FAILURE = 'GET_COUNTRY_LIST_FAILURE';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_CURRENT_COUNTRY_REQUEST = 'GET_CURRENT_COUNTRY_REQUEST';
export const GET_CURRENT_COUNTRY_FAILURE = 'GET_CURRENT_COUNTRY_FAILURE';
export const GET_CURRENT_COUNTRY_SUCCESS = 'GET_CURRENT_COUNTRY_SUCCESS';
export const UPDATE_SUGGESTED_PROXY_MODAL = 'UPDATE_SUGGESTED_PROXY_MODAL';

export const proxyActions = {
  getCountryList,
  getClientCountry,
  updateSuggestedProxy,
};

function getCountryList() {
  return async (dispatch: any) => {
    dispatch(request());
    return await WorldService.getCountryList().then(
      ({data}: any) => {
        dispatch(success(data));
      },
      () => {
        message.error('Could not get country list');
        dispatch(failure());
      }
    );
  };
  function request() {
    return {type: GET_COUNTRY_LIST_REQUEST};
  }
  function success(data) {
    return {type: GET_COUNTRY_LIST_SUCCESS, data};
  }
  function failure() {
    return {type: GET_COUNTRY_LIST_FAILURE};
  }
}

function getClientCountry() {
  return async (dispatch: any) => {
    dispatch(request());
    return await WorldService.getClientCountry().then(
      ({data}: any) => {
        dispatch(success(data));
      },
      () => {
        message.warning('Could not get your current location');
        dispatch(failure());
      }
    );
  };
  function request() {
    return {type: GET_CURRENT_COUNTRY_REQUEST};
  }
  function success(data) {
    return {type: GET_CURRENT_COUNTRY_SUCCESS, data};
  }
  function failure() {
    return {type: GET_CURRENT_COUNTRY_FAILURE};
  }
}

function updateSuggestedProxy(suggestedProxy) {
  return dispatch => {
    dispatch({type: UPDATE_SUGGESTED_PROXY_MODAL, suggestedProxy});
  };
}
