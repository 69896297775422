import React from 'react';
import {Link} from 'react-router-dom';

import {STRINGS} from '../constants';

export function parseCardsList(conversation, openReceiverInConnections) {
  const companyProfileId = conversation?.receiver?.company_profile_id;
  return [
    {
      key: 'email',
      title: 'Email',
      value: conversation?.receiver?.email || 'Unknown',
      icon: {prefix: 'fas', iconName: 'envelope'},
      extra: conversation?.receiver?.custom_email || '',
    },
    {
      key: 'phone',
      title: 'Phone',
      value: conversation?.receiver?.phone || 'Unknown',
      icon: {prefix: 'fas', iconName: 'phone'},
      extra: conversation?.receiver?.custom_phone_number || '',
    },
    {
      key: 'location',
      title: 'Location',
      value: conversation?.receiver?.location || 'Unknown',
      icon: {prefix: 'fas', iconName: 'location-arrow'},
    },
    {
      key: 'company',
      title: 'Company',
      value: conversation?.receiver?.company,
      icon: {prefix: 'fas', iconName: 'building'},
      link: companyProfileId && STRINGS.company_url + conversation?.receiver?.company_profile_id,
      crm: !!companyProfileId,
    },
    {
      key: 'headline',
      title: 'Headline',
      value: conversation?.receiver?.headline,
      icon: {prefix: 'fas', iconName: 'bookmark'},
    },
    {
      key: 'campaigns',
      title: 'Campaigns',
      value:
        conversation?.campaigns && Array.isArray(conversation?.campaigns) ? (
          <>
            {conversation.campaigns.map((campaign, index) => (
              <span key={campaign.id}>
                {index !== 0 && ', '}
                <Link
                  style={{textDecoration: 'underline', color: 'rgba(0,0,0,0.65)'}}
                  to={`/?campaign_id=${campaign.id}`}
                >
                  {campaign.name}
                </Link>
              </span>
            ))}
          </>
        ) : null,
      icon: {prefix: 'fas', iconName: 'list-ul'},
    },
    {
      key: 'deal_amount',
      title: 'Deal Amount',
      icon: {prefix: 'fas', iconName: 'dollar-sign'},
      value: conversation?.deals_amount,
    },
    {
      key: 'open_in_connections',
      title: 'Open in Connections',
      value: (
        <Link
          style={{textDecoration: 'underline', color: 'rgba(0,0,0,0.65)'}}
          to={`/connections`}
          onClick={() => {
            openReceiverInConnections(conversation?.receiver?.id);
          }}
        >
          Open in Connections
        </Link>
      ),
      icon: {prefix: 'fas', iconName: 'link'},
      crm: false,
    },
    {
      key: 'open_in_salesforce',
      title: 'Open in Salesforce',
      value: 'Open in Salesforce',
      link: conversation.salesforce_url,
      icon: {prefix: 'fas', iconName: 'link'},
      crm: true,
      disabled: !conversation.salesforce_url,
    },
    {
      key: 'open_in_hubspot',
      title: 'Open in Hubspot',
      value: 'Open in Hubspot',
      link: conversation.hubspot_url,
      icon: {prefix: 'fas', iconName: 'link'},
      crm: true,
      disabled: !conversation.hubspot_url,
    },
  ];
}
