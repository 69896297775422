import {Icon, Menu, Tooltip} from 'antd';
import * as React from 'react';
import {isBrowser, isTablet} from 'react-device-detect';
import {Link} from 'react-router-dom';

import {InfoCircleOutlined} from '@ant-design/icons';
import {findIconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HeaderModalType} from '@growth-x/types';

import {brandConfig} from '../../utils';

const {SubMenu} = Menu;

export default function AccountMenu({onClick, team_member, client, isAdmin}: any) {
  const isDesktopOrTablet = isBrowser || isTablet;
  const shouldShowBilling =
    isDesktopOrTablet && client.type !== 'testing' && (client.subscription_account_id || isAdmin);

  // TODO does sequence of items in list matter
  // TODO rewrite items in sort of mapper below, but i dont know how yet
  const menuItems = [
    {
      key: HeaderModalType.SETTINGS,
      label: 'Settings',
      icon: <Icon type="setting" />,
    },
    {
      key: HeaderModalType.MEMBERS,
      label: 'Manage Members',
      icon: <Icon type="team" />,
      disabled: team_member,
      tooltip: team_member && 'Only owner allowed to modify',
    },
    {
      key: HeaderModalType.NOTIFICATION_SETTINGS,
      label: 'Manage Notifications',
      icon: <Icon type="notification" />,
      disabled: team_member && !team_member?.user,
      tooltip: team_member && !team_member?.user && 'Only owner allowed to modify',
    },
    {
      key: HeaderModalType.PASSWORD,
      label: 'Change password',
      icon: <Icon type="lock" />,
      disabled: team_member,
      tooltip: team_member && 'Only owner allowed to modify',
    },
  ];

  return (
    <Menu onClick={onClick} selectedKeys={[]} className="accountSwitcher_menu">
      {isDesktopOrTablet &&
        menuItems.map(item => (
          <Menu.Item key={item.key} disabled={item.disabled}>
            {item.tooltip ? (
              <Tooltip title={item.tooltip} onVisibleChange={visible => (item.disabled ? visible : false)}>
                {item.icon} {item.label}
              </Tooltip>
            ) : (
              <>
                {item.icon} {item.label}
              </>
            )}
          </Menu.Item>
        ))}
      {shouldShowBilling && (
        <SubMenu
          title={
            <span>
              <Icon type="dollar" />
              <span>Billing</span>
            </span>
          }
        >
          <Menu.Item key={HeaderModalType.SUBSCRIPTION} disabled={team_member}>
            {team_member && (
              <Tooltip
                title="Only owner allowed to modify"
                onVisibleChange={visible => (team_member ? visible : false)}
              >
                <Icon type="tool" /> Manage Subscription
              </Tooltip>
            )}
            {!team_member && (
              <>
                <Icon type="tool" /> Manage Subscription
              </>
            )}
          </Menu.Item>
          {client.subscription_account_id && !brandConfig.isAffiliate && (
            <Menu.Item key={HeaderModalType.INVOICES} disabled={team_member}>
              <Icon type="profile" /> Invoices
            </Menu.Item>
          )}
          {client.subscription_account_id && !brandConfig.isAffiliate && (
            <Menu.Item key={HeaderModalType.PAYMENT} disabled={team_member}>
              <Icon type="credit-card" /> Payment Methods
            </Menu.Item>
          )}
          {isAdmin && (
            <Menu.Item key="customer_billing">
              <Icon type="bank" /> Customer Billing
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {isDesktopOrTablet && (
        <Menu.Item key={HeaderModalType.PROFILE}>
          <Icon type="user" /> Profile
        </Menu.Item>
      )}
      {isDesktopOrTablet && (isAdmin || client.manage_cloud_feature) && (
        <Menu.Item key={HeaderModalType.ADMIN_TOOLS}>
          <FontAwesomeIcon
            style={{color: brandConfig.primaryColor, marginRight: '5px'}}
            icon={findIconDefinition({
              prefix: 'fas',
              iconName: 'user-shield',
            })}
          />{' '}
          Admin tools
        </Menu.Item>
      )}
      {isDesktopOrTablet &&
        (brandConfig.salesforceFeature ||
          brandConfig.zapierFeature ||
          brandConfig.hubspotFeature ||
          client.instantly_feature) && (
          <SubMenu
            title={
              <span>
                <FontAwesomeIcon style={{marginRight: 10}} icon="exchange-alt" />
                <span>Integrations</span>
              </span>
            }
          >
            {brandConfig.salesforceFeature && (
              <Menu.Item key={HeaderModalType.SALESFORCE}>
                <Icon type="login" /> Salesforce
              </Menu.Item>
            )}
            {brandConfig.zapierFeature && (
              <Menu.Item key={HeaderModalType.ZAPIER}>
                <Icon type="login" /> Zapier
              </Menu.Item>
            )}
            {brandConfig.hubspotFeature && (
              <Menu.Item key={HeaderModalType.HUBSPOT}>
                <Icon type="login" /> Hubspot
              </Menu.Item>
            )}
            {client.rb2b_feature_enabled && brandConfig.rb2bFeature && (
              <Menu.Item key={HeaderModalType.RB2B}>
                <Icon type="login" /> RB2B
              </Menu.Item>
            )}
            {brandConfig.apiSourceFeature && (
              <Menu.Item key={HeaderModalType.API}>
                <Icon type="login" /> API Token
              </Menu.Item>
            )}
            {client.instantly_feature && (
              <Menu.Item key={HeaderModalType.INSTANTLY}>
                <FontAwesomeIcon style={{marginRight: 10}} icon={faEnvelope} /> Instantly
              </Menu.Item>
            )}
            <Menu.Item key={HeaderModalType.WEBHOOK}>
              <Icon type="login" />
              Webhooks
            </Menu.Item>
          </SubMenu>
        )}
      {brandConfig.isGx && isDesktopOrTablet && (
        <Menu.Item key={HeaderModalType.ABOUT_US}>
          <Link to={{pathname: `https://growth-x.com/`}} target={`_blank`}>
            <InfoCircleOutlined />
            About us
          </Link>
        </Menu.Item>
      )}
      {isDesktopOrTablet && <Menu.Divider />}
      <Menu.Item key={HeaderModalType.LOGOUT}>
        <Icon type="logout" /> Log out
      </Menu.Item>
    </Menu>
  );
}
