import {connect} from 'react-redux';

import {UserEditModal} from '@growth-x/ui';

import {prepareUsers} from '../../pages/users/connector';
import {clientMaintenanceActions} from '../../redux/clientMaintenance';
import {usersActions} from '../../redux/users';

function mapState(state: any, ownProps: any) {
  const users = prepareUsers(state.users.list, state.settings.isAdmin);
  return {
    users,
    client: state.client.data,
    isAdmin: state.settings.isAdmin,
    user: state.users.userDetails,
    isNewUser: !state.users.userDetails.id,
    members: state.members.list,
    loading: state.users.userDetailsLoading,
    error: state.users.userDetailsError,
    affiliatedClients: state.clientMaintenance.affiliatedClients,
    serverState: state.client.serverState,
    handleOk: ownProps.handleOk,
    handleCancel: ownProps.handleCancel,
    archiveUser: ownProps.archiveUser,
    unarchiveUser: ownProps.unarchiveUser,
    replaceUser: ownProps.replaceUser,
    validateUser: ownProps.validateUser,
    openUser: ownProps.openUser,
    handleTwitter: ownProps.handleTwitter,
    trackIntercomEvent: ownProps.trackIntercomEvent,
    visible: ownProps.visible,
    clearCookies: ownProps.clearCookies,
    resetLoginErrorDate: ownProps.resetLoginErrorDate,
    scheduleArchive: ownProps.scheduleArchive,
    cancelScheduledArchive: ownProps.cancelScheduledArchive,
    locationsInfo: state.proxy.locationsInfo,
    suggestedProxy: state.proxy.suggestedProxy,
  };
}

const actionCreators = {
  cleanup: usersActions.cleanupUser,
  revealPassword: usersActions.revealPassword,
  changeUserState: usersActions.changeUserState,
  snoozeLoginError: usersActions.snoozeLoginError,
  updateSnSubsctiptionSelect: usersActions.updateSnSubsctiptionSelect,
  getAffiliatedClients: clientMaintenanceActions.getAffiliatedClients,
  moveUser: clientMaintenanceActions.moveUser,
  removeProxy: usersActions.removeProxy,
  getProxyLocations: usersActions.getProxyLocations,
};

export const UserEditModalContainer = connect(mapState, actionCreators)(UserEditModal);
