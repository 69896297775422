export class ProxyService {
  client: any;
  serviceName = 'api/v2/proxy';
  constructor({client}: any) {
    this.client = client;
  }
  add(userId: number, country: string) {
    return this.client.post(`${this.serviceName}/add`, {user: userId, country});
  }
  getLocations() {
    return this.client.get(`${this.serviceName}/locations`, {});
  }
  addCustom(params) {
    return this.client.post(`${this.serviceName}/custom-add`, {...params});
  }
  remove(userId) {
    return this.client.post(`${this.serviceName}/remove`, {user: userId});
  }
}
