import {Button, Col, Form, Input, Row, Switch, Tooltip, Select, Icon, Alert} from 'antd';
import React, {useState, useEffect, useMemo} from 'react';

import {Country, Client} from '@growth-x/types';

const {Option} = Select;

interface UserProxyProps {
  client: Client;
  proxy: any;
  errors: any;
  touched: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  handleBlur: any;
  isCustomProxy: boolean;
  setIsCustomProxy: (value: boolean) => void;
  removeProxy: (userId: number) => void;
  getProxyLocations: () => Promise<Country[]>;
  isAdmin: boolean;
  locationsInfo: any;
  suggestedProxy: any;
}

export function ProxySettings({
  client,
  proxy,
  errors,
  touched,
  values,
  setFieldValue,
  handleBlur,
  isCustomProxy,
  setIsCustomProxy,
  isAdmin,
  removeProxy,
  suggestedProxy,
  locationsInfo,
  getProxyLocations,
}: Readonly<UserProxyProps>) {
  const [countryList, setCountryList] = useState<Country[]>(locationsInfo.countryList);
  const [isLoading, setIsLoading] = useState(false);
  const proxyCountry = useMemo(() => proxy?.location && countryList.find(c => c.iso2 === proxy.location.country), [
    proxy,
  ]);

  useEffect(() => {
    if (suggestedProxy.showSuggestedProxy) {
      setCountryList(suggestedProxy.suggestedProxies);
      const isCurrentLocationAvailable = suggestedProxy.suggestedProxies.find(
        item => item.iso2 === locationsInfo.currentLocation
      );
      setFieldValue('country', isCurrentLocationAvailable ? locationsInfo.currentLocation : undefined);
    }
  }, [suggestedProxy.showSuggestedProxy]);

  useEffect(() => {
    const isCurrentLocationAvailable = countryList.find(item => item.iso2 === locationsInfo.currentLocation);
    setFieldValue('country', isCurrentLocationAvailable ? locationsInfo.currentLocation : undefined);
  }, [countryList]);

  useEffect(() => {
    async function getReadyProxyCountries() {
      setIsLoading(true);
      const countryList = await getProxyLocations();
      setCountryList(countryList);
      setIsLoading(false);
    }
    if (values.id && !proxy?.ip) {
      getReadyProxyCountries();
    }
  }, [proxy]);

  const onSwitcherChange = (value: boolean) => {
    setIsCustomProxy(value);
    if (value) {
      setFieldValue('country', undefined);
    } else {
      setFieldValue('custom_proxy_ip', undefined);
      setFieldValue('custom_proxy_username', undefined);
      setFieldValue('custom_proxy_port', undefined);
      setFieldValue('custom_proxy_password', undefined);
    }
  };

  const onRemoveProxy = () => removeProxy(values.id);

  if (proxy?.ip) {
    return (
      <Form.Item
        style={{marginTop: 10}}
        labelCol={{span: 10}}
        wrapperCol={{span: 14}}
        label={<span>Proxy IP location:</span>}
      >
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: -5}}>
          <div style={{textAlign: 'right', marginRight: 10}}>
            <span>{proxy.ip}</span>
            <br />
            <span>{proxyCountry && `(${proxyCountry.name})`}</span>
          </div>
          {(proxy?.is_custom_proxy || isAdmin) && (
            <Button type="danger" onClick={() => onRemoveProxy()}>
              Clear
            </Button>
          )}
        </div>
      </Form.Item>
    );
  }
  const showProxySelect = ((!values.first_name || suggestedProxy.showSuggestedProxy) && client.new_proxy_ui) || isAdmin;

  return (
    <>
      <Row style={{marginBottom: 10}}>
        <Col span={16}>
          <b style={{fontSize: 14}}>{values.id ? 'Proxy ' : null}Location:</b>{' '}
          <Tooltip title="Please select bot location.">
            <Icon type="info-circle" className="tooltip-icon" style={{marginRight: 5}} />
          </Tooltip>
        </Col>
        <Col span={8} style={{textAlign: 'right'}}>
          <span style={{fontWeight: 400, marginRight: 5, fontSize: 12}}>my proxy</span>
          <Tooltip title="Use my proxy settings">
            <Switch onChange={onSwitcherChange} checked={isCustomProxy} disabled={false} size="small" />
          </Tooltip>
        </Col>
      </Row>
      {!isCustomProxy && showProxySelect && (
        <Form.Item>
          <Select
            showSearch
            loading={isLoading}
            placeholder="Select a location"
            optionFilterProp="children"
            value={values.country}
            onChange={value => setFieldValue('country', value)}
            filterOption={(input, option) =>
              (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={isLoading}
          >
            {countryList.map(country => (
              <Option key={country.iso2} value={country.iso2}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {isCustomProxy && (
        <Row gutter={16} style={{marginTop: '-10px'}}>
          <Col span={12}>
            <Form.Item
              label="Ip:"
              validateStatus={errors.custom_proxy_ip && touched.custom_proxy_ip ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_ip && touched.custom_proxy_ip ? errors.custom_proxy_ip : ''}
            >
              <Input
                name="ip"
                value={values.custom_proxy_ip}
                placeholder="___.___.___.___"
                onChange={ev => setFieldValue('custom_proxy_ip', ev.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Username:"
              validateStatus={errors.custom_proxy_username && touched.custom_proxy_username ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_username && touched.custom_proxy_username ? errors.custom_proxy_username : ''}
            >
              <Input
                name="custom_proxy_username"
                value={values.custom_proxy_username}
                placeholder="Type the username"
                onChange={ev => setFieldValue('custom_proxy_username', ev.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Port:"
              validateStatus={errors.custom_proxy_port && touched.custom_proxy_port ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_port && touched.custom_proxy_port ? errors.custom_proxy_port : ''}
            >
              <Input
                name="custom_proxy_port"
                value={values.custom_proxy_port}
                placeholder="Type the port"
                onChange={ev => setFieldValue('custom_proxy_port', ev.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Password:"
              validateStatus={errors.custom_proxy_password && touched.custom_proxy_password ? 'error' : ''}
              hasFeedback
              help={errors.custom_proxy_password && touched.custom_proxy_password ? errors.custom_proxy_password : ''}
            >
              <Input.Password
                autoComplete="new-password"
                value={values.custom_proxy_password}
                name="custom_proxy_password"
                className={errors.custom_proxy_password && touched.custom_proxy_password ? 'invalid-input' : ''}
                onChange={ev => setFieldValue('custom_proxy_password', ev.target.value)}
                onBlur={handleBlur}
                placeholder={proxy?.custom_proxy_password ? '*******************' : 'Type your password'}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {suggestedProxy.showSuggestedProxy && (
        <Row style={{marginBottom: '10px'}}>
          <Alert
            message="We couldn’t create a proxy in your selected location. Please choose the nearest available location from the list above."
            type="error"
          />
        </Row>
      )}
    </>
  );
}
