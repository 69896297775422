import {connect} from 'react-redux';

import {ShortNewUserModal} from '@growth-x/ui';

import {prepareUsers} from '../../pages/users/connector';
import {usersActions} from '../../redux/users';

function mapState(state: any, ownProps: any) {
  const users = prepareUsers(state.users.list, state.settings.isAdmin);
  return {
    users,
    client: state.client.data,
    user: state.users.userDetails,
    members: state.members.list,
    loading: state.users.userDetailsLoading,
    error: state.users.userDetailsError,
    handleOk: ownProps.handleOk,
    handleCancel: ownProps.handleCancel,
    visible: ownProps.visible,
    archiveUser: ownProps.archiveUser,
    unarchiveUser: ownProps.unarchiveUser,
    isAdmin: state.settings.isAdmin,
    locationsInfo: state.proxy.locationsInfo,
    suggestedProxy: state.proxy.suggestedProxy,
  };
}

const actionCreators = {
  cleanup: usersActions.cleanupUser,
  removeProxy: usersActions.removeProxy,
  addCustomProxy: usersActions.addCustomProxy,
  getProxyLocations: usersActions.getProxyLocations,
  addProxy: usersActions.addProxy,
};

export const ShortNewUserModalContainer = connect(mapState, actionCreators)(ShortNewUserModal);
