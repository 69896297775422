import {Icon, Tooltip, Modal, List, Avatar} from 'antd';
import React, {useEffect, useState} from 'react';

import {PlusCircleOutlined} from '@ant-design/icons';

import {isEmail, isPhoneNumer} from '../../utils/functions';

const {confirm} = Modal;

const getMarkupForLink = link => {
  return (
    <span key={link?.url}>
      {link.url}{' '}
      <Tooltip
        className="conversation__link-info"
        title={`Clicked ${link.click_count} ${link.click_count > 1 ? 'times' : 'time'} ${link.original_url}`}
      >
        <Icon type="link" />
        {link.click_count}
      </Tooltip>
    </span>
  );
};

const replaceTrackableLinksOrCustomFieldsIfNeeded = props => {
  const {message, trackingLinks, addCustomEmailToUserReceiver, addCustomPhoneToUserReceiver, userReceiverId} = props;
  const {sent_by_receiver, content} = message;
  const elements = [];
  let currentToken = '';
  if (!trackingLinks) {
    return content;
  }
  const addTextToElement = () => {
    if (currentToken) {
      elements.push(<span key={currentToken}>{currentToken}</span>);
      currentToken = '';
    }
  };

  content.split(/(\s+)/).forEach(elem => {
    const foundLink = trackingLinks.find(l => l.url === elem);
    if (foundLink) {
      addTextToElement();
      elements.push(getMarkupForLink(foundLink));
    } else if (sent_by_receiver && elem.includes('@') && isEmail(elem)) {
      addTextToElement();
      elements.push(getMarkupForCustomField(userReceiverId, elem, 'e-mail', addCustomEmailToUserReceiver));
    } else if (sent_by_receiver && elem.includes('+') && isPhoneNumer(elem)) {
      addTextToElement();
      elements.push(getMarkupForCustomField(userReceiverId, elem, 'phone number', addCustomPhoneToUserReceiver));
    } else {
      currentToken += elem;
    }
  });
  addTextToElement();

  return elements;
};

const getMarkupForCustomField = (userReceiverId, value, type, func) => {
  return (
    <span
      className="conversation__message-content-markup"
      key={value}
      onClick={() => {
        confirm({
          title: `Do you want to add/change this ${type} in the conversation details?`,
          content: value,
          onOk: async () => {
            await func(value, userReceiverId);
          },
        });
      }}
    >
      <Tooltip title={`Add to conversation details...`}>
        <u className="conversation__message-content-markup">{value}</u>
        <PlusCircleOutlined className="conversation__message-content-markup-icon" />
      </Tooltip>
    </span>
  );
};

const parseUrls = (message, trackingLinks) => {
  const regex = /https?:\/\/[^\s]+[^\s.,!?]/g;
  const urls = message.content.match(regex) || [];
  if (trackingLinks) {
    message.content.split(/(\s+)/).forEach(elem => {
      const foundLink = trackingLinks.find(l => l.url === elem);
      if (foundLink) {
        urls.push(foundLink.original_url);
      }
    });
    return urls.filter(url => !trackingLinks.find(l => l.url === url));
  }
  return urls;
};

export const MessageContent = props => {
  const [previews, setPreviews] = useState([]);
  useEffect(() => {
    const urls = parseUrls(props.message, props.trackingLinks);
    if (urls.length) {
      props.getPreview(urls).then(data => setPreviews(data));
    }
  }, []);
  return (
    <span className="conversation__message-content">
      {replaceTrackableLinksOrCustomFieldsIfNeeded(props)}
      {previews.length > 0 && (
        <List
          className="link-preview-list"
          itemLayout="horizontal"
          dataSource={previews}
          renderItem={(item, index) => (
            <List.Item className="link-preview-item" key={`preview-${index}`}>
              <List.Item.Meta
                avatar={item.image.length ? <Avatar src={item.image} /> : null}
                title={
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                }
                description={item.description}
              />
            </List.Item>
          )}
        />
      )}
    </span>
  );
};
