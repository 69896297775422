export enum HeaderModalType {
  SETTINGS = 'settings',
  MEMBERS = 'members',
  NOTIFICATION_SETTINGS = 'notificationSettings',
  PASSWORD = 'password',
  SUBSCRIPTION = 'subscription',
  INVOICES = 'invoices',
  PAYMENT = 'payment',
  PROFILE = 'profile',
  ADMIN_TOOLS = 'admin_tools',
  SALESFORCE = 'salesforce',
  ZAPIER = 'zapier',
  HUBSPOT = 'hubspot',
  RB2B = 'rb2b',
  API = 'api',
  INSTANTLY = 'instantly',
  WEBHOOK = 'webhook',
  ABOUT_US = 'about-us',
  LOGOUT = 'logout',
  CUSTOMER_BILLING = 'customer_billing',
}
