import {Alert, Icon, Layout, Modal} from 'antd';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {HeaderModalType} from '@growth-x/types';
import {
  AccountPassword,
  AccountSwitcher,
  checkCloudOffline,
  Hubspot,
  InfoIcon,
  LazySalesforce,
  LazyShowCSM,
  LazyShowCSMAdmin,
  LazySubscriptionDetails,
  LazyZapier,
  LazyNotificationSettings,
  STRINGS,
  brandConfig,
  LazyRb2b,
  TrialFinishedModal,
  CONSTANTS,
  LazyApiToken,
} from '@growth-x/ui';

import {
  AccountsSettingsContainer,
  InstantlyContainer,
  MembersDetailsContainer,
  TemplatesModalContainer,
  AdminToolsContainer,
  BillingDetailsContainer,
  LogoContainer,
  FreeInvitesLimitReachedContainer,
  ClientSubscriptionSettingsContainer,
} from '../../containers';
import {adminMaintenanceActions} from '../../redux/adminMaintenance';
import {billingActions} from '../../redux/billing';
import {chargebeeActions} from '../../redux/chargebee';
import {clientActions} from '../../redux/client';
import {headerModalActions} from '../../redux/headerModal';
import {hsOauth2Actions} from '../../redux/hsOauth2';
import {notificationsActions} from '../../redux/notifications';
import {sfOauth2Actions} from '../../redux/sfOauth2';
import {usersActions} from '../../redux/users';
import {LazyWebhooks} from './components/webhooks';

import './header.scss';

const {Header} = Layout;

const modalTitles: Partial<Record<HeaderModalType, string>> = {
  [HeaderModalType.SETTINGS]: 'Settings',
  [HeaderModalType.PASSWORD]: 'Change password',
  [HeaderModalType.SUBSCRIPTION]: 'Manage Subscription',
  [HeaderModalType.NOTIFICATION_SETTINGS]: 'Manage Notifications',
  [HeaderModalType.MEMBERS]: 'Manage Members',
  [HeaderModalType.SALESFORCE]: 'Authorize Salesforce',
  [HeaderModalType.HUBSPOT]: 'Authorize Hubspot',
  [HeaderModalType.INSTANTLY]: 'Instantly Settings',
  [HeaderModalType.ZAPIER]: 'Use Zapier',
  [HeaderModalType.PROFILE]: 'Profile',
  [HeaderModalType.ADMIN_TOOLS]: 'Admin Tools',
  [HeaderModalType.RB2B]: 'Authorize RB2B',
  [HeaderModalType.API]: 'API Token',
  [HeaderModalType.WEBHOOK]: 'Webhooks',
  [HeaderModalType.CUSTOMER_BILLING]: 'Customer Billing',
};

const getTitle = (key?: HeaderModalType): string => modalTitles[key as HeaderModalType] || '';

const DesktopHeader = ({
  client,
  users,
  team_member,
  logout,
  chargebeeInstance,
  notifications,
  toggleNotifications,
  manageSubscription,
  settings,
  updatePassword,
  loadingUpdate,
  errorUpdate,
  getSfAuthorizedCredentials,
  clearSfAuthorizedCredentials,
  setSfAuthorizationCode,
  getHsAuthorizedCredentials,
  clearHsAuthorizedCredentials,
  setHsAuthorizationCode,
  waitSfLogoutRequest,
  waitSfLogoutSuccess,
  getPortalSession,
  sfOauth2,
  hsOauth2,
  changeClientEmail,
  changeClientName,
  changeManagerData,
  markNotificationAsRead,
  shouldLoadMoreNotifications,
  loadMoreNotifications,
  getNotificationSettings,
  setNotificationSettings,
  getHubspotUsers,
  getHubspotPipelines,
  getHubspotSearchProperty,
  configureHubspot,
  headerModal,
  setHeaderModal,
  activateRegularPlan,
  syncRetroactively,
  rb2bLoading,
  rb2bLatestEvent,
  clientTags,
  generateApiToken,
  getApiToken,
  info,
  getBillingInfo,
}: any) => {
  const [trialBannerClosed, setTrialBannerClosed] = useState(
    !!sessionStorage.getItem(CONSTANTS.trial_ended_banner_closed)
  );
  const isCloudOffline = checkCloudOffline(client?.data?.last_activity_date, client?.serverState);
  const isServerSleeping = client?.serverState === 'stopped';
  const history = useHistory();

  useEffect(() => {
    getBillingInfo();
    if (history.location.search.includes('billing-details')) {
      openBillingModal();
    }
    if (history.location.search.includes('subscription-activation')) {
      if (brandConfig.isLeadoku) {
        activateRegularPlan();
      }
      history.push('/');
    }
  }, []);

  const hideTrialBanner = () => {
    sessionStorage.setItem(CONSTANTS.trial_ended_banner_closed, 'true');
    setTrialBannerClosed(true);
  };
  // const onUpgrade = () => IntercomService.trackIntercomEvent('upgrade_request', true, !client.upgrade_request);
  const openBillingModal = () => {
    if (client.data.subscription_account_id && client.data.new_billing_feature) {
      setHeaderModal('subscription');
    }
    history.push('/');
  };

  const onClick = async ({key}: {key: HeaderModalType}) => {
    switch (key) {
      case HeaderModalType.LOGOUT:
        logout();
        break;
      case HeaderModalType.ABOUT_US:
        setHeaderModal(undefined);
        break;
      case HeaderModalType.INVOICES:
      case HeaderModalType.PAYMENT: {
        const result = await getPortalSession();
        chargebeeInstance.setPortalSession(() => Promise.resolve(result));
        chargebeeInstance.createChargebeePortal().openSection({
          sectionType:
            key === HeaderModalType.INVOICES
              ? window.Chargebee.getPortalSections().BILLING_HISTORY
              : window.Chargebee.getPortalSections().PAYMENT_SOURCES,
        });
        break;
      }
      default:
        setHeaderModal(key);
    }
  };

  const getWidth = () => {
    switch (headerModal) {
      case HeaderModalType.MEMBERS:
      case HeaderModalType.ZAPIER:
        return '800px';
      case HeaderModalType.ADMIN_TOOLS:
        return '1000px';
      case HeaderModalType.HUBSPOT:
        return '600px';
      default:
        return '500px';
    }
  };
  const widthProps = {width: getWidth()};

  const modalContentMap = (type: HeaderModalType) => {
    const components: Partial<Record<HeaderModalType, React.ReactNode>> = {
      [HeaderModalType.PASSWORD]: (
        <AccountPassword loading={loadingUpdate} error={errorUpdate} onSubmit={updatePassword} />
      ),
      [HeaderModalType.ADMIN_TOOLS]: <AdminToolsContainer />,
      [HeaderModalType.PROFILE]: settings.isAdmin ? (
        <LazyShowCSMAdmin
          client={client.data}
          changeClientEmail={changeClientEmail}
          changeClientName={changeClientName}
          onSubmit={(values: any) => {
            changeManagerData(values);
            setHeaderModal(undefined);
          }}
        />
      ) : (
        <LazyShowCSM client={client.data} />
      ),
      [HeaderModalType.SETTINGS]: <AccountsSettingsContainer />,
      [HeaderModalType.SUBSCRIPTION]: client.data.new_billing_feature ? (
        <BillingDetailsContainer />
      ) : (
        <LazySubscriptionDetails
          client={client}
          users={users}
          loading={false}
          error={''}
          editable={false}
          onClose={() => setHeaderModal(undefined)}
          onManageSubscription={manageSubscription}
        />
      ),
      [HeaderModalType.MEMBERS]: <MembersDetailsContainer />,
      [HeaderModalType.SALESFORCE]: (
        <LazySalesforce
          oauth2={sfOauth2}
          setAuthorizationCode={setSfAuthorizationCode}
          getAuthorizedCredentials={getSfAuthorizedCredentials}
          clearAuthorizedCredentials={clearSfAuthorizedCredentials}
          waitSfLogoutRequest={waitSfLogoutRequest}
          waitSfLogoutSuccess={waitSfLogoutSuccess}
        />
      ),
      [HeaderModalType.RB2B]: <LazyRb2b client={client.data} loading={rb2bLoading} latestEvent={rb2bLatestEvent} />,
      [HeaderModalType.API]: <LazyApiToken client={client} generate={generateApiToken} getToken={getApiToken} />,
      [HeaderModalType.HUBSPOT]: (
        <Hubspot
          oauth2={hsOauth2}
          setAuthorizationCode={setHsAuthorizationCode}
          getAuthorizedCredentials={getHsAuthorizedCredentials}
          clearAuthorizedCredentials={clearHsAuthorizedCredentials}
          getUsers={getHubspotUsers}
          getSearchProperty={getHubspotSearchProperty}
          configure={configureHubspot}
          syncRetroactively={syncRetroactively}
          getPipelines={getHubspotPipelines}
          tags={clientTags}
        />
      ),
      [HeaderModalType.INSTANTLY]: <InstantlyContainer />,
      [HeaderModalType.ZAPIER]: <LazyZapier app={brandConfig.zapierApp} link={brandConfig.articles.integrateZapier} />,
      [HeaderModalType.NOTIFICATION_SETTINGS]: (
        <LazyNotificationSettings
          getNotificationSettings={getNotificationSettings}
          setNotificationSettings={setNotificationSettings}
          notifications={notifications}
          users={users}
          onClose={() => setHeaderModal(undefined)}
          teamMember={team_member}
        />
      ),
      [HeaderModalType.WEBHOOK]: <LazyWebhooks users={users} onClose={() => setHeaderModal(undefined)} />,
      [HeaderModalType.CUSTOMER_BILLING]: <ClientSubscriptionSettingsContainer />,
    };

    return type ? components[type] : null;
  };

  return (
    <Header className="header__container">
      <LogoContainer />
      <div className="header__info-container" style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-start'}}>
        {client?.data?.running && !isCloudOffline && (
          <span className="header__running">
            <Icon type="sync" spin style={{color: 'var(--color-icon-sync)'}} />
            <span style={{marginRight: '5px'}}>Campaigns are currently running</span>
            <InfoIcon message="Saved changes will take effect within the next 24 hours. For changes to take effect immediately, please disable campaigns before editing and then re-enable campaigns." />
          </span>
        )}
        {!!client?.data?.enabled && isCloudOffline && (settings.isAdmin || client.data?.affiliate?.length > 0) && (
          <Alert className="header__could-offline" message={STRINGS.error.cloud_offline} type="warning" showIcon />
        )}
        {!isCloudOffline && settings.isAdmin && isServerSleeping && (
          <Alert className="header__could-offline" message={STRINGS.error.cloud_sleeping} type="warning" showIcon />
        )}
      </div>
      <AccountSwitcher
        client={client}
        team_member={team_member}
        notifications={notifications}
        toggleNotifications={toggleNotifications}
        onClick={onClick}
        isAdmin={settings.isAdmin}
        onReadNotification={markNotificationAsRead}
        loadMoreNotifications={loadMoreNotifications}
        shouldLoadMoreNotifications={shouldLoadMoreNotifications}
      />

      <Modal
        visible={!!headerModal}
        title={getTitle(headerModal)}
        footer={null}
        destroyOnClose
        onCancel={() => setHeaderModal(undefined)}
        style={{top: 20}}
        {...widthProps}
      >
        {modalContentMap(headerModal)}
      </Modal>

      {!trialBannerClosed && client.data.awaiting_subscription_activation && (
        <TrialFinishedModal
          client={client.data}
          onClose={hideTrialBanner}
          activateRegularPlan={activateRegularPlan}
          info={info}
        />
      )}
      <TemplatesModalContainer />
      <FreeInvitesLimitReachedContainer />
    </Header>
  );
};

const mapStateToProps = (state: any) => ({
  client: state.client,
  users: state.users.list,
  info: state.billing.info,
  team_member: state.client.team_member,
  notifications: state.notifications,
  settings: state.settings,
  loadingUpdate: state.client.loadingUpdate,
  errorUpdate: state.client.errorUpdate,
  sfOauth2: state.sfOauth2,
  hsOauth2: state.hsOauth2,
  chargebeeInstance: state.chargebee.instance,
  unreadNotificationsCount: state.notifications.unreadNotificationsCount,
  shouldLoadMoreNotifications: state.notifications.nextUrl,
  headerModal: state.headerModal.headerModal,
  rb2bLoading: state.rb2b.isLoading,
  rb2bLatestEvent: state.rb2b.event,
  clientTags: [...new Set([...state.connections.tags.map(tag => tag.name), ...CONSTANTS.defaultTags])],
});

const actionCreators = {
  logout: clientActions.logout,
  toggleNotifications: notificationsActions.toggleNotifications,
  manageSubscription: clientActions.manageSubscription,
  changeClientEmail: usersActions.changeClientEmail,
  changeClientName: usersActions.changeClientName,
  changeManagerData: usersActions.changeManagerData,
  updatePassword: clientActions.updatePassword,
  getSfAuthorizedCredentials: sfOauth2Actions.getAuthorizedCredentials,
  clearSfAuthorizedCredentials: sfOauth2Actions.clearAuthorizedCredentials,
  setSfAuthorizationCode: sfOauth2Actions.setAuthorizationCode,
  getHsAuthorizedCredentials: hsOauth2Actions.getAuthorizedCredentials,
  clearHsAuthorizedCredentials: hsOauth2Actions.clearAuthorizedCredentials,
  setHsAuthorizationCode: hsOauth2Actions.setAuthorizationCode,
  getHubspotUsers: hsOauth2Actions.getUsers,
  getHubspotSearchProperty: hsOauth2Actions.getSearchProperty,
  configureHubspot: hsOauth2Actions.configureHubspot,
  syncRetroactively: hsOauth2Actions.syncRetroactively,
  getHubspotPipelines: hsOauth2Actions.getPipelines,
  waitSfLogoutSuccess: sfOauth2Actions.waitSfLogoutSuccess,
  waitSfLogoutRequest: sfOauth2Actions.waitSfLogoutRequest,
  getPortalSession: chargebeeActions.getPortalSession,
  markNotificationAsRead: notificationsActions.markNotificationAsRead,
  loadMoreNotifications: notificationsActions.loadMoreNotifications,
  getNotificationSettings: notificationsActions.getNotificationSettings,
  setNotificationSettings: notificationsActions.setNotificationSettings,
  setProgressDone: adminMaintenanceActions.setProgressDone,
  setHeaderModal: headerModalActions.setHeaderModal,
  activateRegularPlan: chargebeeActions.activateRegularPlan,
  getBillingInfo: billingActions.getBillingInfo,
  generateApiToken: clientActions.generateApiToken,
  getApiToken: clientActions.getApiToken,
};

const ConnectedHeader = connect(mapStateToProps, actionCreators)(DesktopHeader);

export {ConnectedHeader as DesktopHeader};
