import {brandConfig} from '../brandConfig';
import errors from './errors';

export const STRINGS = {
  errors,
  confirmation: {
    confirmation_default_title: 'Confirm your action',
    confirmation_default_message: 'You have unsaved data. Are you sure of closing the modal without saving?',
  },
  warning_add_extra_user: {
    users_limit_reached: 'You have reached the maximum number of users in your plan.',
    confirmation_question: 'You will be charged {splitter}, are you sure?',
  },
  analyzer_url: 'https://meta-stats.com/public/dashboard/9e8aaa2f-7079-478f-a536-8b03ef37f394?analytics_code=',
  metabase_campaigns_stats: 'https://meta-stats.com/public/question/aba8902f-9cc9-458a-8340-2ee02f6bf981',
  conversation_url: 'https://linkedin.com/messaging/thread/',
  sn_conversation_url: 'https://www.linkedin.com/sales/inbox/',
  sn_root_url: 'https://www.linkedin.com/sales/search/people',
  sn_simplified_root_url: 'https://linkedin.com/sales/search/people',
  advanced_search_help_url: 'https://www.linkedin.com/help/sales-navigator/answer/50230',
  company_url: 'https://www.linkedin.com/company/',
  li_search_url: 'https://www.linkedin.com/search/results/people',
  error_server_response: 'Server error, please try again later',
  error_second_followup_undefined:
    'The second followup message is undefined, please make sure to fill it up before running the campaign.',
  error_too_many_profile_views_today:
    ' has viewed too many profiles today. In order to avoid reaching LinkedIn limits, it will restart sending invites tomorrow.',
  error_total_max_invites_user:
    ' has reached the total maximum limit of LinkedIn invites. Please delete pending invites or wait for invites to be accepted.',
  error_connections_not_loading_on_scroll: 'connections not loading on scroll',
  error_profile_id_not_found: 'profile id was not found and receiver cannot be identified',
  error_profile_id_not_found_from_connections:
    'profile id was not found and receiver cannot be identified on connections page',
  error_profile_id_encoded_not_found_from_profile: 'profile id encoded was not found on profile page',
  error_profile_id_not_found_from_profile: 'profile id was not found and receiver cannot be identified on profile page',
  error_saving_connections: `There were some errors while saving connections, please contact ${brandConfig.contact}`,
  error_receiver_not_found_on_inbox_search_results:
    'Followup cannot be sent because receiver was not found in search results',
  error_no_available_credits: `There are no more credits available, please contact ${brandConfig.contact} to add more.`,
  error_message_is_too_long:
    "Invite could not be sent to {receiver.name} because it is longer than 300 characters after replacing the variables. Please reduce text's length. ({campaign.name})",
  error_inmail_message_is_too_long:
    "InMail message could not be sent to {receiver.name} because it is longer than 1900 characters after replacing the variables. Please reduce text's length. ({campaign.name})",
  error_max_followup_length: 'The maximum message length is 8,000 chars, please make it shorter.',
  error_max_exclude_companies_length:
    'The maximum length for exclude campaigns is 70000 chars, please make it shorter.',
  error_no_network_connection:
    'There are some connectivity issues, please check your internet connection. The campaigns will restart automatically when connection is restored.',
  error_server_not_reachable: "Couldn't connect to the server. Will retry in 60 secs.",
  error_sn_inbox_communication_disabled: 'Followup cannot be sent because receiver has communication disabled',
  error_big_url_potential:
    'Campaign skipped because the Search URL has a potential of more than 50,000 results. Please split the search in multiple campaigns with smaller potential. For best results, potential should be between 300 and 2,500.',
  error_zero_url_potential: 'Campaign skipped because the Search URL has 0 results. Please, update the URL.',
  info_start_campaign_time:
    "We suggest to setup this time at the beginning of the morning (like 8am), based on the receivers' location and timezone settings. Take in consideration that campaigns will take several hours to complete. \n For example: You're based in New York and your timezone is setup to Eastern Time. If you are targeting people in California (Pacific Time), then set the Campaigns Start time at 11am, so that they will run from 8am PT.",
  info_request_trial: `Please note, this is a trial version of ${brandConfig.name}. If you would like to activate the full subscription, please contact ${brandConfig.contact}`,
  info_demo: `This is just a demo version, please upgrade to get started or schedule a live demo by sending an email to ${brandConfig.contact} (or by clicking the chat icon in the bottom right)`,
  info_demo_leadoku: `This is just a demo version, please subscribe to get started by clicking the upgrade button`,
  info_subscription_onhold: `Your subscription is on hold. If you would like to re-activate it please contact ${brandConfig.contact}`,
  info_user_pending_invites_lock: `We do not recommend to disable withdrawing invites. Please contact ${brandConfig.contact} for questions.`,
  tooltip_archive_campaign:
    "By clicking archive, the campaign will disappear from your admin panel. To recover it you'll have to contact support and provide the name of the campaign. You'll still be able to view and export your connections and prospects data.",
  tooltip_delete_campaign:
    'By clicking delete, the campaign will disappear from your admin panel. You will not be able to recover it.',
  tooltip_archive_user:
    "By clicking archive, the user will disappear from your admin panel. To recover it you'll have to create user with same email.",
  tooltip_replace_user: 'By clicking replace, the new user will be created without reducing add-on.',
  message_unlock_feature: `Click for more info on how to unlock this feature or contact ${brandConfig.contact}`,
  message_unlock_prospect: 'Select and click on Enhance data to discover this information',
  not_support_oldUI:
    'Skipping {user.email} because it has an old version of LinkedIn UI not supported ({campaign.name})',
  info_user_email_notifications:
    'By default, you receive all email notifications to your client email account. By enabling this email notifications, you will receive also email notifications about user related issues directly to the LinkedIn email of the user (you might also specify another email in the email field when enabling)',
  not_support_linkedin_url_filters: 'This must be the LinkedIn URL, see tooltip for instructions',
  not_support_saved_search_filters: `We do not support Saved Search URL, please ${
    brandConfig.isAffiliate
      ? 'convert it.'
      : 'follow this guide to convert it. https://help.growth-x.com/qanda/do-you-support-saved-search-urls'
  }`,
  not_support_saved_lists_filters:
    'We do not support Saved Lists Search URLs (e.g.https://www.linkedin.com/sales/lists/people/123123123), please modify it.',
  not_support_general_search_filters: 'We only support Sales Navigator and LinkedIn Search URLs.',
  not_support_too_long_urls: `The Search URL is too long and LinkedIn will have problems loading the page. We strongly recommend to make them shorter (below {url_max_length} characters) or convert them into Saved Searches. In order to create a Saved Search, click on Save Search and then copy the Saved Search URL from the list in Saved Searches (e.g. "https://www.linkedin.com/sales/search/people?savedSearchId=…"). Remember that a Saved Search is user specific, make sure to create a specific one for each user (or check the Share Search functionality on LinkedIn). For more info contact ${brandConfig.contact}.`,
  not_support_multi_links:
    'The Search URL consists of several links to LinkedIn. Please, make sure that you have only a one link in the Search URL.',
  not_support_saved_leads_filters: `We don’t support Saved Leads URL as Search URL${
    brandConfig.intercomFeature
      ? `, please follow this link for alternatives. ${brandConfig.articles.convertSavedLeads}`
      : `.`
  }`,
  error_linkedin_post_link: `Url should represent lint to linkedin post.`,
  error_code_mulitiple_receivers_inbox: 'mulitiple_receivers_inbox_search_field',
  campaign_finished_info: `Campaigns have finished running for today, they will automatically restart on {tomorrowDate}. If you leave the campaigns enabled, ${brandConfig.name} will automatically run every 24 hours. If you'd like, you can also start campaigns at any time by clicking Disable and Re-Enable.`,
  sn_message_limit: 'Maximum amount of messages (100) sent today ({campaign_name})',
  skip: 'skip',
  error_login_invalid: 'Wrong email or password. Please try again.',
  error_cannot_connect_chrome: 'Could not connect to chrome',
  error_cannot_init_tab: 'Could not init tab',
  error_followup_with_error: 'Follow up sent with error.',
  error_conversation_parse_failed: 'Could not parse conversation.',
  error_cannot_get_profile_info_request: 'Network request for profile info not found',
  task_invites: 'invites',
  task_save_connections: 'save connections',
  task_send_followups: 'send followups',
  task_clear_pending_invites: 'clear pending invites',
  cancel_subscription_not_exist_error: 'subscription_does_not_exist',
  cancel_subscription_non_renewing_error: 'non_renewing_subscription',
  new_campaign_info_message: 'Numbers will appear within the next 24 hours',
  free_trial_signature: 'Sent via https://leadoku.io',
  info_user_sales_navigator: `Do you have multiple Sales Navigator Subscription for this user? If no, then disregard. If yes, please ${
    brandConfig.isAffiliate
      ? 'choose the right one.'
      : `read this article to choose the right one ${brandConfig.articles.multipleSalesNavigator}.`
  } `,
  info_connections_rate_warning:
    'This campaign has a low connection rate (below 15%). We think it could do better, please consult with your customer success manager on how to improve it.',
  placeholder: {
    filter_url:
      'EX: https://www.linkedin.com/sales/search/people?query=(spellCorrectionEnabled%3Atrue%2CrecentSearchParam%3A(id%3A3469944737%2CdoLogHistory%3Atrue)%2Cfilters%3AList((type%3ACURRENT_COMPANY%2Cvalues%3AList((id%3Aurn%253Ali%253Aorganization%253A1441%2Ctext%3AGoogle%2CselectionType%3AINCLUDED%2Cparent%3A(id%3A0))))%2C(type%3AFUNCTION%2Cvalues%3AList((id%3A8%2Ctext%3AEngineering%2CselectionType%3AINCLUDED)))%2C(type%3ASENIORITY_LEVEL%2Cvalues%3AList((id%3A120%2Ctext%3ASenior%2CselectionType%3AINCLUDED)))%2C(type%3AREGION%2Cvalues%3AList((id%3A102221843%2Ctext%3ANorth%2520America%2CselectionType%3AINCLUDED))))%2Ckeywords%3Agoogle)&sessionId=zUKN96enTQmP5kM%2F5N8VCw%3D%3D',
    li_filter_url:
      'EX: https://www.linkedin.com/search/results/people/?keywords=senior%20google&network=%5B%22S%22%2C%22O%22%5D&origin=FACETED_SEARCH&sid=M~4',
    invite_message:
      'Hi {receiver.first_name}, my name is {user.first_name}.\nMy company does... I need your help with... I would love to connect and tell you more about it.\nRegards, {user.first_name}',
    invite_message_disabled:
      "I'd like to add you to my professional network. (It is not possible to customize the invite message when using this campaign type)",
    campaign_message: "Hi {receiver.first_name}, I'd like to connect.\n{user.first_name}",
    followup_message:
      'Thanks for connecting {receiver.first_name}. As mentioned my company does... \nWould you find this helpful? Feel free to schedule a demo by visiting this link .... \nRegards, {user.first_name}',
    second_followup_message: 'Sorry to bother you again, did you get my last message?\n{user.first_name}',
    third_followup_message: 'Sorry to bother you again, did you get my last message?\n{user.first_name}',
    exclude_companies: 'Apple,Tesla,Uber,LinkedIn',
    blacklist_companies:
      'The companies names should be separated by comma without white space, "double quote" and ending comma, here it is an example: Google,Amazon,Facebook,...',
    acquisition_via_import_message: '',
  },
  tooltip: {
    invite_message:
      "This message will be used as template for sending invites to connect. You might personalized the message by using {receiver.first_name} for the receiver first name, {receiver.last_name} for the receiver last name, and {user.first_name} for the first name of the user that is sending the message. We strongly suggest using a relatively personal message in the invite. It's also good to include some info regarding the reason you're reaching out.  This will give the receiver a hint about the reason you're connecting and not only increase the chance of accepting you invite, but will also prepare the ground for the followup message.",
    message_request_message:
      "This message will be used as template for sending message requests to start a conversation with members of the same group. You might personalized the message by using {receiver.first_name} for the receiver first name, {receiver.last_name} for the receiver last name, and {user.first_name} for the first name of the user that is sending the message. We strongly suggest using a relatively personal message in the message request. It's also good to include some info regarding the reason you're reaching out and the group you both belong to. This will give the receiver a hint about the reason you're contacting and not only increase the chance of accepting your request, but will also prepare the ground for the followup message.",
    inmail_message:
      "This message will be used as template for sending inMails. You might personalized the inMail message by using {receiver.first_name} for the receiver first name, {receiver.last_name} for the receiver last name, and {user.first_name} for the first name of the user that is sending the message. We strongly suggest using a relatively personal message in the inMail. It's also good to include some info regarding the reason you're reaching out. This will give the receiver a hint about the reason you're contacting and not only increase the chance of accepting your inMail, but will also prepare the ground for the followup message.",
    filter_url:
      'Go to https://www.linkedin.com, search LinkedIn for relevant keywords, add filters if needed, then copy & paste the URL from the search results page.',
    csv: `Please ${
      brandConfig.isAffiliate
        ? ' and upload a CSV file.'
        : `follow this guide to create the CSV file. (${brandConfig.articles.acquisitionCsv})`
    }`,
    via_tag: 'Messages will be sent to receivers with the selected tags (e.g. hot-leads, closed-deal, positive,…)',
    via_campaign: 'Messages will be sent to receivers from the selected campaigns.',
    via_action:
      'Messages will be sent to receivers who performed one of the selected actions. (e.g. clicked will include all the receivers that clicked on the links sent)',
    nurturing_type_url: `URL: Copy and paste a Sales Navigator URL from the search results page.`,
    nurturing_type_csv: `CSV: Create and upload a CSV file.`,
    nurturing_type_tag: `Tag: Target receivers with any selected tag.`,
    nurturing_type_action: `Action: Target receivers who clicked a link from conversation messages in the GX inbox.`,
    campaign_message:
      'This message will be used to send messages to first connections. You might personalized the message by using {receiver.first_name} for the receiver first name, {receiver.last_name} for the receiver last name, and {user.first_name} for the first name of the user that is sending the message.',
    followup_message:
      "This message will be used as template for sending a followup message to people that accept your invite to connect. You might personalized the message by using {receiver.first_name} for the receiver first name, {receiver.last_name} for the receiver last name, and {user.first_name} for the first name of the user that is sending the message.\n For best results, we recommend including your contact info (email/phone or simply say 'reply to me here on Linkedin') in the followup message, and encourage people to talk with you.  This way receivers don't think of it as an ad but more of a conversation with you.  In addition - it will also help you track the success rate of campaigns and our product, instead of just sending the traffic to your website.",
    inmail_followup_message:
      "This message will be used as template for sending a followup message to people that accept your inMail. You might personalized the message by using {receiver.first_name) for the receiver first name, {receiver.last_name} for the receivel last name, and (user.first _name} for the first name of the user that is sending the message. For best results, we recommend including your contact info (email/phone or simply say 'reply to me here on Linkedin') in the followup message, and encourage people to talk with you. This way receivers don't think of it as an ad but more of a conversation. In addition - it will also help you track the success rate of campaigns and our product, instead of just sending traffic to your website.",
    second_followup_message:
      'This message will be used as template for sending a second followup message in case of no response detected.',
    blacklist_companies: `Choose the companies name that you don't want to contact. This will be applied in all account, in other words, it will work for all campaigns for all users that you have. ${
      !brandConfig.isAffiliate ? `More info here ${brandConfig.articles.excludeSpecificCompanies}.` : ''
    }`,
    third_followup_message:
      'This message will be used as template for sending a third followup message in case of no response detected.',
    exclude_companies: `List the companies that you don't want to contact. Make sure to list them comma separated and eliminate any "double quote", ending comma, or white space between commas. ${
      !brandConfig.isAffiliate ? `More info here ${brandConfig.articles.excludeSpecificCompanies}.` : ''
    }`,
    exclude_responders:
      'By checking this, the followups will not be sent to people that sent you a message after accepting your invitation',
    exclude_user: 'Allow other users to invite receivers that were already invited by this user',
    exclude_responders_nurturing:
      'By checking this, the followups will not be sent to people that sent you a message after nurturing message.',
    send_invite_with_enhanced_email: `There are receivers that don't allow to be invited unless you have their email. By checking this, ${brandConfig.name} will discover their email, which will be used to send them the invite. Each email match costs 1 credit, make sure to contact ${brandConfig.contact} to recharge your data credits.`,
    multiple_invites_active:
      'By checking this, you allow multiple users to invite same receiver in case of no acceptance after 30 days',
    twitter_follow: 'By checking this, users will automatically follow connections on Twitter.',
    warning_potential: 'For best results, potential should be 300 - 2,500 results',
    wrong_potential:
      'The potential of this campaign is not accurate. It will be scanned in the following days. Please disregard for now.',
    danger_potential:
      'The Search Url defined is way too broad! Please add filters. For best results, potential should be 300 - 2,500 results',
    nurturing_feature_multi_users: `With this feature enabled, you’ll be able to create campaigns that target only first level connections. These campaigns are great for retargeting or nurturing. It also allows you to contact connections that were made before using ${brandConfig.name}`,
    multiple_invites:
      'With this feature enabled, your acquisition campaigns will be able to send multiple invites to the same receiver from different users, in case of no acceptance after 30 days',
    twitter_feature:
      'With this feature enabled, you’ll be able to automatically (or manually) follow your new connections on Twitter',
    csv_campaigns:
      'With this feature enabled, you’ll be able to target specific people with your acquisition and nurturing campaigns by providing a CSV file with a list of LinkedIn URLs',
    cloud_service: `With this feature enabled, your campaigns will be running on a dedicated server managed by ${brandConfig.name}. This way all you need to do is handling your campaigns from the website ${brandConfig.contact}`,
    only_connections_by_gx: `By checking this, messages will be sent only to receivers generated by ${brandConfig.name}`,
    twitter_connect: `By clicking Connect Twitter you will be redirected to page for authorizing ${brandConfig.name} Application`,
    user_validation_needed: `{user.email} is required validation. Open user details, click on three dots, and click on validate. If this doesn't help contact ${brandConfig.contact}.`,
    include_has_open_acquisition_sequence:
      "By enabling this feature, this campaign will skip sending messages to receivers that have an opened sequence in another campaign. For example, if this campaign (let's call it Campaign A) is targeting a receiver that was contacted by another acquisition campaign (let's call it Campaign B) and this receiver is supposed to receive another message like a second followup or something else from Campaign B, then Campaign A will not send any message until there are no more messages left to send from Campaign B. Keep in consideration that there are several rules that will close a sequence, for example if a receiver responds then a campaign sequence is closed.",
    campaign_user_not_selected: 'Please assign user or numbers will not appear',
    campaigns_not_enabled: 'Please enable campaigns or numbers will not appear',
    backup_messages:
      'The Sales Navigator subscription for this user expired and LinkedIn deleted all the conversations. Luckily, we stored a backup and showing it here. Be aware that this backups might not be 100% accurate, so make sure to always pay for your Sales Navigator subscription on time to avoid issues.',
    proxy_feature: 'Users will access linkedin using proxy ip address',
    followups_disabled:
      'You cannot enable a followup that has empty message, please open the campaign details and fill the content first.',
    create_team_member: `By checking this, we will create and send credentials to access this ${brandConfig.name} account to the email of this user. This way you will not have to share other people your credentials. We recommend to keep this feature on.`,
    instantly_info_general: `All receivers that are targeted in this campaign will be added automatically to an email campaign. Connect your Instantly.ai account and choose the Instantly email campaign to continue the sequence by email.`,
    instantly_info_gx:
      'Integrate with Instantly to continue your nurturing campaigns via email. Learn how to set up the integration and use it effectively in our Instantly Integration Guide https://help.growth-x.com/en/articles/9649687-growth-x-integration-with-instantly.',
    instantly_acquisition_tags:
      'All connections are sent if not tag is selected. If you wish to filter by a specific tag, please choose from the selection. It is recommended to keep this selection empty or to choose “positive_ai_defined” tag.',
  },
  url: {
    terms: `https://growth-x.com/terms`,
    privacy: `https://growth-x.com/privacy`,
  },
  warning: {
    invite_message_links:
      'We strongly recommend not using links or emails in the invite as it may increase the chances of an account restriction.',
    inmail_message_links: 'We strongly recommend not using links or emails in the first point of contact.',
    user_not_selected: 'The user is undefined, please make sure to set it up before running the campaign.',
    login_incognito_cookie:
      "There was a login error. Please make sure that cookies are enabled and you're not using Incognito Mode.",
    tags_positive_ai_defined_warning:
      'If you want to tag this conversation as not-positive and reflects on the stats, simply add the tag not-positive.',
    confirm_members_campaigns_disabling:
      'Are you sure that you want to disable all campaigns of all the users of the account? If you want to disable only your campaigns, simply disable the actions (I,F,S)',
    confirm_members_campaigns_enabling:
      'Are you sure that you want to enable all campaigns of all the users of the account?',
    too_many_active_campaigns:
      'You have already {campaigns.length} campaigns with invites enabled for this user, we split the total invites per day per user across all campaigns and when more than 5 are enabled, the last campaigns will start when the first ones are finished',
    too_many_active_campaigns_restricted:
      'You cannot run Invites for more than 10 campaigns for the same user at the same time. Please disable Invites from other campaigns',
    place_on_hold:
      'Please note that all data associated with your account will be permanently deleted after 30 days from the date of cancellation. To prevent this, please place your account on hold to keep the data for ${price} per month.',
    on_hold_cancellation_title: 'Are you sure that you want to cancel your on-hold subscription?',
    on_hold_cancellation_text:
      'Please note that all data associated with your account will be permanently deleted after 30 days from the date of cancellation.',
    leadoku_cancel_subscription:
      'Per our terms and conditions, you may terminate your subscription by providing us with 30 days prior notice. Since your monthly renewal date is less than 30 days away, this means that your account will get canceled 30 days after the coming renewal date.',
    mobile_warning:
      'We recommend using the desktop version of our application for the best experience. While our mobile app is fully functional, the desktop version offers enhanced features and usability that are optimized for larger screens. Thank you!',
    basic_linkedin_user_warning:
      'We highly recommend using a Sales Navigator account. It offers advanced lead search, advanced tools designed to optimize your prospecting, sales, and networking efforts.',
    basic_linkedin_campaign_warning:
      'We highly recommend using a Sales Navigator search. It offers advanced lead search, advanced tools designed to optimize your prospecting, sales, and networking efforts.',
  },
  info: {
    campaigns_review: 'Campaigns are in review...',
    cancel_subscription_already_canceled: 'Your subscription is already canceled.',
    cancel_subscription_non_renewing_subscription: 'Your subscription has been cancelled and will not be auto-renewed.',
    cancel_subscription_canceled: 'Subscription Canceled.',
    cancel_subscription_will_be_canceled: 'The subscription will be cancelled at the end of your billing cycle',
    reactivate_subscription_activating: "Your subscription is activating, you'll receive an invoice by email",
    reactivate_subscription_saving: 'Your subscription is updating, check your email for details',
    campaigns_enabled:
      'Your campaigns are enabled and will start generating leads soon. You can sit, relax, and come back here later.',
    signature_invite_length:
      'The max length for the invite message is 290 characters because each message will contain the signature. Upgrade to increase the max length to 300',
  },
  error: {
    cloud_offline: `Your cloud solution is offline, please contact ${brandConfig.contact} or use the Workspaces client to troubleshoot.`,
    cloud_sleeping: `Your cloud solution is inactive, you can resume it in admin tools.`,
    reactivate_subscription_error: `There were some problems with activation your subscription, please contact ${brandConfig.contact}`,
  },
  tags: {
    positive_ai_defined: 'positive-ai-defined',
    closed_deal: 'closed-deal',
  },
  DEFAULT_API_ERROR_MESSAGE: 'There is a problem on our side. Please, try later or reach out the support team.',
};
