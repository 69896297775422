import {
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  GET_CURRENT_COUNTRY_REQUEST,
  GET_CURRENT_COUNTRY_FAILURE,
  GET_CURRENT_COUNTRY_SUCCESS,
  UPDATE_SUGGESTED_PROXY_MODAL,
} from './proxyActions';

const initialState: any = {
  locationsInfo: {
    isLoading: false,
    currentLocation: null,
    countryList: [],
  },
  suggestedProxy: {
    showSuggestedProxy: false,
    suggestedProxies: [],
  },
};

export const proxyReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CURRENT_COUNTRY_REQUEST:
    case GET_COUNTRY_LIST_REQUEST: {
      return {...state, locationsInfo: {...state.locationsInfo, isLoading: true}};
    }
    case GET_COUNTRY_LIST_SUCCESS: {
      return {...state, locationsInfo: {...state.locationsInfo, isLoading: false, countryList: action.data}};
    }
    case GET_COUNTRY_LIST_FAILURE: {
      return {...state, locationsInfo: {...state.locationsInfo, isLoading: false, countryList: []}};
    }
    case GET_CURRENT_COUNTRY_FAILURE: {
      return {...state, locationsInfo: {...state.locationsInfo, isLoading: false, currentLocation: null}};
    }
    case GET_CURRENT_COUNTRY_SUCCESS: {
      return {...state, locationsInfo: {...state.locationsInfo, isLoading: false, currentLocation: action.data.iso2}};
    }
    case UPDATE_SUGGESTED_PROXY_MODAL: {
      return {...state, suggestedProxy: {...state.suggestedProxy, ...action.suggestedProxy}};
    }
    default:
      return state;
  }
};
