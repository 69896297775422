import {connect} from 'react-redux';

import {FreeInvitesLimitReached} from '../../components/communications/';
import {chargebeeActions} from '../../redux/chargebee';

function mapState(state: any) {
  return {
    client: state.client.data,
    users: state.users.list,
  };
}

const actionCreators = {
  activateRegularPlanGx: chargebeeActions.activateRegularPlanGx,
};

export const FreeInvitesLimitReachedContainer = connect(mapState, actionCreators)(FreeInvitesLimitReached);
