import {connect} from 'react-redux';

import {User} from '@growth-x/types';

import {billingActions} from '../../redux/billing';
import {clientActions} from '../../redux/client';
import {clientMaintenanceActions} from '../../redux/clientMaintenance';
import {headerModalActions} from '../../redux/headerModal';
import {proxyActions} from '../../redux/proxy';
import {settingsActions} from '../../redux/settings';
import {usersActions} from '../../redux/users';
import {getSettings} from '../campaigns/utils';

const getActiveUsers = (user: User) => !user.archived;

export const prepareUsers = (users: User[], isAdmin: boolean) => {
  const result = isAdmin ? users : users.filter(getActiveUsers);
  if (isAdmin) {
    const archivedUsers = users.filter(user => user.archived);
    const activeUsers = users.filter(user => !user.archived);
    return [...activeUsers, ...archivedUsers];
  }
  return result;
};

function mapState(state: any) {
  const users = prepareUsers(state.users.list, state.settings.isAdmin);

  return {
    users,
    campaigns: state.campaigns.list,
    usersLoading: state.users.usersLoading,
    client: state.client.data,
    serverState: state.client.serverState,
    team_member: state.client.team_member,
    userDetailsLoading: state.users.userDetailsLoading,
    userDetailsError: state.users.userDetailsError,
    statsError: state.users.statsError,
    statsLoading: state.users.statsLoading,
    stats: state.users.stats,
    userValidation: state.users.userValidation,
    userTask: state.users.userTask,
    settings: getSettings(state),
    showUserInfoModal: state.showUserInfoModal,
    showUserLimitReachedModal: state.showUserLimitReachedModal,
    userInfoModalTitle: state.userInfoModalTitle,
    userInfoModalMessage: state.userInfoModalMessage,
    unreadInboxCount: state.inbox.unreadCount,
    unreadCountByUser: state.inbox.unreadCountByUser,
    isAdmin: state.settings.isAdmin,
    billingInfo: state.billing.info,
    members: state.members.list,
    userDetails: state.users.userDetails,
    affiliatedClients: state.clientMaintenance.affiliatedClients,
    suggestedProxy: state.proxy.suggestedProxy,
  };
}

const actionCreators = {
  getUsers: usersActions.getUsers,
  createUser: usersActions.createUser,
  updateUser: usersActions.updateUser,
  replaceUser: usersActions.replaceUser,
  getUsersStats: usersActions.getUsersStats,
  setSettings: settingsActions.setSettings,
  archiveUser: usersActions.archiveUser,
  unarchiveUser: usersActions.unarchiveUser,
  archiveUserAdminMode: usersActions.archiveUserAdminMode,
  validateUser: usersActions.validateUser,
  openUser: usersActions.openUser,
  validateUserUpdateTask: usersActions.validateUserUpdateTask,
  clearUserError: usersActions.clearUserError,
  clearUserWarning: usersActions.clearUserWarning,
  clearCookies: usersActions.clearCookies,
  updateLoginErrorDate: usersActions.updateLoginErrorDate,
  updateUserTask: usersActions.updateUserTask,
  validatePassword: usersActions.validatePassword,
  getBillingInfo: billingActions.getBillingInfo,
  toggleNoVNCCustomAddressBar: clientActions.toggleNoVNCCustomAddressBar,
  updateUserDetails: usersActions.updateUserDetails,
  getAffiliatedClients: clientMaintenanceActions.getAffiliatedClients,
  moveUser: clientMaintenanceActions.moveUser,
  scheduleArchive: usersActions.scheduleArchive,
  cancelScheduledArchive: usersActions.cancelScheduledArchive,
  setHeaderModal: headerModalActions.setHeaderModal,
  addProxy: usersActions.addProxy,
  updateSuggestedProxy: proxyActions.updateSuggestedProxy,
};

export default connect(mapState, actionCreators);
