import {Dropdown, Menu, Icon} from 'antd';
import React from 'react';

function filterOutLoggedMember(members, client, team_member) {
  const acceptedMembers = members.filter(m => m.status === 'ACCEPTED');
  if (team_member) {
    return [client, ...acceptedMembers?.filter(m => m.id !== team_member.id)];
  } else {
    return acceptedMembers || [];
  }
}

export const SelectNoteMember = ({
  children,
  client,
  team_member,
  members,
  isVisible,
  onVisibleChange,
  csmList,
  onSelect,
  onAddNewMember,
}) => {
  let menu;
  const filteredMembers = filterOutLoggedMember(members, client, team_member);
  const csmMember = client.csm ? csmList.find(csm => csm.name === client.csm) : null;
  if (filteredMembers.length) {
    menu = (
      <Menu>
        {filterOutLoggedMember(members, client, team_member).map(member => (
          <Menu.Item
            key={member.id}
            onClick={() => onSelect(member, member.client ? 'team_member' : 'client')}
          >{`${member.name} (${member.email})`}</Menu.Item>
        ))}
        {csmMember && (
          <Menu.Item
            key={csmMember.id}
            onClick={() => onSelect(csmMember, 'csm')}
          >{`${csmMember.full_name} (${csmMember.email})`}</Menu.Item>
        )}
      </Menu>
    );
  } else {
    menu = (
      <Menu>
        <Menu.Item key={'add-member'} onClick={onAddNewMember}>
          <Icon type="team" />
          Add Team Member
        </Menu.Item>
        {csmMember && (
          <Menu.Item
            key={csmMember.id}
            onClick={() => onSelect(csmMember, 'csm')}
          >{`${csmMember.full_name} (${csmMember.email})`}</Menu.Item>
        )}
      </Menu>
    );
  }
  return (
    <Dropdown
      placement="topCenter"
      trigger={['click']}
      onVisibleChange={visible => {
        if (!visible) {
          onVisibleChange(false);
        }
      }}
      overlay={menu}
      visible={isVisible}
    >
      {children}
    </Dropdown>
  );
};
