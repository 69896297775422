import {connect} from 'react-redux';

import {ClientSubscriptionSettings} from '../../components/header/ClientSubscriptionSettings';
import {headerModalActions} from '../../redux/headerModal';

function mapState(state: any) {
  return {
    client: state.client.data,
    isAdmin: state.settings.isAdmin,
  };
}

const actionCreators = {
  close: () => headerModalActions.setHeaderModal(undefined),
};

export const ClientSubscriptionSettingsContainer = connect(mapState, actionCreators)(ClientSubscriptionSettings);
