import {Form, Input} from 'antd';
import React from 'react';

import {Plans} from '@growth-x/types';

const Label = ({plan}) => (
  <span style={{marginLeft: 20}}>
    <span style={{textTransform: 'capitalize'}}>{plan === Plans.STARTER ? Plans.ACCELERATE : plan}</span> (per user)
  </span>
);

export const PlanPriceField = ({plan, onChange, errors}) => (
  <Form.Item
    validateStatus={errors ? 'error' : ''}
    help={errors?.price}
    key={plan.plan_id}
    label={<Label plan={plan.plan_id} />}
  >
    <Input type="number" value={plan.price} placeholder="Price" min={0} onChange={onChange} />
  </Form.Item>
);
