import {Form, Row, Button, Icon, Upload, Menu, Dropdown, Popover, Tooltip, notification} from 'antd';
import {UploadFile} from 'antd/lib/upload/interface';
import classnames from 'classnames';
import {ErrorMessage} from 'formik';
import React, {useCallback, useEffect, useRef, useState} from 'react';

import {findIconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Template} from '@growth-x/types';
import {ConfirmationModal, TemplatesMenu, IconWrapper} from '@growth-x/ui';

import {ReactComponent as MagicIcon} from '../../assets/images/magic-icon.svg';
import {CONSTANTS, STRINGS} from '../../utils';
import {formatAttachmentName} from '../../utils/functions/formatAttachmentName';
import {AdminFeatureIcon} from '../icons';

import './messageTextArea.css';

type InsertButton = {
  value: string;
  label: string;
};

interface MessageTextAreaProps {
  label?: string | React.ReactNode;
  value: string;
  setValue: (value: string) => void;
  insertTexts: InsertButton[];
  placeholder: string;
  errors?: any;
  touched?: any;
  name: string;
  additionalData?: string | React.ReactNode;
  showAttachment?: boolean;
  attachment?: any;
  setAttachment?: (value: any) => void;
  disabled?: boolean;
  clientAttachments?: any;
  deleteClientAttachment?: (attachment_id: string | number) => void;
  display?: boolean;
  dataTestId?: string;
  isAdmin?: boolean;
  templates?: any;
  defaultTemplate?: any;
  className?: string;
  showWhitelabelSignature?: boolean;
  onUpgradePlan?: () => Promise<any>;
  createTemplate?: (message?: string) => void;
  onSuggestMessageTemplate?: (params: any) => void;
  tooltip?: string;
}

export const MessageTextArea = ({
  label,
  value,
  setValue,
  insertTexts,
  placeholder,
  errors,
  touched,
  name,
  additionalData,
  showAttachment,
  attachment,
  setAttachment,
  disabled,
  clientAttachments,
  deleteClientAttachment,
  display,
  dataTestId,
  isAdmin,
  templates,
  defaultTemplate,
  className,
  showWhitelabelSignature,
  createTemplate,
  onUpgradePlan,
  onSuggestMessageTemplate,
  tooltip,
}: MessageTextAreaProps) => {
  const elementRef = useRef(null);
  const backdropRef = useRef(null);
  const highlightsRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [templatesDropdownVisible, setTemplatesDropdownVisible] = useState(false);
  const [showUpgradeConfirmation, setShowUpgradeConfirmation] = useState(false);

  const applyHighlights = useCallback(
    (text = '') => {
      let result = text;
      result = result.replace(/\n$/g, '\n\n');

      const allVariables = [
        ...insertTexts,
        {
          value: '{receiver.company_name}',
        },
        // {
        //   value: '{receiver.most_recent_past_company}',
        // },
        // {
        //   value: '{receiver.company_industry}',
        // },
        // {
        //   value: '{receiver.company_region}',
        // },
        // {
        //   value: '{receiver.receiver_region}',
        // },
      ];

      allVariables.forEach((replaceKey: any) => {
        if (result.includes(replaceKey.key) && replaceKey.editable) {
          const splittedMessage = splitByReplaceKey(result, replaceKey.key);
          const highlightedMessage = `${splittedMessage.shift()}<mark>${replaceKey.key}`;

          result = splittedMessage.reduce((completeMessage, chunkMessage, currIndex) => {
            const firstCurlyBraceIndex = chunkMessage.indexOf('}') + 1;
            const companyNameFallback = chunkMessage.substring(0, firstCurlyBraceIndex);
            const afterCompanyNameFallback = chunkMessage.substring(firstCurlyBraceIndex);

            const highlightedChunk = `${completeMessage}${companyNameFallback}</mark>${afterCompanyNameFallback}`;

            const isLastElement = currIndex === splittedMessage.length - 1;

            if (isLastElement) {
              return highlightedChunk;
            }

            return `${highlightedChunk}<mark>${replaceKey.key}`;
          }, highlightedMessage);
        } else {
          result = result.split(replaceKey.value).join(`<mark>${replaceKey.value}</mark>`);
        }
      });

      return result;
    },
    [insertTexts]
  );

  const handleInput = useCallback(() => {
    const text = elementRef.current.textContent;
    const highlightedText = applyHighlights(text);
    highlightsRef.current.innerHTML = highlightedText;
  }, [applyHighlights]);

  useEffect(() => {
    handleInput();
    elementRef.current.onscroll = handleScroll;
  }, [elementRef, handleInput]);

  useEffect(() => {
    handleInput();
  }, [value, handleInput]);

  const handleScroll = () => {
    const scrollTop = elementRef.current.scrollTop;
    backdropRef.current.scrollTop = scrollTop;
  };

  const splitByReplaceKey = (text, key) => {
    return text.split(key);
  };
  const insertText = (item: any) => {
    const companyFallback = CONSTANTS.COMPANY_VARIABLES_FALLBACK[item.fallback];
    if (companyFallback) {
      const desc = (
        <div>
          you chose the variable <strong>{item.value}</strong>. This variable <strong>receiver.{item.fallback}</strong>{' '}
          is not always present for all receivers. When it is not found, such value will be replaced with the editable
          default text provided after the OR (in this example <strong>{companyFallback}</strong>)
        </div>
      );

      notification.warning({
        message: desc,
        duration: 10,
      });
    }
    const {selectionStart} = elementRef.current;
    value = value || '';
    setValue(`${value.substring(0, selectionStart)}${item.value}${value.substring(selectionStart)}`);
  };

  const handleFileClick = async (file: any, setAttachment: any) => {
    setAttachment(file);
  };

  const isAttachmentString = typeof attachment === 'string';
  let attachmentName = isAttachmentString
    ? attachment
    : attachment
    ? attachment.attachment_name || attachment.name
    : null;
  attachmentName = formatAttachmentName(attachmentName);
  const attachmentElement = showAttachment ? (
    attachmentName ? (
      <Button onClick={() => (setAttachment ? setAttachment('') : null)}>
        <Icon type="file" />
        {attachmentName}
        <Icon type="close-circle" />
      </Button>
    ) : (
      <Popover
        placement="bottomRight"
        title={
          <div className="message-text-area__attachment-title">
            <span>Select a file</span>
            <span className="message-text-area__attachment-size">[Max: 20 MB]</span>
          </div>
        }
        getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
        content={
          <div className="message-text-area__attachment-content">
            <Upload
              multiple={false}
              showUploadList={true}
              fileList={clientAttachments?.list?.data.map(({id, attachment_name}) => {
                return {
                  uid: id,
                  id,
                  name: attachment_name,
                };
              })}
              accept=".csv,.xlsx,.doc,.pdf,.txt,.html,.htm,.jpeg,.jpg,.png"
              beforeUpload={(file: any) => {
                if (setAttachment) {
                  setAttachment(file);
                }
                return false;
              }}
              onRemove={async file => {
                await deleteClientAttachment(file.uid);
              }}
              onPreview={(file: UploadFile) => handleFileClick(file, setAttachment)}
            >
              <Tooltip title={'Upload a new file'}>
                <Button>
                  <Icon type="plus" />
                </Button>
              </Tooltip>
            </Upload>
          </div>
        }
        trigger="click"
      >
        <Button style={{width: '32px'}}>
          <Icon type="paper-clip" />
        </Button>
      </Popover>
    )
  ) : null;
  const allTemplates = defaultTemplate ? [defaultTemplate, ...(templates || [])] : [...(templates || [])];
  const filteredTemplates = searchText
    ? allTemplates.filter((template: Template) => template.title.toLowerCase().includes(searchText.toLowerCase()))
    : allTemplates;
  const toggleTemplatesModal = () => setTemplatesDropdownVisible(value => !value);

  const insertVarMenu = (
    <Menu>
      {insertTexts.map((item, index) => (
        <Menu.Item key={index} onClick={() => insertText(item)}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const filterTemplates = (value: string) => setSearchText(value);

  const inputTemplate = event => {
    toggleTemplatesModal();
    const template = filteredTemplates.find(template => parseInt(template.id) === parseInt(event.key));
    setValue(template.text);
  };

  const templatesDropdown = () => {
    return (
      <Dropdown
        disabled={disabled}
        overlayClassName="message-text-area__templates-dropdown"
        overlay={
          <TemplatesMenu
            filterTemplates={filterTemplates}
            searchText={searchText}
            filteredTemplates={filteredTemplates}
            inputTemplate={inputTemplate}
            createTemplate={createTemplate ? () => createTemplate(value) : null}
            inputMessage={value}
            onClose={() => setTemplatesDropdownVisible(false)}
            className={classnames(className, 'message-text-area__templates-trigger')}
          />
        }
        placement="topCenter"
        visible={templatesDropdownVisible}
      >
        <Button
          className={classnames(className, 'message-text-area__templates-trigger')}
          onClick={toggleTemplatesModal}
          style={{marginLeft: 5}}
          type="dashed"
        >
          <IconWrapper>
            <FontAwesomeIcon icon={findIconDefinition({prefix: 'fas', iconName: 'edit'})} />
          </IconWrapper>
          Templates
        </Button>
      </Dropdown>
    );
  };

  return (
    <div style={{display: display ? 'block' : 'none'}}>
      <Form.Item
        label={
          <>
            {isAdmin && <AdminFeatureIcon />}
            {label}
          </>
        }
        validateStatus={errors && touched ? 'error' : ''}
      >
        <div className="message-text-area__container">
          {onSuggestMessageTemplate && !disabled && (
            <Tooltip title={'Generate Message with AI'}>
              <Button
                onClick={onSuggestMessageTemplate}
                className="message-text-area__suggest-template-button"
                shape="circle"
                type="link"
                loading={false}
              >
                <MagicIcon />
              </Button>
            </Tooltip>
          )}
          <div className="ant-input-textarea ant-input-textarea-show-count">
            <Tooltip title={tooltip}>
              <div>
                <textarea
                  data-testid={dataTestId}
                  ref={elementRef}
                  name={name}
                  placeholder={placeholder}
                  value={value}
                  onChange={ev => setValue(ev.target.value)}
                  className={classnames('message-text-area__input ant-input', {
                    'textarea-whitelabel': showWhitelabelSignature,
                  })}
                  style={{margin: 0}}
                  disabled={disabled}
                />
              </div>
            </Tooltip>
            {showWhitelabelSignature ? (
              <div className="message-text-area__whitelabel-signature">
                <span>{STRINGS.free_trial_signature}</span>
                <ConfirmationModal
                  visible={showUpgradeConfirmation}
                  message="By clicking yes, you will remove the signature and upgrade to a paying subscription of $99 per month? Are you sure to proceed?"
                  onOk={() => {
                    onUpgradePlan().then(() => setShowUpgradeConfirmation(false));
                  }}
                  onCancel={() => setShowUpgradeConfirmation(false)}
                />
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setShowUpgradeConfirmation(true)}
                  style={{marginLeft: '5px', fontSize: '.9em'}}
                >
                  Upgrade
                </Button>
              </div>
            ) : null}
          </div>
          <div ref={backdropRef} className="message-text-area__backdrop">
            <div ref={highlightsRef} className="message-text-area__highlights" />
          </div>
        </div>
      </Form.Item>
      <Row type="flex" className="message-text-area-buttons__wrapper">
        <div style={{maxWidth: '40%'}}>
          <ErrorMessage name={name} className="message-text-area-buttons__error" component="div" />
        </div>

        <div>
          {attachmentElement}
          {insertTexts?.length > 0 && (
            <Dropdown disabled={disabled} overlay={insertVarMenu} placement="bottomLeft">
              <Button>
                <Icon type="plus" /> Insert variable
              </Button>
            </Dropdown>
          )}
          {templates && templatesDropdown()}
        </div>
      </Row>
      <div style={{marginBottom: 5}}>{additionalData}</div>
    </div>
  );
};

MessageTextArea.defaultProps = {
  display: true,
};
