import {connect} from 'react-redux';

import {LazyAccountsSettings} from '@growth-x/ui';

import {adminMaintenanceActions} from '../../redux/adminMaintenance';
import {clientActions} from '../../redux/client';
import {headerModalActions} from '../../redux/headerModal';
import {settingsActions} from '../../redux/settings';

function mapState(state: any) {
  return {
    client: state.client.data,
    isAdmin: state.settings.isAdmin,
    clientCustomProxyVisible: state.client.clientCustomProxyVisible,
  };
}

const actionCreators = {
  setHeaderModal: headerModalActions.setHeaderModal,
  onSubmit: settingsActions.submitSettings,
  getSubscriptionInfo: adminMaintenanceActions.getSubscriptionInfo,
  changeClientFeatures: clientActions.changeClientFeatures,
};

export const AccountsSettingsContainer = connect(mapState, actionCreators)(LazyAccountsSettings);
