export class WorldService {
  client: any;
  serviceName = 'world';
  constructor({client}: any) {
    this.client = client;
  }
  getClientCountry() {
    return this.client.get(`${this.serviceName}/me`, {});
  }
  getCountryList() {
    return this.client.get(`${this.serviceName}/countries`, {});
  }
}
