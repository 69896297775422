import {message} from 'antd';
import {useState} from 'react';

interface ErrorHandlerResult {
  error: Error | null;
  handleError: (error: any, customMessage?: string) => void;
  clearError: () => void;
}

export const useErrorHandler = (): ErrorHandlerResult => {
  const [error, setError] = useState<Error | null>(null);

  const handleError = (error: any, customMessage?: string) => {
    setError(error);

    if (error?.response?.status === 400 && error?.response?.data) {
      const responseData = error.response.data;

      // TODO ask BE about structure of error and create parser : (
      if (responseData.non_field_errors && responseData.non_field_errors[0]?.message) {
        message.error(responseData.non_field_errors[0].message);
      } else if (
        responseData.url &&
        Array.isArray(responseData.url) &&
        responseData.url.length > 0 &&
        responseData.url[0]?.message
      ) {
        message.error('URL: ' + responseData.url[0].message);
      } else if (responseData.detail) {
        message.error(responseData.detail);
      } else {
        message.error('Bad Request');
      }
    } else if (customMessage) {
      message.error(customMessage);
    } else if (error instanceof Error) {
      message.error(error.message);
    } else {
      message.error('An unexpected error occurred.');
    }

    // TODO does sentry collect those errors?
    console.error(error);
  };

  const clearError = () => {
    setError(null);
  };

  return {error, handleError, clearError};
};
