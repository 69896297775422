import {Modal, Row, Radio, Col, Button} from 'antd';
import React from 'react';

import acceleratePlanIcon from '../../assets/images/accelerate_plan.png';
import corePlanIcon from '../../assets/images/core_plan.png';
import {ReactComponent as DoubleCheck} from '../../assets/svg/double-check.svg';
import {CONSTANTS} from '../../utils';
import Plan from './Plan';

import './ChoosePlanGxModal.scss';

export const ChoosePlanGxModal = ({visible, onCancel, period, onChangePeriod, onClick}) => (
  <Modal visible={visible} footer={null} destroyOnClose={true} onCancel={onCancel} width={1000} title="Checkout">
    <div className="plans_container gx">
      <Row type="flex" justify="center" style={{marginBottom: 45}}>
        <Radio.Group className="choose_plan" value={period} buttonStyle="solid" onChange={onChangePeriod}>
          <Radio.Button value="monthly">Monthly</Radio.Button>
          <Radio.Button value="annual">Annual</Radio.Button>
        </Radio.Group>
      </Row>
      <Row gutter={[32, 8]}>
        {GX_PLANS.map(plan => (
          <Col key={plan.key} span={12}>
            <Plan
              plan={plan}
              isPopular={plan.key === CONSTANTS.BILLING.PLANS.STARTER}
              period={period}
              isCollapsable={plan.key !== CONSTANTS.BILLING.PLANS.STARTER}
              highlighted={false}
              button={
                <div className="plan_bottom">
                  <Button onClick={() => onClick(plan)} type="primary">
                    {plan.button}
                  </Button>
                </div>
              }
            />
          </Col>
        ))}
      </Row>
    </div>
  </Modal>
);

export const GX_PLANS = [
  {
    key: CONSTANTS.BILLING.PLANS.CORE,
    title: 'Core',
    prices: {
      monthly: {
        price: '$99',
        description: '/per month',
      },
      annual: {
        price: '$79',
        description: '/per month',
      },
    },
    button: 'Get Started Now',
    icon: <img src={corePlanIcon} alt="core plan" />,
    features: (
      <>
        <div className="included">
          <DoubleCheck /> Everything in Free
        </div>
        <div className="caption">Team Collaboration</div>
        <ul>
          <li>Personalized Message Sequences</li>
          <li>Unlimited templates library</li>
          <li>Tags and notes for conversation tracking</li>
          <li>Built-in duplicate detection</li>
          <li>CRM integration capabilities</li>
        </ul>
        <div className="caption">AI features</div>
        <ul>
          <li>AI-driven hot lead identification</li>
          <li>AI-assisted rapid campaign creation</li>
        </ul>
        <div className="caption">Killer Campaigns &amp; Seamless Integrations</div>
        <ul>
          <li>Website Visitors Campaign via Instantly</li>
          <li>API/Webhook/Zapier Campaign Sources</li>
          <li>Upload your own list</li>
          <li>Retargeting Google Ads, Meta Ads, LinkedIn Ads, and more</li>
          <li>Sales Navigator LinkedIn Search</li>
          <li>Nurturing Campaigns</li>
          <li>Email Automation via Instantly</li>
        </ul>
        <div className="caption">Agency Only Features</div>
        <ul>
          <li>Whitelabel (10+ users required)</li>
        </ul>
      </>
    ),
  },
  {
    key: CONSTANTS.BILLING.PLANS.STARTER,
    title: 'Accelerate',
    prices: {
      monthly: {
        price: '$299',
        description: '/per month',
      },
      annual: {
        price: '$239',
        description: '/per month',
      },
    },
    button: 'Get Started Now',
    icon: <img src={acceleratePlanIcon} alt="accelerate plan" />,
    features: (
      <>
        <div className="included">
          <DoubleCheck /> Everything in Core
        </div>
        <div className="caption">VIP features</div>
        <ul>
          <li>
            <span style={{textDecoration: 'underline'}}>Dedicated Growth Expert</span>
          </li>
          <li>1:1 Training Sessions</li>
          <li>Priority Support</li>
          <li>Campaign Review &amp; Optimization</li>
          <li>Profile Review &amp; Optimization</li>
          <li>API/CRM Assisted Integration</li>
          <li>Executive Analytics</li>
          <li>Unlimited Outreach</li>
          <li>Data Credits</li>
        </ul>
      </>
    ),
  },
];
