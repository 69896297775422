import * as Yup from 'yup';

import {Plans} from '@growth-x/types';

type PlanPricing = {
  plan_id: Plans.CORE | Plans.STARTER | Plans.ON_HOLD | Plans.FREE;
  price: number;
};

export interface CustomerSettings {
  apply_changes: boolean;
  monthly: PlanPricing[];
  yearly: PlanPricing[];
  'half-yearly': PlanPricing[];
  'three-monthly': PlanPricing[];
}

export const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
};

export const FormSchema = Yup.object().shape({
  monthly: Yup.array().of(
    Yup.object().shape({
      plan_id: Yup.string(),
      price: Yup.number().min(0).required('Required'),
    })
  ),
  yearly: Yup.array().of(
    Yup.object().shape({
      plan_id: Yup.string(),
      price: Yup.number().min(0).required('Required'),
    })
  ),
  'half-yearly': Yup.array().of(
    Yup.object().shape({
      plan_id: Yup.string(),
      price: Yup.number().min(0).required('Required'),
    })
  ),
  'three-monthly': Yup.array().of(
    Yup.object().shape({
      plan_id: Yup.string(),
      price: Yup.number().min(0).required('Required'),
    })
  ),
});
