import axios from 'axios';

import {IApiService} from '@growth-x/types';

import {clientActions} from '../redux/client';
import {maintenanceActions} from '../redux/maintenance';
import {CONSTANTS} from './constants';

axios.defaults.withCredentials = true;

export const interceptor = (store: any) => {
  axios.interceptors.response.use(
    res => {
      return res;
    },
    err => {
      if (!err.response || err.response.status === -1 || /^5[0-9]{2}$/.test(err.response.status)) {
        store.dispatch(maintenanceActions.checkMaintenance());
      } else if (err && err.response && err.response.status === 401 && !err.response.config.url.includes('logout')) {
        if (
          !(window.location.pathname as any).includes('/login') &&
          !(window.location.pathname as any).includes('/signup') &&
          !(window.location.pathname as any).includes('/reset-password')
        ) {
          store.dispatch(clientActions.logout(true));
        }
      }
      return Promise.reject(err);
    }
  );
};

const paramsToString = (params: any) => {
  return Object.keys(params)
    .map(function (key) {
      if (Array.isArray(params[key])) {
        return params[key].map(param => encodeURIComponent(key) + '=' + encodeURIComponent(param)).join('&');
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      }
    })
    .join('&');
};

class ApiService implements IApiService {
  // TODO parameters for get optional
  async get(service_name: string, parameters: any) {
    return this.request('GET', service_name, parameters);
  }
  async getByUrl(url: string, parameters: any) {
    return this.request('GET', url, parameters, false, true);
  }
  async post(service_name: string, parameters: any) {
    return this.request('POST', service_name, parameters);
  }
  async put(service_name: string, parameters: any) {
    return this.request('PUT', service_name, parameters);
  }
  async delete(service_name: string, parameters: any) {
    return this.request('DELETE', service_name, parameters);
  }
  async patch(service_name: string, parameters: any) {
    return this.request('PATCH', service_name, parameters);
  }
  async postMultipart(service_name: string, parameters: any) {
    const fd = new FormData();
    Object.keys(parameters).forEach(key => {
      if (parameters[key] != null) {
        fd.append(key, parameters[key]);
      }
    });
    return this.request('POST', service_name, fd, true);
  }
  async getUrl(service_name: string, parameters: any): Promise<string> {
    return CONSTANTS.url + service_name + `?${paramsToString(parameters)}`;
  }
  async request(method: string, service_name: string, parameters: any, multipart = false, byUrl = false) {
    let url = byUrl ? service_name : CONSTANTS.url + service_name;
    const headers = {'Content-Type': multipart ? 'multipart/form-data' : 'application/json'};
    const isGet = method === 'GET';

    if (isGet) {
      if (byUrl) {
        url += `&${paramsToString(parameters)}`;
      } else {
        url += `?${paramsToString(parameters)}`;
      }
    }
    const requestCommonData = {method, url, headers};
    const requestData: any = isGet
      ? requestCommonData
      : {...requestCommonData, data: multipart ? parameters : JSON.stringify(parameters)};
    return axios(requestData);
  }
}

export const client: IApiService = new ApiService();
