import debounce from 'lodash/debounce';

import {ReceiversService, TagsService} from '../../services';
import {UPDATE_CLIENT_TAGS} from '../client';
import {SET_SETTINGS} from '../settings';

export const GET_CONNECTIONS_REQUEST = 'GET_CONNECTIONS_REQUEST';
export const GET_CONNECTIONS_SUCCESS = 'GET_CONNECTIONS_SUCCESS';
export const GET_CONNECTIONS_FAILURE = 'GET_CONNECTIONS_FAILURE';
export const LOAD_MORE_CONNECTIONS_REQUEST = 'LOAD_MORE_CONNECTIONS_REQUEST';
export const LOAD_MORE_CONNECTIONS_SUCCESS = 'LOAD_MORE_CONNECTIONS_SUCCESS';

export const connectionsActions = {
  getConnections,
  loadMoreConnections,
  exportConnections,
  getTags,
  addTags,
  removeTags,
  enhanceConnections,
  filterConnections,
};

function getConnections(connectionsFilter: any) {
  return (dispatch: any, getState: any) => {
    dispatch(request());
    const clientId = getState().client.data.id;
    const settings = getState().settings;
    settings[clientId] = {...settings[clientId], connectionsFilter};
    dispatch({type: SET_SETTINGS, settings});
    ReceiversService.getConnections(connectionsFilter).then(
      ({data}: any) => {
        dispatch(success(data.receivers, data.total));
      },
      (error: string) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {type: GET_CONNECTIONS_REQUEST};
  }
  function success(connections: any, total: number) {
    return {type: GET_CONNECTIONS_SUCCESS, connections, total};
  }
  function failure(status: string) {
    return {type: GET_CONNECTIONS_FAILURE, status};
  }
}

const debouncedGetData = debounce((dispatch, connectionsFilter) => {
  ReceiversService.getConnections(connectionsFilter).then(
    ({data}: any) => {
      dispatch(success(data.receivers, data.total));
    },
    (error: string) => {
      dispatch(failure(error));
    }
  );
  function success(connections: any, total: number) {
    return {type: GET_CONNECTIONS_SUCCESS, connections, total};
  }
  function failure(status: string) {
    return {type: GET_CONNECTIONS_FAILURE, status};
  }
}, 1000);

function filterConnections(connectionsFilter: any) {
  return (dispatch: any, getState: any) => {
    const clientId = getState().client.data.id;
    const settings = getState().settings;
    const newConnectionsFilter = {...settings.connectionsFilter, ...connectionsFilter};
    settings[clientId] = {...settings[clientId], connectionsFilter: newConnectionsFilter};
    dispatch({type: SET_SETTINGS, settings});
    dispatch(request());

    debouncedGetData(dispatch, newConnectionsFilter);
  };

  function request() {
    return {type: GET_CONNECTIONS_REQUEST};
  }
}

function loadMoreConnections() {
  return (dispatch: any, getState: any) => {
    const clientId = getState().client.data.id;
    const settings = getState().settings;
    const totalConnections = getState().connections.total;
    const connections = getState().connections.list;
    const connectionsFilter = settings[clientId].connectionsFilter;
    if (connections.length !== totalConnections && connectionsFilter.start < totalConnections) {
      const newConnectionsFilter = {...connectionsFilter, start: connectionsFilter.start + 50};
      settings[clientId] = {...settings[clientId], connectionsFilter: newConnectionsFilter};
      dispatch({type: SET_SETTINGS, settings});
      dispatch(request());
      ReceiversService.getConnections(newConnectionsFilter).then(
        ({data}: any) => {
          dispatch(success(data.receivers, data.total));
        },
        (error: string) => {
          dispatch(failure(error));
        }
      );
    }
  };

  function request() {
    return {type: LOAD_MORE_CONNECTIONS_REQUEST};
  }
  function success(connections: any, total: number) {
    return {type: LOAD_MORE_CONNECTIONS_SUCCESS, connections, total};
  }
  function failure(status: string) {
    return {type: GET_CONNECTIONS_FAILURE, status};
  }
}

function exportConnections(filters) {
  return () => {
    ReceiversService.exportConnections(filters).then((url: string) => {
      window.open(url, '_self');
    });
  };
}

function getTags() {
  return (dispatch: any) => {
    return TagsService.getTagsByClient().then((response: any) => {
      dispatch({type: UPDATE_CLIENT_TAGS, tags: response?.data?.tags || []});
    });
  };
}

function addTags(tags: string[], receivers: number[]) {
  return () => {
    return TagsService.addTags(tags, receivers);
  };
}

function removeTags(tags: string[], receivers: number[]) {
  return () => {
    return TagsService.removeTags(tags, receivers);
  };
}

function enhanceConnections(connectionIds: number[]) {
  return (dispatch: any, getState: any) => {
    const clientId = getState().client.data.id;
    const connectionsFilter = getState().settings[clientId].connectionsFilter;
    ReceiversService.enhanceReceivers(connectionIds).then(() => {
      dispatch(getConnections(connectionsFilter));
    });
  };
}
