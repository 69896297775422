import {createStore, applyMiddleware, compose, combineReducers, UnknownAction} from 'redux';
import {ThunkAction, withExtraArgument} from 'redux-thunk';

import {StorageService} from '../services';
import {emit} from '../services/sockets';
import {adminMaintenanceReducers} from './adminMaintenance/';
import {audienceTreeReducers} from './audienceTree';
import {billingReducers} from './billing';
import {campaignsReducers} from './campaigns';
import {chargebeeReducers} from './chargebee';
import {clientReducers} from './client';
import {clientAttachmentsReducers} from './clientAttachments';
import {clientMaintenanceReducers} from './clientMaintenance';
import {connectionsReducers} from './connections';
import {headerModalReducers} from './headerModal';
import {hsOauth2Reducers} from './hsOauth2';
import {inboxReducers} from './inbox';
import {instantlyReducers} from './instantly';
import {maintenanceReducers} from './maintenance';
import {manualTasksReducers} from './manualTasks';
import {membersReducers} from './members';
import {notificationsReducers} from './notifications';
import {prospectsReducers} from './prospects';
import {proxyReducers} from './proxy';
import {rb2bReducers} from './rb2b';
import {settingsReducers} from './settings';
import {sfOauth2Reducers} from './sfOauth2';
import {templatesReducers} from './templates';
import {usersReducers} from './users';

const rootReducer = combineReducers({
  client: clientReducers,
  users: usersReducers,
  prospects: prospectsReducers,
  connections: connectionsReducers,
  campaigns: campaignsReducers,
  settings: settingsReducers,
  notifications: notificationsReducers,
  inbox: inboxReducers,
  maintenance: maintenanceReducers,
  templates: templatesReducers,
  clientAttachments: clientAttachmentsReducers,
  members: membersReducers,
  audienceTree: audienceTreeReducers,
  sfOauth2: sfOauth2Reducers,
  hsOauth2: hsOauth2Reducers,
  chargebee: chargebeeReducers,
  billing: billingReducers,
  clientMaintenance: clientMaintenanceReducers,
  adminMaintenance: adminMaintenanceReducers,
  instantly: instantlyReducers,
  manualTasks: manualTasksReducers,
  headerModal: headerModalReducers,
  rb2b: rb2bReducers,
  proxy: proxyReducers,
});

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialStore = StorageService.getAllItems();
const middleware = [withExtraArgument({emit})];
export const store = createStore(rootReducer, initialStore, composeEnhancers(applyMiddleware(...middleware)));

export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
export type AppGetState = AppStore['getState'];
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunkAction = ThunkAction<void, RootState, unknown, UnknownAction>;
