import {Tooltip} from 'antd';
import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Campaign, Client} from '@growth-x/types';

import {CONSTANTS, evaluatePotential, formatNumbers, STRINGS} from '../../utils';
import {ExclamationIcon, DangerIcon} from '../icons';

interface ICampaignPotentialProps {
  client: Client;
  record: Campaign;
}

export const CampaignPotential = ({record, client}: ICampaignPotentialProps) => {
  const potentialText = formatNumbers(record.potential);
  const potentialStatus = evaluatePotential(record.potential);
  const columnText = potentialText ? `${potentialText}` : '-';
  const campaign_user = record.campaign_users ? record.campaign_users[0] : {};
  const isEmailInvites = record.type === CONSTANTS.campaignTypes.acquisition_via_import;
  const isRegularCampaign =
    record.type === CONSTANTS.campaignTypes.acquisition || record.type === CONSTANTS.campaignTypes.retention;
  const isParsingNotFinishedEmailInvites = isEmailInvites && campaign_user && !record.last_scan_date;
  const isParsingNotFinishedRegularCampaign = isRegularCampaign && campaign_user && campaign_user.loop === 0;
  const isParsingNotFinished =
    !!potentialText && (isParsingNotFinishedEmailInvites || isParsingNotFinishedRegularCampaign);
  const showUserNotSelectedWarning = !potentialText && !record.selectedUser && isRegularCampaign;
  const showCampaignEnabledWarning = !potentialText && !client.enabled;
  const isProblemDetected = showUserNotSelectedWarning || showCampaignEnabledWarning;
  const showPotentialWarnings = !CONSTANTS.ENDLESS_CAMPAIGN_TYPES.includes(record.type);
  const emptyPotentialElement = isProblemDetected ? (
    <span>...</span>
  ) : (
    <Tooltip title={STRINGS.new_campaign_info_message}>
      <span>...</span>
    </Tooltip>
  );
  return (
    <span className="campaigns-page__potential-container">
      <FontAwesomeIcon icon="globe-americas" />
      <span style={{marginLeft: '10px'}}>{potentialText ? columnText : emptyPotentialElement}</span>
      {showUserNotSelectedWarning && <ExclamationIcon message={STRINGS.tooltip.campaign_user_not_selected} />}
      {showCampaignEnabledWarning && <ExclamationIcon message={STRINGS.tooltip.campaigns_not_enabled} />}
      {showPotentialWarnings && (
        <>
          {isParsingNotFinished && <ExclamationIcon message={STRINGS.tooltip.wrong_potential} />}
          {potentialStatus === 'warning' && <ExclamationIcon message={STRINGS.tooltip.warning_potential} />}
          {potentialStatus === 'danger' && <DangerIcon message={STRINGS.tooltip.danger_potential} />}
        </>
      )}
    </span>
  );
};
