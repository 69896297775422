import * as Yup from 'yup';

import {isHebrew, isValidMessageLength, replaceKeys, STRINGS, CONSTANTS} from '../../../utils';

export const AcquisitionSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  in_mail_subject: Yup.string().when('type', (type: string, schema: any) => {
    return type === 'acquisition_inmail'
      ? schema
          .required('Required')
          .matches(CONSTANTS.regexes.message_variables, 'Inmail subject can not contain message variables')
      : schema.nullable();
  }),
  filters: Yup.string().when(
    ['type'],
    (type: string, schema: any) => {
      if (type === CONSTANTS.campaignTypes.acquisition) {
        return schema
          .test(
            'max',
            ({value}: any) => {
              const isNewUIFilter = value.includes('recentSearchParam');
              const limit = isNewUIFilter ? CONSTANTS.limits.new_url_length : CONSTANTS.limits.url_length;
              return replaceKeys(STRINGS.not_support_too_long_urls, ['{url_max_length}'], [`${limit}`]);
            },
            (value: any) => {
              if (!value) return true;
              const isNewUIFilter = value.includes('recentSearchParam');
              const limit = isNewUIFilter ? CONSTANTS.limits.new_url_length : CONSTANTS.limits.url_length;
              return value.length <= limit;
            }
          )
          .url(STRINGS.not_support_linkedin_url_filters)
          .matches(/^((?!.*saved-leads.*).)*$/, STRINGS.not_support_saved_leads_filters)
          .matches(/^((?!.*sales\/lists.*).)*$/, STRINGS.not_support_saved_lists_filters)
          .matches(/^((?!.*sharedSearchId.*).)*$/, STRINGS.not_support_saved_search_filters)
          .matches(/^.*(sales\/search).*$/, 'Users without a Sales Navigator account will be skipped.')
          .matches(
            new RegExp(
              `^(${STRINGS.sn_root_url}|${STRINGS.sn_simplified_root_url})((?!.*${STRINGS.sn_root_url}|${STRINGS.sn_simplified_root_url}.*).)*$`
            ),
            STRINGS.not_support_multi_links
          )
          .matches(
            new RegExp(`^(${STRINGS.sn_root_url}|${STRINGS.sn_simplified_root_url}).*$`),
            STRINGS.not_support_general_search_filters
          )
          .required('Required');
      } else if (type === CONSTANTS.campaignTypes.acquisition_basic) {
        return schema
          .url(STRINGS.not_support_linkedin_url_filters)
          .matches(/^.*(search\/results).*$/, 'This must be the LinkedIn search URL')
          .required('Required');
      } else if (type === 'acquisition_csv_liked_post') {
        return schema
          .url(STRINGS.not_support_linkedin_url_filters)
          .matches(/^.*(posts\/).*$/, STRINGS.error_linkedin_post_link)
          .required('Required');
      }
      return schema.nullable();
    }
  ),
  csv: Yup.mixed().when(['type', 'id'], (type: string, id: number, schema: any) => {
    return type === 'acquisition_csv'
      ? schema
          .test('fileType', 'File should be valid csv', (value: any) => {
            if (!value) return true;
            return value.name.includes('.csv');
          })
      : schema;
  }),
  message: Yup.string().when(
    ['type', 'shouldShowWhitelabelSignature'],
    (type: string, shouldShowWhitelabelSignature: boolean, schema: any) => {
      if (type === 'acquisition_via_import') {
        return schema;
      }
      const isInmail = type === 'acquisition_inmail';
      const messageCountLimit = isInmail ? CONSTANTS.limits.inmail_message_length : CONSTANTS.limits.invite_message;
      const hebrewMessageCountLimit = isInmail
        ? CONSTANTS.limits.inmail_message_length
        : CONSTANTS.limits.hebrew_invite_message;

      return schema
        .test(
          'hebrew-max',
          `Max Length is ${hebrewMessageCountLimit} characters`,
          (value: any) => !(value && isHebrew(value) && value.length > hebrewMessageCountLimit)
        )
        .test('max', `Max Length is ${messageCountLimit} characters`, (value: any) => {
          const {isValid, message} = isValidMessageLength(value, messageCountLimit);
          return !(value && !isHebrew(value) && !isValid && message.length > messageCountLimit);
        })
        .test(
          'whitelabel-max',
          `Max Length is ${CONSTANTS.limits.invite_message_whitelabel_limit} characters`,
          (value: any) =>
            !(value && shouldShowWhitelabelSignature && value.length > CONSTANTS.limits.invite_message_whitelabel_limit)
        );
    }
  ),
  followup_message: Yup.mixed().when(
    ['followup_attachment', 'type'],
    (followup_attachment: any, type: string, schema: any) => {
      return schema
        .test('required', 'Required', (value: any) => !!value)
        .test('max', STRINGS.error_max_followup_length, (value: any) => {
          return value && value.length <= 8000;
        })
        .test(
          'file-size',
          'The uploaded file exceeds max file size of 20 MB, please upload a smaller file.',
          (value: any) => {
            if (followup_attachment && followup_attachment.size) {
              return value && followup_attachment.size <= CONSTANTS.limits.max_attachment_size;
            }
            return true;
          }
        )
        .test(
          'file-extension',
          'The uploaded file has an invalid extension. Please upload a file with any of these extensions .csv, xlsx, .doc, .pdf, .txt, .html, .htm, .jpeg, .jpg, .png',
          () => {
            const validExtensions = ['csv', 'xlsx', 'doc', 'pdf', 'txt', 'html', 'htm', 'jpeg', 'jpg', 'png'];
            if (followup_attachment && followup_attachment.name) {
              const name = followup_attachment.name;
              const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
              return validExtensions.indexOf(ext) !== -1;
            }
            return true;
          }
        )
        .test('invalid_file_name', 'File name should contain only letters, digits, "_" or "-"', () => {
          if (followup_attachment && followup_attachment.name) {
            return CONSTANTS.regexes.attachment_name.test(followup_attachment.name);
          }
          return true;
        });
    }
  ),
  second_followup_message: Yup.mixed().when(
    ['second_followup_attachment', 'type'],
    (second_followup_attachment: any, type: string, schema: any) => {
      return schema
        .test('max', STRINGS.error_max_followup_length, (value: any) => {
          return !value || value.length <= 8000;
        })
        .test(
          'file-size',
          'The uploaded file exceeds max file size of 20 MB, please upload a smaller file.',
          (value: any) => {
            if (second_followup_attachment && second_followup_attachment.size) {
              return value && second_followup_attachment.size <= CONSTANTS.limits.max_attachment_size;
            }
            return true;
          }
        )
        .test(
          'file-extension',
          'The uploaded file has an invalid extension. Please upload a file with any of these extensions .csv, xlsx, .doc, .pdf, .txt, .html, .htm, .jpeg, .jpg, .png',
          () => {
            const validExtensions = ['csv', 'xlsx', 'doc', 'pdf', 'txt', 'html', 'htm', 'jpeg', 'jpg', 'png'];
            if (second_followup_attachment && second_followup_attachment.name) {
              const name = second_followup_attachment.name;
              const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
              return validExtensions.indexOf(ext) !== -1;
            }
            return true;
          }
        )
        .test('invalid_file_name', 'File name should contain only letters, digits, "_" or "-"', () => {
          if (second_followup_attachment && second_followup_attachment.name) {
            return CONSTANTS.regexes.attachment_name.test(second_followup_attachment.name);
          }
          return true;
        });
    }
  ),
  third_followup_message: Yup.mixed().when(
    ['third_followup_attachment', 'type'],
    (third_followup_attachment: any, type: string, schema: any) => {
      return schema
        .test('max', STRINGS.error_max_followup_length, (value: any) => {
          return !value || value.length <= 8000;
        })
        .test(
          'file-size',
          'The uploaded file exceeds max file size of 20 MB, please upload a smaller file.',
          (value: any) => {
            if (third_followup_attachment && third_followup_attachment.size) {
              return value && third_followup_attachment.size <= CONSTANTS.limits.max_attachment_size;
            }
            return true;
          }
        )
        .test(
          'file-extension',
          'The uploaded file has an invalid extension. Please upload a file with any of these extensions .csv, xlsx, .doc, .pdf, .txt, .html, .htm, .jpeg, .jpg, .png',
          () => {
            const validExtensions = ['csv', 'xlsx', 'doc', 'pdf', 'txt', 'html', 'htm', 'jpeg', 'jpg', 'png'];
            if (third_followup_attachment && third_followup_attachment.name) {
              const name = third_followup_attachment.name;
              const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
              return validExtensions.indexOf(ext) !== -1;
            }
            return true;
          }
        )
        .test('invalid_file_name', 'File name should contain only letters, digits, "_" or "-"', () => {
          if (third_followup_attachment && third_followup_attachment.name) {
            return CONSTANTS.regexes.attachment_name.test(third_followup_attachment.name);
          }
          return true;
        });
    }
  ),
  exclude_companies: Yup.string().max(70000, STRINGS.error_max_exclude_companies_length).nullable(),
  tracking_link_prefix: Yup.string()
    .matches(/^[a-z0-9]+$/i, 'Only alphanumeric symbols allowed')
    .nullable(),
  instantly_campaign_ids: Yup.mixed().when(['instantly_campaigns'], (instantly_campaigns: any, schema: any) => {
    return schema.test('campaign_exist', `Selected instantly campaign doesn't exist`, value => {
      return !value.some(campaign => !instantly_campaigns.find(c => c.id === campaign));
    });
  }),
});
