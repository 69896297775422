import {UserErrorCodes, User} from '@growth-x/types';

export function getUsersWithErrors(user: User, isAdmin: boolean) {
  if (user.archived) return false;
  if (user.warning_code) return true;
  if (!user.error_code) return false;
  if (isAdmin && user.error_code) return true;
  return [
    UserErrorCodes.WrongCredentials,
    UserErrorCodes.LoginCaptcha,
    UserErrorCodes.LoginTwoSteps,
    UserErrorCodes.LoginIdentityRequired,
    UserErrorCodes.LoginEmailVerification,
    UserErrorCodes.NameViolation,
    UserErrorCodes.LoginAppVerification,
    UserErrorCodes.SnHasOktaSso,
    UserErrorCodes.UserLanguage,
  ].includes(user.error_code as UserErrorCodes);
}
