import React from 'react';

import {getLogo} from './utils';
import './Logo.scss';

export const MobileLogo = () => {
  return (
    <div className="logo_mobile">
      <img src={getLogo()} className="logo__img" alt="logo" />
    </div>
  );
};
