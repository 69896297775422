import {Brands} from '@growth-x/types';

import {brandConfig} from '../../utils';
import BreachLogoImage from './breach.svg';
import DigitalMarkLogoImage from './digital-mark.svg';
import GxLogoImage from './gx.svg';
import InfiniteLogoImage from './infinite.png';
import LeadokuLogoImage from './leadoku.png';

export const getLogo = () => {
  switch (brandConfig.brand) {
    case Brands.LEADOKU:
      return LeadokuLogoImage;
    case Brands.INFINITE:
      return InfiniteLogoImage;
    case Brands.DIGITALMARK:
      return DigitalMarkLogoImage;
    case Brands.BREACH:
      return BreachLogoImage;
    default:
      return GxLogoImage;
  }
};
