import {CONSTANTS, formatNumbers, isCampaignRetention} from '@growth-x/ui';

import StatusesMap from './statusesMap';

function getFullItem(item, campaign) {
  const allStatuses = StatusesMap.getAllStatuses();
  const status = allStatuses.get(item.status);
  if (status) {
    if (isCampaignRetention(campaign) && item.status === CONSTANTS.audience_statuses.created) {
      return {...item, ...status, label: 'to be nurtured'};
    }
    return {...item, ...status};
  }
  return {...item, label: item.status, color: '#bfbfbf'};
}

function getInitialOptionsForCampaign(campaign) {
  const acquisitionDefault = {
    invited: 0,
    created: 0,
    error_company_excluded: 0,
    error_invite_exist: 0,
    error_pending: 0,
    errors: 0,
  };
  const retentionDefault = {
    nurtured: 0,
    created: 0,
    error_company_excluded: 0,
    error_response_or_followup_detected: 0,
    error_exclude_responders: 0,
    error_contacted_by_excluded_campaign: 0,
    error_open_sequence: 0,
    error_not_gx_connection: 0,
    error_in_mail: 0,
    error_different_typed_message: 0,
    error_do_not_send_tag: 0,
    errors: 0,
  };
  const inmailDefault = {
    invited_free_inmail: 0,
    invited_credit_used: 0,
    created: 0,
    error_company_excluded: 0,
    error_invite_exist: 0,
    error_pending: 0,
    errors: 0,
  };
  const initialOptions = {
    acquisition: {
      ...acquisitionDefault,
      error_email_required: 0,
      error_different_typed_invite: 0,
    },
    acquisition_csv: {
      ...acquisitionDefault,
      error_email_required: 0,
      error_different_typed_invite: 0,
      error_profile_url: 0,
    },
    acquisition_csv_rb2b: {
      ...acquisitionDefault,
      error_email_required: 0,
      error_different_typed_invite: 0,
      error_profile_url: 0,
    },
    acquisition_csv_api: {
      ...acquisitionDefault,
      error_email_required: 0,
      error_different_typed_invite: 0,
      error_profile_url: 0,
    },
    acquisition_csv_zapier: {
      ...acquisitionDefault,
      error_email_required: 0,
      error_different_typed_invite: 0,
      error_profile_url: 0,
    },
    acquisition_via_import: {
      ...acquisitionDefault,
      error_no_email: 0,
    },
    acquisition_message_request: {
      ...acquisitionDefault,
    },
    acquisition_csv_instantly: {
      ...acquisitionDefault,
      error_email_required: 0,
      error_different_typed_invite: 0,
      error_profile_url: 0,
    },
    acquisition_csv_liked_post: {
      ...acquisitionDefault,
      error_email_required: 0,
      error_different_typed_invite: 0,
      error_profile_url: 0,
    },
    retention: {...retentionDefault},
    retention_csv: {
      ...retentionDefault,
      error_profile_url: 0,
    },
    retention_tags: {...retentionDefault},
    retention_actions: {...retentionDefault},
    acquisition_inmail: {...inmailDefault},
  };
  return initialOptions[campaign.type];
}

export function mapRawStatsToItems(stats, campaign) {
  const items: any = getInitialOptionsForCampaign(campaign);
  const validStatuses = StatusesMap.getValidStatuses();
  for (const s of stats) {
    if (validStatuses.has(s.status)) {
      items[s.status] = s.total;
    } else {
      items.errors += s.total;
    }
  }
  const preparedItems = Object.keys(items).map(i => getFullItem({status: i, total: formatNumbers(items[i])}, campaign));
  preparedItems.sort((a, b) => b.priority - a.priority);
  return preparedItems;
}

export function mapRawStatsToErrors(stats, campaign) {
  const items: any = {};
  const errorStatuses = StatusesMap.getErrorStatuses();
  for (const s of stats) {
    if (errorStatuses.has(s.status)) {
      items[s.status] = s.total;
    }
  }
  const preparedItems = Object.keys(items).map(i => getFullItem({status: i, total: formatNumbers(items[i])}, campaign));
  preparedItems.sort((a, b) => b.priority - a.priority);
  return preparedItems;
}
