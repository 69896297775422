import {Spin, Form, Button, Row, Alert, Switch} from 'antd';
import {Formik} from 'formik';
import React, {useState, useEffect} from 'react';

import {BillingService} from '../../../services';
import {useErrorHandler} from '../../../utils/hooks/useErrorHandler';
import {AlertMessage} from './AlertMessage';
import {PlanPriceField} from './PlanPriceField';
import {formItemLayout, FormSchema, CustomerSettings} from './utils';

export function ClientSubscriptionSettings({close}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [settings, setSettings] = useState<CustomerSettings>(null);
  const {handleError, clearError} = useErrorHandler();

  const getSubscriptionSettings = async () => {
    setLoading(true);
    clearError();

    try {
      const {data} = await BillingService.getCustomerSettings();
      setSettings({...data?.plan_pricing, apply_changes: false});
    } catch (err) {
      handleError(err);
      setSettings(null);
    } finally {
      setLoading(false);
    }
  };

  const updateSubscriptionSettings = async (settings: CustomerSettings) => {
    setIsUpdating(true);
    try {
      const {apply_changes, ...plan_pricing} = settings;
      await BillingService.updateCustomerSetting({plan_pricing, apply_changes});
      close();
    } catch (err) {
      handleError(err);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    getSubscriptionSettings();
  }, []);

  if (loading) return <Spin />;

  return (
    <Formik
      initialValues={settings}
      validationSchema={FormSchema}
      onSubmit={updateSubscriptionSettings}
      enableReinitialize
    >
      {({values, errors, handleSubmit, setFieldValue}) => (
        <Form {...formItemLayout} layout="vertical" onSubmit={handleSubmit}>
          <h2>Monthly subscriptions price</h2>
          {values.monthly.map((plan, index) => (
            <PlanPriceField
              plan={plan}
              key={plan.plan_id}
              onChange={e => setFieldValue(`monthly[${index}].price`, e.target.value)}
              errors={errors.monthly?.[index]}
            />
          ))}
          <h2>Yearly subscriptions price</h2>
          {values.yearly.map((plan, index) => (
            <PlanPriceField
              key={plan.plan_id}
              plan={plan}
              onChange={e => setFieldValue(`yearly[${index}].price`, e.target.value)}
              errors={errors.yearly?.[index]}
            />
          ))}
          <h2>Half-Yearly subscriptions price</h2>
          {values['half-yearly'].map((plan, index) => (
            <PlanPriceField
              key={plan.plan_id}
              plan={plan}
              onChange={e => setFieldValue(`half-yearly[${index}].price`, e.target.value)}
              errors={errors['half-yearly']?.[index]}
            />
          ))}
          <h2>3 Month subscriptions price</h2>
          {values['three-monthly'].map((plan, index) => (
            <PlanPriceField
              key={plan.plan_id}
              plan={plan}
              onChange={e => setFieldValue(`three-monthly[${index}].price`, e.target.value)}
              errors={errors['three-monthly']?.[index]}
            />
          ))}
          <Form.Item label="Apply changes:" style={{marginBottom: 0}} labelCol={{span: 16}} wrapperCol={{span: 8}}>
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <span style={{marginRight: 10, fontWeight: 'bold'}}>{values.apply_changes ? 'Yes' : 'No'}</span>
              <Switch
                onChange={(checked: boolean) => setFieldValue('apply_changes', checked)}
                checked={values.apply_changes}
              />
            </div>
          </Form.Item>
          <Alert message={AlertMessage} type="warning" style={{marginBottom: 20}} />
          <Row type="flex" justify="space-between">
            <Button onClick={close} type="ghost">
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" loading={isUpdating} disabled={isUpdating}>
              Save
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
