import {Form, Switch} from 'antd';
import React from 'react';

import {LockIcon} from '../..';
import {STRINGS} from '../../..';

interface SwitchRowProps {
  label: string | React.ReactNode;
  onChange: (checked: boolean) => void;
  checked: boolean;
  isLock: boolean;
  onLockClick?: () => void;
}

export const SwitchRow = ({label, onChange, checked, isLock, onLockClick}: SwitchRowProps) => {
  return (
    <Form.Item label={label} labelCol={{span: 20}} wrapperCol={{span: 4}}>
      <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
        {isLock && onLockClick && (
          <div style={{marginRight: 10}}>
            <LockIcon onClick={() => (onLockClick ? onLockClick() : null)} message={STRINGS.message_unlock_feature} />
          </div>
        )}
        <Switch onChange={onChange} checked={checked} disabled={isLock} />
      </div>
    </Form.Item>
  );
};
