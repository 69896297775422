import {Tooltip, Icon, Dropdown, Button, Menu, Radio, Select, Input} from 'antd';
import * as React from 'react';

import {brandConfig} from '@growth-x/ui';

import {CONSTANTS} from '../../services/constants';

const InputGroup = Input.Group;
const RadioGroup = Radio.Group;
const Option = Select.Option;

export const ProspectsRightActions = ({
  client,
  handleExportAll,
  handleRefresh,
  prospectsLoading,
}: any) => {
  const [creditSelect, setCreditSelect] = React.useState(0);
  const showCreditSelect = client.data_credits <= 0;
  return (
    <div className="prospects-page__right-buttons" style={{width: showCreditSelect ? '360px' : '180px'}}>
      <span style={{marginRight: '10px'}}>
        {client.data_credits} Credits{' '}
        <Tooltip title="Each credit count for 1 email match.">
          <Icon type="info-circle" className="tooltip-icon" />
        </Tooltip>
      </span>
      {showCreditSelect && !brandConfig.isAffiliate ? (
        <InputGroup compact style={{width: '180px'}}>
          <Select onChange={(val: any) => setCreditSelect(val)} value={creditSelect} style={{width: '140px'}}>
            {CONSTANTS.credit_packages.map((option: any) => (
              <Option value={option.value} key={option.value}>
                {option.name}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            icon="shopping-cart"
            target="_blank"
            disabled={!creditSelect}
            href={`mailto:${brandConfig.web}?subject=Request%20to%20Buy%20${
              CONSTANTS.credit_packages.find((o: any) => o.value === creditSelect).name
            }%20Data%20Credits`}
          />
        </InputGroup>
      ) : null}
      <Tooltip title="Export All Prospects">
        <Button onClick={handleExportAll} shape="circle" icon="download" />
      </Tooltip>
      <Tooltip title="Refresh">
        <Button onClick={handleRefresh} loading={prospectsLoading} shape="circle" icon="reload" />
      </Tooltip>
    </div>
  );
};
