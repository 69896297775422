import {Icon, Input, List, Tooltip, Avatar, Button, Spin, Divider} from 'antd';
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {findIconDefinition, IconLookup} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Client} from '@growth-x/types';

import {parseCardsList} from '../../utils';
import {SidebarCard} from '../sidebar/SidebarCard';
import './inboxSidebarContent.css';

interface IInboxSidebarContentProps {
  openReceiverInConnections: any;
  user: any;
  conversation: any;
  updateReceiverNote: any;
  tagsComponent: React.ReactNode;
  selectCampaignComponent: React.ReactNode;
  client: Client;
  updateInbox: () => void;
  isClientOffline: boolean;
  inboxUpdating?: boolean;
  conversationsDetailLoading: boolean;
}

export const InboxSidebarContent = ({
  openReceiverInConnections,
  user,
  conversation,
  updateReceiverNote,
  client,
  updateInbox,
  isClientOffline,
  tagsComponent,
  selectCampaignComponent,
  conversationsDetailLoading,
}: IInboxSidebarContentProps) => {
  const [inputNoteValue, setInputNoteValue] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const cards = parseCardsList(conversation, openReceiverInConnections);

  useEffect(() => {
    setInputNoteValue(conversation?.receiver?.inbox_note);
  }, [conversation?.receiver?.inbox_note]);

  const handleUpdateInbox = () => {
    setLoading(true);
    updateInbox();
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  if (!conversation) return null;

  if (conversationsDetailLoading) {
    return (
      <div className="inbox__sidebar-spinner-wrapper">
        <Spin size="large" />
      </div>
    );
  }

  const getListItemTitle = (value, link, extra, crm) => {
    const valueSpan = <span className={'inbox__sidebar-title'}>{value || 'Unknown'}</span>;
    if (link) {
      if (crm) {
        return (
          <a style={{textDecoration: 'underline'}} href={link} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      }
      return (
        <Link style={{textDecoration: 'underline'}} to={link}>
          {value}
        </Link>
      );
    }
    if (extra) {
      return (
        <>
          {valueSpan}
          <Tooltip title={extra}>
            <Avatar className={'inbox__sidebar-title-avatar'} size="small">
              +1
            </Avatar>
          </Tooltip>
        </>
      );
    }
    return valueSpan;
  };

  return (
    <div className="inbox__sidebar">
      <div className="inbox__sidebar-row-title" style={{minHeight: '49px'}}>
        <span style={{display: 'block', padding: '12px 16px', fontWeight: 'bold'}}>Conversation details</span>
        <Button
          onClick={handleUpdateInbox}
          disabled={!client.enabled || loading || isClientOffline}
          style={{marginRight: 15}}
        >
          <Icon spin={loading} type="sync" />
        </Button>
      </div>

      <ul style={{padding: '2px'}}>
        <SidebarCard bordered={false} title={null}>
          <List itemLayout={'horizontal'} style={{padding: '0'}}>
            {cards?.map(({key, title, value, link, icon: {prefix, iconName}, extra, crm, disabled}: any) => {
              return (
                value &&
                !disabled && (
                  <List.Item key={key}>
                    <Tooltip placement="left" title={title}>
                      <List.Item.Meta
                        style={{margin: '0', alignItems: 'center'}}
                        avatar={
                          <Avatar
                            style={{backgroundColor: 'unset', color: 'unset'}}
                            icon={
                              <Icon style={{marginRight: '6px'}}>
                                <FontAwesomeIcon
                                  icon={findIconDefinition({
                                    prefix,
                                    iconName,
                                  } as IconLookup)}
                                />
                              </Icon>
                            }
                          />
                        }
                        title={getListItemTitle(value, link, extra, crm)}
                      />
                    </Tooltip>
                  </List.Item>
                )
              );
            })}
          </List>
          <Divider style={{margin: 5}} />
        </SidebarCard>
        <SidebarCard bordered={false} title={null} key={'note'}>
          <div className="conversation__note">
            <Input.TextArea
              style={{height: '100px'}}
              value={inputNoteValue}
              defaultValue={conversation?.receiver?.inbox_note}
              className="conversation__note-input"
              placeholder="Insert a note..."
              onChange={(e: any) => {
                setInputNoteValue(e.target.value);
              }}
              onBlur={(e: any) => {
                updateReceiverNote(user?.id, conversation?.receiver?.id, e.target.value);
              }}
              maxLength={200}
            />
          </div>
          <Divider style={{margin: 5}} />
        </SidebarCard>
        <SidebarCard title="" bordered={false}>
          {tagsComponent}
          <Divider style={{margin: 5}} />
        </SidebarCard>
        <SidebarCard title="" bordered={false}>
          {selectCampaignComponent}
        </SidebarCard>
      </ul>
    </div>
  );
};
