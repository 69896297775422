import React from 'react';

const ApplyMessage =
  'If you apply changes, then for all subscriptions of the customer with the type whose price has changed ' +
  ' and they do not have a coupon, the new price will be applied immediately, without prorate.';

const NotApplyMessage =
  'If you do not apply the changes, then the new price will be applied only to new subscriptions, ' +
  ' all existing ones will remain unchanged.';

export const AlertMessage = (
  <div style={{textAlign: 'center'}}>
    {ApplyMessage}
    <br />
    {NotApplyMessage}
  </div>
);
