export enum EventType {
  ConnectionCreated = 'connection.created',
  ConnectionUpdated = 'connection.updated',
  MessageCreated = 'message.created',
  TagAttached = 'tag.attached',
  TagDetached = 'tag.detached',
}
export type Webhook = {
  id: number;
  name: string;
  url: string;
  active: boolean;
  event_type: EventType;
};
