import {Button, Collapse, Form, Input, message, Row, Select, Spin, Icon, Alert, Tooltip} from 'antd';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';

import {brandConfig, CONSTANTS, generateAlphabetString, CampaignDetailsProps} from '@growth-x/ui';

import {ConfirmationModal, InfoIcon, LockIcon, MessageLengthCount, MessageTextArea} from '../';
import {isContainLink, isHebrew, STRINGS} from '../..';
import {CsvInput} from '../csvInput/csvInput';
import {getAdvancedMessages, RemoveFollowUpButton, SearchFilterLabel, SwitchRow} from './common';
import {UrlFilters} from './urlFilters';
import {AcquisitionSchema} from './validationSchemas';

const {Option, OptGroup} = Select;
const {Panel} = Collapse;

export const AcquisitionDetails = ({
  campaign,
  campaignList,
  client,
  clientAttachments,
  deleteClientAttachment,
  campaignProcessing,
  trackIntercomEvent,
  apiUrl,
  getTags,
  onSubmit,
  onCancel,
  isCSVInvalid,
  error,
  onParseSearchUrl,
  campaignParsedFilters,
  campaignParsedFiltersLoading,
  resetSearchUrlFilters,
  templates,
  createTemplate,
  moveFromPromoPlan,
  getFollowupMessageSuggestion,
  isInstantlyVerified,
  rb2bLatestEvent,
  setHeaderModal,
  InstantlyAcquisitionFormItemContainer,
  clientTags,
  instantlyCampaigns,
}: CampaignDetailsProps) => {
  const [isFiltersChanged, setIsFiltersChanged] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('');
  const [selectedType, setSelectedType] = useState(undefined);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [second_followup_message_visible, setSecondFollowupMessageVisible] = useState(false);
  const [third_followup_message_visible, setThirdFollowupMessageVisible] = useState(false);
  // TODO WHAT IS THIS??
  const [tracking_link_prefix, setTrackingLinkPrefix] = useState(
    !!campaign.id ? campaign.tracking_link_prefix : generateAlphabetString()
  );

  const day_options = Array(30)
    .fill(0)
    .map((_, idx) => idx + 1);

  const defaultDaysDelay = '7';

  useEffect(() => {
    setSelectedType(client.campaign_sources_limited ? CONSTANTS.campaignTypes.acquisition_basic : campaign.type);
    setSecondFollowupMessageVisible(!!campaign.second_followup_message);
    setThirdFollowupMessageVisible(!!campaign.third_followup_message);

    if (campaign.filters) {
      onParseSearchUrl(campaign.filters);
    }
  }, [campaign, getTags, onParseSearchUrl]);

  const getCsvFileLink = () => {
    return campaign.csv ? `${apiUrl}api/v2/campaigns/${campaign.id}/download-csv` : null;
  };

  const handleSubmit = async (campaignForm: any) => {
    if (
      campaignForm.type === 'acquisition_csv' ||
      campaignForm.followup_attachment ||
      campaignForm.second_followup_attachment ||
      campaignForm.third_followup_attachment
    ) {
      if (campaignForm.csv && campaignForm.type === 'acquisition_csv' && (await isCSVInvalid(campaignForm.csv))) return;
      setProcessingMessage(
        campaignForm.followup_attachment ||
          campaignForm.second_followup_attachment ||
          campaignForm.third_followup_attachment
          ? 'Uploading file, please wait.'
          : 'CSV processing will take a while, please, wait for the ending.'
      );
    }

    await onSubmit(
      {
        ...campaign,
        ...campaignForm,
      },
      {second_followup_message_visible, third_followup_message_visible}
    );
  };

  const resetFields = setFieldValue => {
    setFieldValue('filters', '');
    setFieldValue('csv', null);
    setFieldValue('csv_name', '');
    resetSearchUrlFilters();
  };

  const handleSwitchCampaignType = (selected, setFieldValue) => {
    resetFields(setFieldValue);
    setFieldValue('type', selected);
    setSelectedType(selected);
    if (selected === 'acquisition_basic') {
      // basic li campaign doesn't have invite message
      setFieldValue('message', '');
    }
  };

  const onClickCsvRequest = () => {
    if (!client.csv_campaigns_feature) {
      trackIntercomEvent('csv_campaigns_feature_request', true, true);
      message.info('Your request sent.');
    }
  };

  const onClose = () => setConfirmationVisible(true);
  const onCloseConfirmModal = () => setConfirmationVisible(false);
  const onCloseModals = () => {
    setConfirmationVisible(false);
    onCancel();
  };

  const getInviteMaxLength = (selectedType, message) => {
    if (selectedType === 'acquisition_inmail') {
      return CONSTANTS.limits.inmail_message_length;
    } else if (isHebrew(message)) {
      return CONSTANTS.limits.hebrew_invite_message;
    } else if (client.signature_enabled) {
      return CONSTANTS.limits.invite_message_whitelabel_limit;
    }
    return CONSTANTS.limits.invite_message;
  };

  const canUpdateCSVFile = !!campaign.id && !!campaign.csv_name;
  const beforeUploadAttachment = (setFieldValue: any, field, value: any) => {
    setFieldValue(field, value);
  };

  const handleSwitchTextarea = (checked, key, setFieldValue) => {
    const attachmentKey = key.replace('_message', '_attachment');
    if (key === 'second_followup_message') {
      setSecondFollowupMessageVisible(!!checked);
    } else if (key === 'third_followup_message') {
      setThirdFollowupMessageVisible(!!checked);
    }
    setFieldValue(key, '');
    setFieldValue(attachmentKey, '');
  };

  const getTooltipMessage = type => {
    let message;
    let followup_message;
    switch (type) {
      case 'acquisition_message_request':
        message = STRINGS.tooltip.message_request_message;
        followup_message = STRINGS.tooltip.followup_message;
        break;
      case 'acquisition_inmail':
        message = STRINGS.tooltip.inmail_message;
        followup_message = STRINGS.tooltip.inmail_followup_message;
        break;
      default:
        message = STRINGS.tooltip.invite_message;
        followup_message = STRINGS.tooltip.followup_message;

        break;
    }
    return {message, followup_message};
  };

  const onSuggestMessageTemplate = async (type, values, setValue, valueName) => {
    const result = await getFollowupMessageSuggestion({
      campaign_name: values.name,
      search_filter_url: values.filters,
      initial_message: values.message,
      first_message: values.followup_message,
      second_message: values.second_followup_message,
      suggest_message_type: type,
    });
    if (result) {
      setValue(valueName, result);
    }
  };

  const getIntegratedCampaignTooltip = (isExist, campaignType) => {
    const campaignName = campaignType === CONSTANTS.campaignTypes.acquisition_csv_instantly ? 'Instantly' : 'RB2B';
    if (isExist && !isCampaignExist) {
      return `You already have a Website Visitors by ${campaignName} campaign enabled. Please archive the existing one, to create a new one.`;
    }
    return '';
  };

  const getSelectedTypeWarningIfNeeded = campaignType => {
    const campaignTypeSettings = {
      [CONSTANTS.campaignTypes.acquisition_csv_instantly]: {
        text: (
          <span style={{color: 'var(--color-main-orange)'}}>
            To enable Website Visitors by Instantly campaign you need to{' '}
            <Button type="link" style={{padding: 0, height: 20}} onClick={() => setHeaderModal('instantly')}>
              <span style={{textDecoration: 'underline'}}>configure</span>
            </Button>{' '}
            it.
          </span>
        ),
        configured: isInstantlyVerified,
      },
      [CONSTANTS.campaignTypes.acquisition_csv_rb2b]: {
        text: (
          <span style={{color: 'var(--color-main-orange)'}}>
            To enable Website Visitors by RB2B campaign you need to{' '}
            <Button type="link" style={{padding: 0, height: 20}} onClick={() => setHeaderModal('rb2b')}>
              <span style={{textDecoration: 'underline'}}>configure</span>
            </Button>{' '}
            it.
          </span>
        ),
        configured: rb2bLatestEvent,
      },
      [CONSTANTS.campaignTypes.acquisition_csv_api]: {
        text: (
          <span style={{color: 'var(--color-main-orange)'}}>
            To enable LinkedIn Profiles URLs via API campaign you need to{' '}
            <Button type="link" style={{padding: 0, height: 20}} onClick={() => setHeaderModal('api')}>
              <span style={{textDecoration: 'underline'}}>configure</span>
            </Button>{' '}
            it.
          </span>
        ),
        configured: client.has_api_setup,
      },
    };
    if (campaignTypeSettings[campaignType] && !campaignTypeSettings[campaignType].configured) {
      return campaignTypeSettings[campaignType].text;
    }
    return null;
  };

  const shouldShowSuggestionButton = !campaign.id && client.ai_followup_generation_feature;
  const isCampaignExist = !!campaign.id;
  const shouldShow = !['acquisition_via_import', 'acquisition_message_request', 'acquisition_inmail'].includes(
    selectedType
  );
  const isRb2bCampaignExist = !!campaignList.find(c => c.type === CONSTANTS.campaignTypes.acquisition_csv_rb2b);
  const isInstantlyCampaignExist = !!campaignList.find(
    c => c.type === CONSTANTS.campaignTypes.acquisition_csv_instantly
  );
  return (
    <Spin spinning={campaignProcessing} tip={processingMessage}>
      <ConfirmationModal visible={confirmationVisible} onOk={onCloseModals} onCancel={onCloseConfirmModal} />
      <Formik
        initialValues={{
          name: campaign.name,
          filters: campaign.filters,
          message: campaign.message,
          in_mail_subject: campaign.in_mail_subject,
          followup_message: campaign.followup_message,
          second_followup_message: campaign.second_followup_message,
          exclude_companies: campaign.exclude_companies,
          exclude_responders: campaign.exclude_responders,
          enhance_active: campaign.enhance_active,
          multiple_invites_active: campaign.multiple_invites_active,
          twitter_follow: campaign.twitter_follow,
          second_followup_days: campaign.second_followup_days || defaultDaysDelay,
          type: campaign.type,
          id: campaign.id,
          csv: null,
          csv_name: campaign.csv_name,
          followup_attachment: campaign.followup_attachment || '',
          second_followup_attachment: campaign.second_followup_attachment || '',
          invites_volume:
            campaign.invites_volume ||
            (!['acquisition_message_request', 'acquisition_inmail'].includes(campaign.type) ? '1' : '0'),
          third_followup_message: campaign.third_followup_message,
          third_followup_days: campaign.third_followup_days || defaultDaysDelay,
          third_followup_attachment: campaign.third_followup_attachment || '',
          tracking_link_prefix: tracking_link_prefix,
          add_attribution: campaign.add_attribution,
          isDuplicatedCampaign: campaign.isDuplicatedCampaign,
          shouldShowWhitelabelSignature: client.signature_enabled,
          linkedin_basic_feature: client?.linkedin_basic_feature,
          instantly_campaign_ids: campaign.instantly_campaign_ids || [],
          instantly_tags_ids: campaign.instantly_tags_ids || [],
          instantly_campaigns: instantlyCampaigns,
        }}
        enableReinitialize={true}
        validationSchema={AcquisitionSchema}
        onSubmit={handleSubmit}
      >
        {({values, touched, errors, dirty, handleSubmit, setFieldValue}) => (
          <Form onSubmit={handleSubmit} layout="vertical">
            <div style={{position: 'relative', padding: '20px 20px 0 20px'}}>
              {!!campaign.archived && (
                <div className="view-only-overlay">
                  <span className="view-only-overlay__text">
                    <Icon type="eye" /> View only
                  </span>
                </div>
              )}
              <Form.Item
                label="Name*:"
                validateStatus={errors.name && touched.name ? 'error' : ''}
                hasFeedback
                help={errors.name && touched.name ? errors.name : ''}
              >
                <Input
                  name="name"
                  value={values.name}
                  placeholder="Type the name of your campaign"
                  onChange={ev => setFieldValue('name', ev.target.value)}
                />
              </Form.Item>
              {shouldShow && (
                <Form.Item
                  label={
                    <span style={{display: 'flex', alignItems: 'center'}}>
                      Source*:
                      <span style={{marginLeft: 5, cursor: 'pointer'}}>
                        <InfoIcon
                          message={
                            <>
                              <span>{STRINGS.tooltip.nurturing_type_url}</span>
                              <br />
                              <span>{STRINGS.tooltip.nurturing_type_csv}</span>
                            </>
                          }
                        />
                      </span>
                    </span>
                  }
                  help={getSelectedTypeWarningIfNeeded(selectedType)}
                >
                  <Select
                    disabled={!values.isDuplicatedCampaign && isCampaignExist}
                    onChange={value => handleSwitchCampaignType(value, setFieldValue)}
                    value={selectedType}
                  >
                    <OptGroup label="LinkedIn" key="linkedin">
                      <Option key="acquisition" disabled={client.campaign_sources_limited}>
                        Sales Navigator Search
                      </Option>
                      {client?.linkedin_basic_feature && (
                        <Option key="acquisition_basic">Regular LinkedIn Search</Option>
                      )}
                      <Option key="acquisition_csv_liked_post">LinkedIn Post Likers</Option>
                    </OptGroup>
                    <OptGroup label="Import" key="import">
                      <Option key="acquisition_csv">CSV file</Option>
                    </OptGroup>
                    <OptGroup label="Integrations" key="integrations">
                      {client.instantly_feature && client.instantly_visitor_feature_enabled && (
                        <Option
                          disabled={isInstantlyCampaignExist}
                          key={CONSTANTS.campaignTypes.acquisition_csv_instantly}
                        >
                          <Tooltip
                            title={getIntegratedCampaignTooltip(
                              isInstantlyCampaignExist,
                              CONSTANTS.campaignTypes.acquisition_csv_instantly
                            )}
                          >
                            <div>Website Visitors via Instantly</div>
                          </Tooltip>
                        </Option>
                      )}
                      {client.rb2b_feature_enabled && (
                        <Option disabled={isRb2bCampaignExist} key={CONSTANTS.campaignTypes.acquisition_csv_rb2b}>
                          <Tooltip
                            title={getIntegratedCampaignTooltip(
                              isRb2bCampaignExist,
                              CONSTANTS.campaignTypes.acquisition_csv_rb2b
                            )}
                          >
                            <div>Website Visitors via RB2B</div>
                          </Tooltip>
                        </Option>
                      )}
                      <Option key={CONSTANTS.campaignTypes.acquisition_csv_api}>LinkedIn Profiles URLs via API</Option>
                      <Option key={CONSTANTS.campaignTypes.acquisition_csv_zapier}>
                        Leads via Zapier (7,000+ apps)
                      </Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              )}
              {
                {
                  acquisition: (
                    <Form.Item
                      label={SearchFilterLabel(selectedType === CONSTANTS.campaignTypes.acquisition_basic)}
                      validateStatus={errors.filters && touched.filters ? 'error' : ''}
                      hasFeedback
                      help={errors.filters && touched.filters ? errors.filters : ''}
                    >
                      <Input
                        name="filters"
                        placeholder={
                          selectedType === CONSTANTS.campaignTypes.acquisition_basic
                            ? STRINGS.placeholder.li_filter_url
                            : STRINGS.placeholder.filter_url
                        }
                        value={values.filters}
                        onBlur={() => onParseSearchUrl(values.filters)}
                        onChange={ev => {
                          setFieldValue('filters', ev.target.value);
                          setIsFiltersChanged(true);
                        }}
                      />
                    </Form.Item>
                  ),
                  acquisition_csv: (
                    <Form.Item
                      label={
                        <span style={{display: 'flex', alignItems: 'center'}}>
                          <span>
                            CSV file*:
                            {!client.csv_campaigns_feature && (
                              <span style={{marginLeft: 5, cursor: 'pointer'}}>
                                <LockIcon onClick={onClickCsvRequest} message={STRINGS.message_unlock_feature} />
                              </span>
                            )}
                          </span>
                          <span style={{marginLeft: 5, cursor: 'pointer'}}>
                            <InfoIcon message={STRINGS.tooltip.csv} />
                          </span>
                        </span>
                      }
                      validateStatus={errors.csv && touched.csv ? 'error' : ''}
                      hasFeedback
                      help={errors.csv && touched.csv ? errors.csv : ''}
                    >
                      <CsvInput
                        setFieldValue={setFieldValue}
                        disabled={client.csv_campaigns_feature ? canUpdateCSVFile : !client.csv_campaigns_feature}
                        csvFile={values.csv}
                        csvName={values.csv_name}
                        link={getCsvFileLink()}
                      />
                    </Form.Item>
                  ),
                  acquisition_via_import: (
                    <Form.Item
                      label={SearchFilterLabel()}
                      validateStatus={errors.filters && touched.filters ? 'error' : ''}
                      hasFeedback
                      help={errors.filters && touched.filters ? errors.filters : ''}
                    >
                      <Input
                        name="filters"
                        placeholder={
                          selectedType === CONSTANTS.campaignTypes.acquisition_basic
                            ? STRINGS.placeholder.li_filter_url
                            : STRINGS.placeholder.filter_url
                        }
                        value={values.filters}
                        onBlur={() => onParseSearchUrl(values.filters)}
                        onChange={ev => {
                          setFieldValue('filters', ev.target.value);
                          setIsFiltersChanged(true);
                        }}
                      />
                    </Form.Item>
                  ),
                  acquisition_message_request: (
                    <Form.Item
                      label={SearchFilterLabel()}
                      validateStatus={errors.filters && touched.filters ? 'error' : ''}
                      hasFeedback
                      help={errors.filters && touched.filters ? errors.filters : ''}
                    >
                      <Input
                        name="filters"
                        placeholder={STRINGS.placeholder.filter_url}
                        value={values.filters}
                        onBlur={() => onParseSearchUrl(values.filters)}
                        onChange={ev => {
                          setFieldValue('filters', ev.target.value);
                          setIsFiltersChanged(true);
                        }}
                      />
                    </Form.Item>
                  ),
                  acquisition_inmail: (
                    <>
                      <Form.Item
                        label="Inmail Subject*:"
                        validateStatus={errors.in_mail_subject && touched.in_mail_subject ? 'error' : ''}
                        hasFeedback
                        help={errors.in_mail_subject && touched.in_mail_subject ? errors.in_mail_subject : ''}
                      >
                        <Input
                          name="in_mail_subject"
                          value={values.in_mail_subject}
                          placeholder="Type the subject of your campaign"
                          onChange={ev => setFieldValue('in_mail_subject', ev.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        label={SearchFilterLabel()}
                        validateStatus={errors.filters && touched.filters ? 'error' : ''}
                        hasFeedback
                        help={errors.filters && touched.filters ? errors.filters : ''}
                      >
                        <Input
                          name="filters"
                          placeholder={STRINGS.placeholder.filter_url}
                          value={values.filters}
                          onBlur={() => onParseSearchUrl(values.filters)}
                          onChange={ev => {
                            setFieldValue('filters', ev.target.value);
                            setIsFiltersChanged(true);
                          }}
                        />
                      </Form.Item>
                    </>
                  ),
                  acquisition_csv_liked_post: (
                    <Form.Item
                      label={'Post URL*:'}
                      validateStatus={errors.filters && touched.filters ? 'error' : ''}
                      hasFeedback
                      help={errors.filters && touched.filters ? errors.filters : ''}
                    >
                      <Input
                        name="filters"
                        placeholder={
                          'https://www.linkedin.com/posts/rikypisano_outreach-bookdemo-outreachmistakes-activity-7295094252831682560-XBEj'
                        }
                        value={values.filters}
                        onChange={ev => {
                          setFieldValue('filters', ev.target.value);
                          setIsFiltersChanged(true);
                        }}
                      />
                    </Form.Item>
                  ),
                }[selectedType === CONSTANTS.campaignTypes.acquisition_basic ? 'acquisition' : selectedType]
              }
              <UrlFilters
                data={campaignParsedFilters}
                loading={campaignParsedFiltersLoading}
                filtersSavedSearch={campaign?.filters_full_json}
              />
              <div style={{marginTop: '-10px', marginBottom: '5px'}}>
                {isFiltersChanged &&
                  selectedType === CONSTANTS.campaignTypes.acquisition &&
                  getAdvancedMessages(values.filters, errors, touched).map((message: string, index: number) => (
                    <span key={`${index}`} dangerouslySetInnerHTML={{__html: message}} />
                  ))}
              </div>
              {!errors.filters && selectedType === CONSTANTS.campaignTypes.acquisition_basic && (
                <Alert
                  style={{
                    marginBottom: 10,
                    marginTop: 5,
                  }}
                  message={STRINGS.warning.basic_linkedin_campaign_warning}
                  type="warning"
                />
              )}
              <MessageTextArea
                label={
                  <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>
                      {selectedType === 'acquisition_message_request'
                        ? 'Message Request:'
                        : selectedType === 'acquisition_inmail'
                        ? 'Inmail Message:'
                        : 'Invite Message:'}
                      <span style={{marginLeft: 5, cursor: 'pointer'}}>
                        <InfoIcon message={getTooltipMessage(selectedType).message} />
                      </span>
                    </span>
                    <span>
                      <MessageLengthCount
                        text={values.message}
                        maxLength={getInviteMaxLength(selectedType, values.message)}
                      />{' '}
                      {client.signature_enabled && <InfoIcon message={STRINGS.info.signature_invite_length} />}
                    </span>
                  </span>
                }
                name="message"
                placeholder={
                  [CONSTANTS.campaignTypes.acquisition_via_import, CONSTANTS.campaignTypes.acquisition_basic].includes(
                    selectedType
                  )
                    ? STRINGS.placeholder.acquisition_via_import_message
                    : STRINGS.placeholder.invite_message
                }
                setValue={value => setFieldValue('message', value)}
                value={values.message}
                disabled={[
                  CONSTANTS.campaignTypes.acquisition_via_import,
                  CONSTANTS.campaignTypes.acquisition_basic,
                ].includes(selectedType)}
                tooltip={
                  selectedType === CONSTANTS.campaignTypes.acquisition_basic
                    ? 'Customizing the invite message note is disabled and left blank to maximize the number of connection requests on regular LinkedIn. For a more personalized approach, we recommend using Sales Navigator as the source.'
                    : ''
                }
                errors={errors.message}
                touched={touched.message}
                insertTexts={CONSTANTS.message_variable_list}
                additionalData={
                  isContainLink(values.message) && (
                    <span className="text-warning">
                      {selectedType === 'acquisition_inmail'
                        ? STRINGS.warning.inmail_message_links
                        : STRINGS.warning.invite_message_links}
                    </span>
                  )
                }
                templates={templates}
                createTemplate={createTemplate}
                className="acquisition-invite-message"
                showWhitelabelSignature={client.signature_enabled}
                onUpgradePlan={moveFromPromoPlan}
                onSuggestMessageTemplate={
                  shouldShowSuggestionButton
                    ? () => onSuggestMessageTemplate('message', values, setFieldValue, 'message')
                    : null
                }
              />
              <MessageTextArea
                label={
                  <span>
                    Followup Message*:
                    <span style={{marginLeft: 5, cursor: 'pointer'}}>
                      <InfoIcon message={getTooltipMessage(selectedType).followup_message} />
                    </span>
                  </span>
                }
                placeholder={STRINGS.placeholder.followup_message}
                name="followup_message"
                setValue={value => setFieldValue('followup_message', value)}
                value={values.followup_message}
                errors={errors.followup_message}
                touched={touched.followup_message}
                insertTexts={CONSTANTS.message_variable_list}
                showAttachment={true}
                setAttachment={value => beforeUploadAttachment(setFieldValue, 'followup_attachment', value)}
                attachment={values.followup_attachment}
                clientAttachments={clientAttachments}
                deleteClientAttachment={deleteClientAttachment}
                showWhitelabelSignature={client.signature_enabled}
                onUpgradePlan={moveFromPromoPlan}
                templates={templates}
                createTemplate={createTemplate}
                className="acquisition-followup-message"
                onSuggestMessageTemplate={
                  shouldShowSuggestionButton
                    ? () => onSuggestMessageTemplate('followup', values, setFieldValue, 'followup_message')
                    : null
                }
              />
              <MessageTextArea
                label={
                  <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>
                      Second Followup Message: <InfoIcon message={STRINGS.tooltip.second_followup_message} />
                    </span>
                    <RemoveFollowUpButton
                      onClick={() => handleSwitchTextarea(false, 'second_followup_message', setFieldValue)}
                    />
                  </span>
                }
                placeholder={STRINGS.placeholder.second_followup_message}
                name="second_followup_message"
                setValue={value => setFieldValue('second_followup_message', value)}
                value={values.second_followup_message}
                errors={errors.second_followup_message}
                touched={touched.second_followup_message}
                insertTexts={CONSTANTS.message_variable_list}
                showAttachment={true}
                setAttachment={value => beforeUploadAttachment(setFieldValue, 'second_followup_attachment', value)}
                attachment={values.second_followup_attachment}
                additionalData={
                  <div>
                    <Select
                      showSearch
                      style={{width: 80}}
                      placeholder="days"
                      optionFilterProp="children"
                      onChange={(value: any) => setFieldValue('second_followup_days', value)}
                      value={values.second_followup_days}
                      filterOption={(input: any, option: any) =>
                        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {day_options.map(day => (
                        <Select.Option key={`${day}`} value={`${day}`}>
                          {day}
                        </Select.Option>
                      ))}
                    </Select>{' '}
                    days after <InfoIcon message={'Days after first followup.'} />
                  </div>
                }
                display={second_followup_message_visible}
                clientAttachments={clientAttachments}
                deleteClientAttachment={deleteClientAttachment}
                showWhitelabelSignature={client.signature_enabled}
                onUpgradePlan={moveFromPromoPlan}
                templates={templates}
                createTemplate={createTemplate}
                className="acquisition-second-followup-message"
                onSuggestMessageTemplate={
                  shouldShowSuggestionButton
                    ? () =>
                        onSuggestMessageTemplate('second_followup', values, setFieldValue, 'second_followup_message')
                    : null
                }
              />
              <div
                className="textarea-label-wrapper"
                style={{display: second_followup_message_visible ? 'none' : 'block'}}
              >
                <label className="textarea-label">
                  <span
                    style={{cursor: 'pointer'}}
                    onClick={checked => {
                      handleSwitchTextarea(checked, 'second_followup_message', setFieldValue);
                    }}
                  >
                    <Icon type="plus" style={{backgroundColor: brandConfig.primaryColor}} /> Add Additional Followup
                    Message
                  </span>
                </label>
              </div>
              <MessageTextArea
                label={
                  <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>
                      Third Followup Message: <InfoIcon message={STRINGS.tooltip.third_followup_message} />
                    </span>
                    <RemoveFollowUpButton
                      onClick={() => handleSwitchTextarea(false, 'third_followup_message', setFieldValue)}
                    />
                  </span>
                }
                placeholder={STRINGS.placeholder.third_followup_message}
                name="third_followup_message"
                setValue={value => setFieldValue('third_followup_message', value)}
                value={values.third_followup_message}
                errors={errors.third_followup_message}
                touched={touched.third_followup_message}
                insertTexts={CONSTANTS.message_variable_list}
                showAttachment={true}
                setAttachment={value => beforeUploadAttachment(setFieldValue, 'third_followup_attachment', value)}
                attachment={values.third_followup_attachment}
                additionalData={
                  <div>
                    <Select
                      showSearch
                      style={{width: 80}}
                      placeholder="days"
                      optionFilterProp="children"
                      onChange={(value: any) => setFieldValue('third_followup_days', value)}
                      value={values.third_followup_days}
                      filterOption={(input: any, option: any) =>
                        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {day_options.map(day => (
                        <Select.Option key={`${day}`} value={`${day}`}>
                          {day}
                        </Select.Option>
                      ))}
                    </Select>{' '}
                    days after <InfoIcon message={'Days after second followup.'} />
                  </div>
                }
                display={third_followup_message_visible}
                clientAttachments={clientAttachments}
                deleteClientAttachment={deleteClientAttachment}
                showWhitelabelSignature={client.signature_enabled}
                onUpgradePlan={moveFromPromoPlan}
                templates={templates}
                createTemplate={createTemplate}
                className="acquisition-third-followup-message"
                onSuggestMessageTemplate={
                  shouldShowSuggestionButton
                    ? () => onSuggestMessageTemplate('third_followup', values, setFieldValue, 'third_followup_message')
                    : null
                }
              />
              <div
                className="textarea-label-wrapper"
                style={{
                  display: third_followup_message_visible || !second_followup_message_visible ? 'none' : 'block',
                }}
              >
                <label className="textarea-label">
                  <span
                    style={{cursor: 'pointer'}}
                    onClick={checked => {
                      handleSwitchTextarea(checked, 'third_followup_message', setFieldValue);
                    }}
                  >
                    <Icon type="plus" style={{backgroundColor: brandConfig.primaryColor}} /> Add Additional Followup
                    Message
                  </span>
                </label>
              </div>
              <Collapse
                bordered={false}
                className="acquisition-details__advanced-options"
                style={{marginBottom: '20px'}}
              >
                <Panel header="Advanced options" style={{border: 'none', margin: '0 -15px'}} key="1">
                  <Form.Item
                    label={
                      <span>
                        Exclude Companies: <InfoIcon message={STRINGS.tooltip.exclude_companies} />
                      </span>
                    }
                    validateStatus={errors.exclude_companies && touched.exclude_companies ? 'error' : ''}
                    hasFeedback
                    help={errors.exclude_companies && touched.exclude_companies ? errors.exclude_companies : ''}
                  >
                    <Input
                      name="exclude_companies"
                      placeholder={STRINGS.placeholder.exclude_companies}
                      value={values.exclude_companies}
                      onChange={ev => setFieldValue('exclude_companies', ev.target.value)}
                    />
                  </Form.Item>
                  <SwitchRow
                    label={
                      <span>
                        Convert URLs in Trackable URLs:{' '}
                        <InfoIcon
                          message={
                            <span>
                              By activating this feature, {brandConfig.name} transforms all URLs in your messages into
                              trackable links, granting you visibility into who is interacting with your content.
                              <br />
                              <br />
                              Here's how it works: Enable the feature and assign a short name to your link.{' '}
                              {brandConfig.name} then takes care of the rest. For instance, if your message says, “Hi,
                              check out my website at http://website.com,” and you've designated the short name
                              “MarchPromo,” your message will be updated to “Hi, check out my website at
                              https://bitlyl.ink/MarchPromo6f21s.”
                              <br />
                              <br />
                              When a prospect clicks the link, they're seamlessly redirected to your original URL
                              (http://website.com in this example).
                              {!brandConfig.isAffiliate && (
                                <>
                                  <span>Dive deeper into this feature at </span>
                                  <a href={brandConfig.articles.trackableUrl}>
                                    <b>Trackable URLs</b>
                                  </a>{' '}
                                  <span>to enhance your campaign tracking capabilities.</span>
                                </>
                              )}
                            </span>
                          }
                        />
                      </span>
                    }
                    onChange={(checked: boolean) => {
                      setFieldValue(
                        'tracking_link_prefix',
                        checked ? generateAlphabetString(CONSTANTS.defaultTrackingLinkPrefixLength) : null
                      );
                    }}
                    checked={!!values.tracking_link_prefix}
                    isLock={false}
                  />
                  {values.tracking_link_prefix && (
                    <Form.Item
                      label={''}
                      validateStatus={errors.tracking_link_prefix && touched.tracking_link_prefix ? 'error' : ''}
                      help={
                        errors.tracking_link_prefix && touched.tracking_link_prefix ? errors.tracking_link_prefix : ''
                      }
                    >
                      <Input
                        name="tracking_link_prefix"
                        placeholder={'URL short name (e.g. MarchPromo)'}
                        value={values.tracking_link_prefix}
                        onChange={ev => setFieldValue('tracking_link_prefix', ev.target.value)}
                      />
                    </Form.Item>
                  )}
                  {values.tracking_link_prefix && client.attribution_feature && (
                    <SwitchRow
                      label={
                        <span>
                          Adding UTM parameters to Trackable URLs:{' '}
                          <InfoIcon
                            message={
                              <span>
                                By activating this feature, {brandConfig.name} adds utm params to trackable links.
                              </span>
                            }
                          />
                        </span>
                      }
                      onChange={(checked: boolean) => {
                        setFieldValue('add_attribution', checked ? 1 : 0);
                      }}
                      checked={!!values.add_attribution}
                      isLock={false}
                    />
                  )}

                  {!client.feature_disabled && (
                    <SwitchRow
                      label={
                        <span>
                          Exclude Responders: <InfoIcon message={STRINGS.tooltip.exclude_responders} />
                        </span>
                      }
                      onChange={(checked: boolean) => setFieldValue('exclude_responders', checked ? 1 : 0)}
                      checked={!!values.exclude_responders}
                      isLock={false}
                    />
                  )}
                  {client.instantly_feature && (
                    <InstantlyAcquisitionFormItemContainer
                      errors={errors}
                      touched={touched}
                      values={values}
                      tags={clientTags}
                      onChangeCampaigns={(values: string[]) => setFieldValue('instantly_campaign_ids', values)}
                      onChangeTags={(values: string[]) => setFieldValue('instantly_tags_ids', values)}
                    />
                  )}
                  {shouldShow && (
                    <SwitchRow
                      label={
                        <span>
                          Invite people that require email using data credits:{' '}
                          <InfoIcon message={STRINGS.tooltip.send_invite_with_enhanced_email} />
                        </span>
                      }
                      onChange={(checked: boolean) => setFieldValue('enhance_active', checked ? 1 : 0)}
                      checked={!!values.enhance_active}
                      isLock={client.data_credits <= 0}
                      onLockClick={() => trackIntercomEvent('enhance_email_required_feature', true, true)}
                    />
                  )}
                  {!['acquisition_message_request', 'acquisition_inmail'].includes(selectedType) && (
                    <>
                      <SwitchRow
                        label={
                          <span>
                            Multiple Invites to Same Receiver:{' '}
                            <InfoIcon message={STRINGS.tooltip.multiple_invites_active} />
                          </span>
                        }
                        onChange={(checked: boolean) => setFieldValue('multiple_invites_active', checked ? 1 : 0)}
                        checked={!!values.multiple_invites_active}
                        isLock={!client.multiple_invites_feature}
                        onLockClick={() =>
                          trackIntercomEvent('multiple_invites_feature_request', true, !client.multiple_invites_feature)
                        }
                      />
                      <SwitchRow
                        label={
                          <span>
                            Auto Follow Connections on Twitter: <InfoIcon message={STRINGS.tooltip.twitter_follow} />
                          </span>
                        }
                        onChange={(checked: boolean) => setFieldValue('twitter_follow', checked ? 1 : 0)}
                        checked={!!values.twitter_follow}
                        isLock={!client.twitter_feature}
                        onLockClick={() => trackIntercomEvent('twitter_feature_request', true, !client.twitter_feature)}
                      />
                    </>
                  )}
                  {shouldShow && (
                    <Form.Item
                      label={
                        <span>
                          Invites Volume:{' '}
                          <InfoIcon
                            message={
                              <span>
                                {brandConfig.name} splits daily invites across campaigns evenly when default volume is
                                "Normal". For example, if a user has 100 daily maximum invites and two campaigns with
                                volume "Normal", {brandConfig.name} will send 50 invites in each campaign. <br />
                                Use High volume to double number invites, or Very High volume to triple. For example, if
                                a user has 100 daily maximum invites, two campaigns with volume "Normal" and one
                                campaign with volume "High", {brandConfig.name} will send 25 invites the two campaigns
                                "Normal" and 50 with the campaign "High". <br />
                                <br />
                                Note: setting all campaigns at level "High" will have same effect as setting them all at
                                "Normal". <br />
                                <br />
                                {!brandConfig.isAffiliate && `More info here: ${brandConfig.articles.invitesVolume}`}
                              </span>
                            }
                          />
                        </span>
                      }
                    >
                      <Select
                        disabled={!client.invites_volume_feature}
                        onChange={(value: any) => setFieldValue('invites_volume', value)}
                        value={values.invites_volume}
                        defaultValue={'1'}
                        style={{width: 120}}
                      >
                        <Option value="1">Normal</Option>
                        <Option value="2">High</Option>
                        <Option value="3">Very High</Option>
                      </Select>
                    </Form.Item>
                  )}
                </Panel>
              </Collapse>
            </div>
            <div style={{padding: '0 20px 20px 20px'}}>
              <Row style={{justifyContent: 'flex-end'}} type="flex">
                {error && <span className="campaigns-edit-modal__error-message">{STRINGS.error_server_response}</span>}
                <Button onClick={dirty ? onClose : onCloseModals} style={{marginRight: '10px'}}>
                  Cancel
                </Button>
                <Button disabled={!!campaign.archived} loading={false} type="primary" htmlType="submit">
                  Save
                </Button>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
