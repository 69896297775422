import * as Yup from 'yup';

import {CONSTANTS, STRINGS, replaceKeys} from '@growth-x/ui';

export const RetentionSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  filters: Yup.string().when(['type', 'linkedin_basic_feature'], (type: string, linkedin_basic_feature: boolean, schema: any) => {
    return type === 'retention'
      ? schema
          .test(
            'max',
            ({value}: any) => {
              const isNewUIFilter = value.includes('recentSearchParam');
              const limit = isNewUIFilter ? CONSTANTS.limits.new_url_length : CONSTANTS.limits.url_length;
              return replaceKeys(STRINGS.not_support_too_long_urls, ['{url_max_length}'], [`${limit}`]);
            },
            (value: any) => {
              if (!value) return true;
              const isNewUIFilter = value.includes('recentSearchParam');
              const limit = isNewUIFilter ? CONSTANTS.limits.new_url_length : CONSTANTS.limits.url_length;
              return value.length <= limit;
            }
          )
          .url(STRINGS.not_support_linkedin_url_filters)
          .matches(/^((?!.*saved-leads.*).)*$/, STRINGS.not_support_saved_leads_filters)
          .matches(/^((?!.*sales\/lists.*).)*$/, STRINGS.not_support_saved_lists_filters)
          .matches(/^((?!.*sharedSearchId.*).)*$/, STRINGS.not_support_saved_search_filters)
          .matches(!linkedin_basic_feature ? /^.*(sales\/search).*$/ : /^.*$/, 'Users without a Sales Navigator account will be skipped.')
          .matches(
            new RegExp(
              `^(${STRINGS.sn_root_url}|${STRINGS.sn_simplified_root_url}${linkedin_basic_feature ? `|${STRINGS.li_search_url}` : ''})((?!.*${STRINGS.sn_root_url}|${STRINGS.sn_simplified_root_url}.*).)*$`
            ),
            STRINGS.not_support_multi_links
          )
          .matches(
            new RegExp(`^(${STRINGS.sn_root_url}|${STRINGS.sn_simplified_root_url}|${linkedin_basic_feature ? `|${STRINGS.li_search_url}` : ''}).*$`),
            STRINGS.not_support_general_search_filters
          )
          .required('Required')
      : schema.nullable();
  }),
  csv: Yup.mixed().when(['type', 'id'], (type: string, id: number, schema: any) => {
    return type === 'retention_csv'
      ? schema
          .test('fileType', 'File should be valid csv', (value: any) => {
            if (!value) return true;
            return value.name.includes('.csv');
          })
      : schema;
  }),
  audience_tags_ids: Yup.mixed().when(['type'], (type: string, schema: any) => {
    return type === 'retention_tags'
      ? schema.test('required', 'Required', (value: any) => value && value?.length > 0)
      : schema.nullable();
  }),
  audience_actions: Yup.mixed().when(['type'], (type: string, schema: any) => {
    return type === 'retention_actions'
      ? schema.test('required', 'Required', (value: any) => value && value?.length > 0)
      : schema.nullable();
  }),
  message: Yup.mixed().when(['message_attachment'], (message_attachment: any, schema: any) => {
    return schema
      .test('required', 'Required', (value: any) => !!value)
      .test(
        'file-size',
        'The uploaded file exceeds max file size of 20 MB, please upload a smaller file.',
        (value: any) => {
          if (message_attachment && message_attachment.size) {
            return value && message_attachment.size <= CONSTANTS.limits.max_attachment_size;
          }
          return true;
        }
      )
      .test(
        'file-extension',
        'The uploaded file has an invalid extension. Please upload a file with any of these extensions .csv, xlsx, .doc, .pdf, .txt, .html, .htm, .jpeg, .jpg, .png',
        () => {
          const validExtensions = ['csv', 'xlsx', 'doc', 'pdf', 'txt', 'html', 'htm', 'jpeg', 'jpg', 'png'];
          if (message_attachment && message_attachment.name) {
            const name = message_attachment.name;
            const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
            return validExtensions.indexOf(ext) !== -1;
          }
          return true;
        }
      )
      .test('invalid_file_name', 'File name should contain only letters, digits, "_" or "-"', () => {
        if (message_attachment && message_attachment.name) {
          return CONSTANTS.regexes.attachment_name.test(message_attachment.name);
        }
        return true;
      });
  }),
  followup_message: Yup.mixed().when(['followup_attachment'], (followup_attachment: any, schema: any) => {
    return schema
      .test('max', STRINGS.error_max_followup_length, (value: any) => {
        return !value || value.length <= 8000;
      })
      .test(
        'file-size',
        'The uploaded file exceeds max file size of 20 MB, please upload a smaller file.',
        (value: any) => {
          if (followup_attachment && followup_attachment.size) {
            return value && followup_attachment.size <= CONSTANTS.limits.max_attachment_size;
          }
          return true;
        }
      )
      .test(
        'file-extension',
        'The uploaded file has an invalid extension. Please upload a file with any of these extensions .csv, xlsx, .doc, .pdf, .txt, .html, .htm, .jpeg, .jpg, .png',
        () => {
          const validExtensions = ['csv', 'xlsx', 'doc', 'pdf', 'txt', 'html', 'htm', 'jpeg', 'jpg', 'png'];
          if (followup_attachment && followup_attachment.name) {
            const name = followup_attachment.name;
            const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
            return validExtensions.indexOf(ext) !== -1;
          }
          return true;
        }
      )
      .test('invalid_file_name', 'File name should contain only letters, digits, "_" or "-"', () => {
        if (followup_attachment && followup_attachment.name) {
          return CONSTANTS.regexes.attachment_name.test(followup_attachment.name);
        }
        return true;
      });
  }),
  second_followup_message: Yup.mixed().when(
    ['second_followup_attachment'],
    (second_followup_attachment: any, schema: any) => {
      return schema
        .test('max', STRINGS.error_max_followup_length, (value: any) => {
          return !value || value.length <= 8000;
        })
        .test(
          'file-size',
          'The uploaded file exceeds max file size of 20 MB, please upload a smaller file.',
          (value: any) => {
            if (second_followup_attachment && second_followup_attachment.size) {
              return value && second_followup_attachment.size <= CONSTANTS.limits.max_attachment_size;
            }
            return true;
          }
        )
        .test(
          'file-extension',
          'The uploaded file has an invalid extension. Please upload a file with any of these extensions .csv, xlsx, .doc, .pdf, .txt, .html, .htm, .jpeg, .jpg, .png',
          () => {
            const validExtensions = ['csv', 'xlsx', 'doc', 'pdf', 'txt', 'html', 'htm', 'jpeg', 'jpg', 'png'];
            if (second_followup_attachment && second_followup_attachment.name) {
              const name = second_followup_attachment.name;
              const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
              return validExtensions.indexOf(ext) !== -1;
            }
            return true;
          }
        )
        .test('invalid_file_name', 'File name should contain only letters, digits, "_" or "-"', () => {
          if (second_followup_attachment && second_followup_attachment.name) {
            return CONSTANTS.regexes.attachment_name.test(second_followup_attachment.name);
          }
          return true;
        });
    }
  ),
  third_followup_message: Yup.mixed().when(
    ['third_followup_attachment'],
    (third_followup_attachment: any, schema: any) => {
      return schema
        .test('max', STRINGS.error_max_followup_length, (value: any) => {
          return !value || value.length <= 8000;
        })
        .test(
          'file-size',
          'The uploaded file exceeds max file size of 20 MB, please upload a smaller file.',
          (value: any) => {
            if (third_followup_attachment && third_followup_attachment.size) {
              return value && third_followup_attachment.size <= CONSTANTS.limits.max_attachment_size;
            }
            return true;
          }
        )
        .test(
          'file-extension',
          'The uploaded file has an invalid extension. Please upload a file with any of these extensions .csv, xlsx, .doc, .pdf, .txt, .html, .htm, .jpeg, .jpg, .png',
          () => {
            const validExtensions = ['csv', 'xlsx', 'doc', 'pdf', 'txt', 'html', 'htm', 'jpeg', 'jpg', 'png'];
            if (third_followup_attachment && third_followup_attachment.name) {
              const name = third_followup_attachment.name;
              const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
              return validExtensions.indexOf(ext) !== -1;
            }
            return true;
          }
        )
        .test('invalid_file_name', 'File name should contain only letters, digits, "_" or "-"', () => {
          if (third_followup_attachment && third_followup_attachment.name) {
            return CONSTANTS.regexes.attachment_name.test(third_followup_attachment.name);
          }
          return true;
        });
    }
  ),
  tracking_link_prefix: Yup.string()
    .matches(/^[a-z0-9]+$/i, 'Only alphanumeric symbols allowed')
    .nullable(),
});
