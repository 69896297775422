import {Button} from 'antd';
import React from 'react';

import {brandConfig} from '@growth-x/ui';

export const InstantlyLink = () => (
  <>
    <Button
      className="instantly-link"
      type="link"
      target="_blank"
      href="https://app.instantly.ai/app/settings/integrations?via=gx"
    >
      Generate API key
    </Button>
    {brandConfig.articles.instantlyInstructions && (
      <>
        {' '}
        or see instructions{' '}
        <a href={brandConfig.articles.instantlyInstructions} rel="noreferrer noopener" target="_blank">
          here
        </a>
      </>
    )}
  </>
);
