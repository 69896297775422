const CAMPAIGN_TYPES = {
  acquisition: 'acquisition',
  acquisition_csv: 'acquisition_csv',
  acquisition_via_import: 'acquisition_via_import',
  acquisition_message_request: 'acquisition_message_request',
  retention: 'retention',
  retention_csv: 'retention_csv',
  retention_tags: 'retention_tags',
  retention_actions: 'retention_actions',
  acquisition_inmail: 'acquisition_inmail',
  acquisition_csv_instantly: 'acquisition_csv_instantly',
  acquisition_csv_rb2b: 'acquisition_csv_rb2b',
  acquisition_csv_api: 'acquisition_csv_api',
  acquisition_csv_zapier: 'acquisition_csv_zapier',
  acquisition_basic: 'acquisition_basic',
  acquisition_csv_liked_post: 'acquisition_csv_liked_post',
};

const ENDLESS_CAMPAIGN_TYPES = [
  CAMPAIGN_TYPES.acquisition_csv_instantly,
  CAMPAIGN_TYPES.acquisition_csv_rb2b,
  CAMPAIGN_TYPES.acquisition_csv_api,
  CAMPAIGN_TYPES.acquisition_csv_zapier,
];

export const PLANS = {
  CORE: 'core',
  FREE: 'free',
  STARTER: 'starter',
  ON_HOLD: 'on-hold',
};

export const STATUSES = {
  CANCELED: 'canceled',
  NON_RENEWING: 'non_renewing',
  ACTIVE: 'active',
  ON_HOLD_SCHEDULED: 'on_hold_scheduled',
};

export const CARD_STATUSES = {
  VALID: 'valid',
  NO_CARD: 'no_card',
};

export const CONSTANTS = {
  maintenance_url: `https://maintenance.growth-x.com/`,
  app_url: `https://web.growth-x.com`,
  beta_url: `https://beta.growth-x.com`,
  trial_ended_banner_closed: 'trial_ended_banner_closed',
  fernet: '0u5m0JPzFW_N0kwMKDBHqT_2_Y8XcdetoFFVr3SCU_w=',
  sf_oauth2_state_key: 'oauth2-state-key',
  sf_oauth2_message_response: 'react-use-oauth2-response',
  sf_oauth2_url: 'https://login.salesforce.com/services/oauth2/authorize?response_type=code&display=popup',
  sf_oauth2_redirect_path: '/oauth2/callback',
  hs_oauth2_state_key: 'hs-oauth2-state-key',
  hs_oauth2_message_response: 'hs-react-use-oauth2-response',
  hs_oauth2_url: 'https://app-eu1.hubspot.com/oauth/authorize?',
  hs_oauth2_redirect_path: '/hs/oauth/callback',
  hs_oauth2_scope: encodeURIComponent(
    [
      'account-info.security.read',
      'crm.objects.contacts.read',
      'crm.objects.contacts.write',
      'crm.objects.deals.read',
      'crm.schemas.contacts.read',
      'crm.schemas.contacts.write',
      'crm.schemas.deals.read',
      'oauth',
    ].join(' ')
  ),
  hs_oauth2_optional_scope: encodeURIComponent(
    ['crm.objects.users.read', 'crm.objects.owners.read', 'automation'].join(' ')
  ),
  credit_packages: [
    {name: 'Select Credits', value: 0},
    {name: '100 ($47)', value: 47},
    {name: '500 ($227)', value: 227},
    {name: '1,000 ($397)', value: 397},
  ],
  campaignTypes: CAMPAIGN_TYPES,
  ENDLESS_CAMPAIGN_TYPES,
  TASK_TYPES: {
    SEND_MESSAGE: 'send_message',
    ACCOUNT_LIST: 'account_list',
    ACCOUNT_LIST_CSV: 'account_list_csv',
  },
  TASK_STATUSES: {
    CREATED: 'created',
    IN_PROGRESS: 'in_progress',
    DONE: 'done',
    ERROR: 'error',
    ERROR_PROFILE_LIMIT_REACHED: 'error_profile_limit_reached',
  },
  match_requirements: [
    {name: 'Personal email', value: 'email.personal'},
    {name: 'Work email', value: 'email.work'},
    {name: 'Any Email', value: 'email'},
  ],
  module_ids: {linkedin: 2},
  message_types: {
    message: 'message',
    response: 'response',
  },
  error_flags: {
    message_not_saved: 8,
    response_not_saved: 7,
    sn_message_not_saved: 9,
    message_from_profile_not_sent: 20,
    user_receiver_not_found: 3,
    user_receiver_not_matched: 4,
    user_receiver_sn_inbox_disabled: 5,
    user_receiver_unfriended: 6,
    user_receiver_not_able_to_message: 14,
  },
  limits: {
    could_offline_minutes: 5,
    invite_message: 300,
    invite_message_whitelabel_limit: 290,
    nurturing_message: 250,
    hebrew_invite_message: 250,
    inmail_message_length: 1850,
    twitter_follows: 100,
    url_length: 4141,
    new_url_length: 7000,
    restart_time_seconds: 82800, // 23 hours
    client_update_interval: 180000, // 3 minutes
    maintenance_update_interval: 60000, // 1 minute
    max_attachment_size: 20971520, // 20mb
  },
  regexes: {
    attachment_name: /^[a-zA-Z0-9_.\s-]*$/,
    emoji: /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF][\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|[\u4E00-\u9FA5\uF900-\uFA2D])/g,
    message_variables: /^((?!{[^.]+.[^}]+}).)*$/,
  },
  defaultTags: [
    'hot-lead',
    'closed-deal',
    'positive',
    'not-positive',
    'booked-demo',
    'completed-demo',
    'unqualified-lead',
    'do-not-send',
    'soft-lead',
    'qualified-lost-lead',
  ],
  defaultTrackingLinkPrefixLength: 8,
  main_date_format: 'YYYY-MM-DD HH:mm:ss',
  short_date_format: 'YYYY-MM-DD',
  ordinal_date_format: 'Do MMM, YYYY HH:mm:ss',
  COMPANY_VARIABLES_FALLBACK: {
    company: 'your company',
    company_name: 'your company',
    most_recent_past_company: 'your recent past company',
    company_industry: 'your company industry',
    company_region: 'your company region',
    location: 'your region',
  },
  message_variable_list: [
    {value: '{receiver.first_name}', label: '+ receiver first name'},
    {value: '{receiver.last_name}', label: '+ receiver last name'},
    {
      value: '{receiver.company_name OR your company}',
      label: '+ receiver oldest current company name',
      fallback: 'company_name',
      key: '{receiver.company_name OR ',
      editable: true,
    },
    // {
    //   value: '{receiver.most_recent_past_company OR your recent past company}',
    //   label: '+ receiver recent past company',
    //   fallback: 'most_recent_past_company',
    //   key: '{receiver.most_recent_past_company OR ',
    //   editable: true,
    // },
    // {
    //   value: '{receiver.company_industry OR your company industry}',
    //   label: '+ receiver oldest current company industry',
    //   fallback: 'company_industry',
    //   key: '{receiver.company_industry OR ',
    //   editable: true,
    // },
    // {
    //   value: '{receiver.company_region OR your company region}',
    //   label: '+ receiver oldest current company region',
    //   fallback: 'company_region',
    //   key: '{receiver.company_region OR ',
    //   editable: true,
    // },
    // {
    //   value: '{receiver.receiver_region OR your region}',
    //   label: '+ receiver current region',
    //   fallback: 'receiver_region',
    //   key: '{receiver.receiver_region OR ',
    //   editable: true,
    // },
    {value: '{user.first_name}', label: '+ user first name'},
  ],
  audience_statuses: {
    created: 'created', // to be send
    invited: 'invited', // invited
    invites_from_previous_potential: 'invites_from_previous_potential',
    nurtured: 'nurtured', // invited
    manually_sequenced_created: 'manually_sequenced_created',
    manually_sequenced_nurtured: 'manually_sequenced_nurtured',
    already_connected: 'already_connected', // already connected
    error_no_email: 'error_no_email', // no email
    error_pending: 'error_pending', // pending
    error_company_excluded: 'error_company_excluded', // excluded (blacklist)
    error_email_required: 'error_email_required', // email required
    error_invite_exist: 'error_invite_exist', // excluded (invite exist)
    error_message_exist: 'error_message_exist', // message exist
    error_different_typed_message: 'error_different_typed_message', // message not match
    error_different_typed_invite: 'error_different_typed_invite', // message not match
    error_open_sequence: 'error_open_sequence', // open sequence
    error_exclude_responders: 'error_exclude_responders', // excluded responders
    error_contacted_by_excluded_campaign: 'error_contacted_by_excluded_campaign', // contacted by excluded campaign
    error_not_gx_connection: 'error_not_gx_connection', // not gx connection
    error_response_or_followup_detected: 'error_response_or_followup_detected', // response or manual detected
    error_do_not_send_tag: 'error_do_not_send_tag', // receiver flagged with #do-not-send tag
    error_communication_disabled: 'error_communication_disabled', // communication disabled
    error_in_mail: 'error_in_mail', // conversation restricted
    error_profile_url: 'error_profile_url', // broken profile url
    errors: 'errors', // note: not real status, added here for sorting
    error_no_invite_modal: 'error_no_invite_modal',
    error_session_expired_modal: 'error_session_expired_modal',
    error_invite_with_error: 'error_invite_with_error',
    error_conversation_failed: 'error_conversation_failed',
    error_file_not_attached: 'error_file_not_attached',
    error_invite_invalid: 'error_invite_invalid',
    error_message_invalid: 'error_message_invalid',
    error_message_not_allowed: 'error_message_not_allowed',
    error_message: 'error_message',
    error_has_invite: 'error_has_invite',
    error_invite_not_allowed: 'error_invite_not_allowed',
    error_profile_id_not_found: 'error_profile_id_not_found',
    error_not_loaded: 'error_not_loaded',
    error_no_receiver_id: 'error_no_receiver_id',
    hidden_from_search: 'hidden_from_search',
    campaign_audience_errors_count: 'campaign_audience_errors_count', // db saved errors
    error_invalid_email: 'error_invalid_email',
    error_email_not_found: 'error_email_not_found',
    error_invalid_profile_url_and_email: 'error_invalid_profile_url_and_email',
    error_message_requests_exist: 'error_message_requests_exist',
    error_receiver_not_match_with_public_identifier: 'error_receiver_not_match_with_public_identifier',
    error_receiver_not_found_in_search_results: 'error_receiver_not_found_in_search_results',
    error_group_profile_page_search_result_null: 'error_group_profile_page_search_result_null',
    error_tag_not_matched: 'error_tag_not_matched',
    invite_message_429: 'invite_message_429',
    invite_import_message_500: 'invite_import_message_500',
    invite_import_message_400: 'invite_import_message_400',
    error_inmail_exist: 'error_inmail_exist',
    invited_credit_used: 'invited_credit_used',
    invited_free_inmail: 'invited_free_inmail',
    error_manually_removed: 'error_manually_removed', // receiver manually removed (for zapier campaigns)
  },
  connectionsTableColumns: [
    {label: 'First Name', value: 'first_name'},
    {label: 'Last Name', value: 'last_name'},
    {label: 'Headline', value: 'headline'},
    {label: 'Email', value: 'email'},
    {label: 'Phone', value: 'phone'},
    {label: 'User', value: 'user'},
    {label: 'Tags', value: 'tags'},
    {label: 'Response', value: 'response'},
    {label: 'Conversation', value: 'conversation'},
    {label: 'Positions', value: 'positions'},
    {label: 'Companies', value: 'companies'},
    {label: 'Imported', value: 'imported'},
    {label: 'Connection Date', value: 'imported_date'},
    {label: 'Location', value: 'location'},
    {label: 'LinkedIn', value: 'linkedin_url'},
    {label: 'Twitter', value: 'twitter_url'},
    {label: 'Followed', value: 'followed_on_twitter'},
    {label: 'Websites', value: 'websites'},
    {label: 'IM Handles', value: 'ims'},
    {label: 'Birthday', value: 'birthday'},
    {label: 'Campaigns', value: 'campaigns'},
    {label: 'Incomplete', value: 'incomplete'},
    {label: 'Response Date', value: 'response_date'},
    {label: 'Clicked', value: 'clicked_links'},
  ],
  inbox_page_key: '5',
  standartHighlights: [],
  campaignColumns: [
    {label: 'Actions', value: 'actions', disabled: true},
    {label: 'Type', value: 'type'},
    {label: 'Name', value: 'name', disabled: true},
    {label: 'Warnings', value: 'warnings', disabled: true},
    {label: 'User', value: 'user', disabled: true},
    {label: 'Potential', value: 'potential'},
    {label: 'To be Outreached', value: 'to_be_outreached'},
    {label: 'Outreached', value: 'outreached'},
    {label: 'Invited', value: 'invites'},
    {label: 'Nurtured', value: 'nurtured'},
    {label: 'Connected', value: 'invites_to_connections_rate'},
    {label: 'Responded', value: 'connections_to_responses_rate'},
    {label: 'Responded to Outreached', value: 'responded_to_outreached'},
    {label: 'Responded after First Outreach', value: 'responses_after_invite'},
    {label: 'Responded after Followup', value: 'responses_after_followup'},
    {label: 'Responded after Second Followup', value: 'responses_after_second_followup'},
    {label: 'Responded after Third Followup', value: 'responses_after_third_followup'},
    {label: 'Likely Positive', value: 'total_positive_responses'},
    {label: 'Deals Amount', value: 'total_deals_amount'},
    {label: 'In Sequence', value: 'in_sequence'},
    {label: 'Last Activity', value: 'last_activity'},
    {label: 'Clicked', value: 'total_links_clicked'},
    // {label: 'Message Request Accepted', value: 'accepted_message_requests'},
    // {label: 'Message Request', value: 'message_request'},
    {label: 'Accepted inMail', value: 'accepted_inmails'},
    {label: 'Rejected inMail', value: 'rejected_inmails'},
    {label: 'Inmail', value: 'inmail'},
    {label: 'Creation Date', value: 'creation_date'},
  ],
  keyLabelMap: {
    status: {key: 'isArchived', label: 'Status'},
    actions: {key: 'action', label: 'Actions'},
    type: {key: 'campaign_type', label: 'Type'},
    name: {key: 'name', label: 'Name'},
    warnings: {key: 'error', label: 'Warnings'},
    user: {key: 'selectedUser.email', label: 'User'},
    potential: {key: 'potential', label: 'Potential'},
    outreached: {key: 'outreached', label: 'Outreached'},
    invites: {key: 'invites', label: 'Invited'},
    nurtured: {key: 'nurtured', label: 'Nurtured'},
    to_be_outreached: {key: 'stats.reach', label: 'To be Outreached'},
    last_activity: {key: 'last_run_date', label: 'Last Activity'},
    creation_date: {key: 'creation_date', label: 'Creation Date'},
    invites_to_connections_rate: {key: 'stats.invites_to_connections_rate', label: 'Connected'},
    connections_to_responses_rate: {key: 'stats.connections_to_responses_rate', label: 'Responded'},
    total_positive_responses: {key: 'stats.total_positive_responses', label: 'Likely Positive'},
    total_deals_amount: {key: 'stats.deals_amount', label: 'Deals Amount'},
    total_links_clicked: {key: 'stats.tracking_clicks', label: 'Tracking Clicks'},
    'hot-lead': {key: 'stats.tags.hot-lead', label: 'Hot Lead'},
    'closed-deal': {key: 'stats.tags.closed-deal', label: 'Closed Deal'},
    positive: {key: 'stats.tags.positive', label: 'Positive'},
    'not-positive': {key: 'stats.tags.not-positive', label: 'Not Positive'},
    'completed-demo': {key: 'stats.tags.completed-demo', label: 'Completed Demo'},
    'do-not-send': {key: 'stats.tags.do-not-send', label: 'Do Not Send'},
    'booked-demo': {key: 'stats.tags.booked-demo', label: 'Booked Demo'},
    'unqualified-lead': {key: 'stats.tags.unqualified-lead', label: 'Unqualified Lead'},
    'soft-lead': {key: 'stats.tags.soft-lead', label: 'Soft Lead'},
    'qualified-lost-lead': {key: 'stats.tags.qualified-lost-lead', label: 'Qualified Lost Lead'},
    // message_request: {key: 'stats.message_request', label: 'Message Request'},
    // accepted_message_requests: {key: 'stats.accepted_message_requests', label: 'Message Request Accepted'},
    inmail: {key: 'stats.inmail', label: 'Inmail'},
    accepted_inmails: {key: 'stats.accepted_inmails', label: 'Accepted inMail'},
    rejected_inmails: {key: 'stats.rejected_inmails', label: 'Rejected inMail'},
  },
  BILLING: {
    PLANS,
    STATUSES,
    CARD_STATUSES,
  },
};
