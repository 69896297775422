import {Webhook, IApiService} from '@growth-x/types';

export class WebhookService {
  client: IApiService;
  constructor({client}: {client: IApiService}) {
    this.client = client;
  }
  serviceName = 'webhooks';

  getWebhooks() {
    return this.client.get(`api/v2/${this.serviceName}`, '');
  }

  postWebhook(webhook: Omit<Webhook, 'id'>) {
    return this.client.post(`api/v2/${this.serviceName}`, webhook);
  }

  deleteWebhook(id: number) {
    return this.client.delete(`api/v2/${this.serviceName}/${id}`, '');
  }

  patchWebhook(id: number, parameters: Partial<Omit<Webhook, 'id'>>) {
    return this.client.patch(`api/v2/${this.serviceName}/${id}`, parameters);
  }
}
